import {
  Accessor,
  createEffect,
  createSignal,
  onCleanup,
  onMount,
} from "solid-js";
import { createQuery } from "@tanstack/solid-query";
import { DateTime } from "luxon";

const STORAGE_KEY = "lastVisitDate";

const fetchLastVisitDate = (): DateTime | null => {
  const storedDate = localStorage.getItem(STORAGE_KEY);
  if (storedDate) {
    return DateTime.fromISO(storedDate, { zone: "utc" });
  }
  const newDate = saveCurrentDate();
  return newDate;
};

const saveCurrentDate = (): DateTime => {
  const currentDate = DateTime.utc();
  localStorage.setItem(STORAGE_KEY, currentDate.toISO());
  return currentDate;
};

export default function refreshLastVisitDateTime(
  reloadPage: boolean = true,
): Accessor<boolean> {
  const lastVisitDateTimeQuery = createQuery(() => ({
    queryKey: [STORAGE_KEY],
    queryFn: fetchLastVisitDate,
    staleTime: Infinity,
  }));
  const [shouldRefresh, setShouldRefresh] = createSignal(false);

  onMount(() => {
    // If is the app re opened, we need to refresh the data
    saveCurrentDate();
    lastVisitDateTimeQuery.refetch();
  });

  createEffect(() => {
    const handleFocus = () => {
      if (lastVisitDateTimeQuery.data) {
        const diff = DateTime.utc().diff(
          lastVisitDateTimeQuery.data,
          "minutes",
        ).minutes;

        // After 5 minutes, we consider data is outdated and we need to refresh
        if (diff > 5) {
          saveCurrentDate();
          lastVisitDateTimeQuery.refetch();
          // Reload page
          setShouldRefresh(true);
          if (reloadPage) {
            location.href = "/";
          }
        } else {
          // Update the last visit date
          saveCurrentDate();
          lastVisitDateTimeQuery.refetch();
        }
      }
    };

    window.addEventListener("focus", handleFocus);

    onCleanup(() => {
      window.removeEventListener("focus", handleFocus);
    });
  });
  return shouldRefresh;
}
