import { Modal, ModalDescription } from "./ui/modal";
import { Button } from "./ui/button";
import { eventBus } from "@/components/event-bus.ts";
import { coinHD } from "@/assets/images";
import { formatCompactNumber } from "@/lib/utils";
import { setPlayerStore } from "./player-store";
import { GameService } from "@/api-client";
import { mergeProps } from "solid-js";

export function AFKReloadModal(props: {
  closeModal: () => void;
  title?: string;
}) {
  const mergedProps = mergeProps({ title: "Welcome Back!" }, props);

  function handleClose() {
    // props.closeModal();
  }

  return (
    <Modal open={true} showRewardsOverlay onPointerDownOutside={handleClose}>
      <div class="flex flex-col justify-center gap-4">
        <div class="eazy-chat text-outline-1 text-center text-3xl text-[#FCB74F]">
          {mergedProps.title}
        </div>
        <Button
          onClick={() => {
            location.href = "/";
          }}
          class="eazy-chat text-outline-1 mt-6 h-12 min-w-fit border border-[#004811] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-4xl leading-4 text-white"
        >
          <span class="mt-2 align-middle text-white">Reload</span>
        </Button>
      </div>
    </Modal>
  );
}
