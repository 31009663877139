import {
  Actor,
  Engine,
  SpriteSheet,
  Animation,
  range,
  vec,
  AnimationStrategy,
  Vector,
  Random,
  Color,
} from "excalibur";
import { ItemSpawner, ItemType, SpawnType } from "./item-spawner";
import { Resources } from "@/game/resources.ts";
import { animManager } from "./animation-manager";
import { vibrate } from "@/lib/utils.ts";
import { FloatText } from "@/game/actors/float-text";
import { Config } from "../config";
import {
  OnboardingStep,
  onboardingActions,
  onboardingSelectors,
} from "@/components/onboarding-store";
import { outline } from "../materials/outline";
import { createEffect } from "solid-js";
import { OnboardingArrowDown } from "./onboarding-arrow-down";

export class OnboardingShardMine extends Actor {
  public outlineMaterial: ex.Material | null = null;
  public arrow?: OnboardingArrowDown;
  private oreSmash?: Animation;
  private random: Random;
  private spawner?: ItemSpawner;

  constructor() {
    super({ x: 0, y: 0 });
    this.random = new Random();

    this.on("pointerdown", (event) => {
      if (
        onboardingSelectors.currentStep() !== OnboardingStep.PreMineShards &&
        onboardingSelectors.currentStep() !== OnboardingStep.MineShards
      ) {
        return;
      }
      const shardsChange = onboardingActions.handleMiningOnboarding();
      this.playOreSmash(event.worldPos);
      // Julio: shake doesn't feel right on mobile
      //this.scene?.camera.shake(10, 10, 10);
      vibrate("light");
      this.scene?.add(
        new FloatText(
          this.pos.x + this.random.integer(-32, 32),
          this.pos.y - 180,
          `+${shardsChange}`,
          32,
          Color.Magenta,
        ),
      );
      if (this.spawner) {
        this.spawner.itemType = ItemType.Shard;
        if (shardsChange < 1) this.spawner.itemType = ItemType.ShardSmall;
        this.spawner.spawn(1);
      }

      if (onboardingSelectors.currentStep() === OnboardingStep.PreMineShards) {
        onboardingActions.moveToNextStep();
      }
      if (onboardingSelectors.currentStep() === OnboardingStep.MineShards) {
        if (onboardingSelectors.shardsMined() >= 12) {
          onboardingActions.moveToNextStep();
        }
      }
    });

    createEffect(() => {
      if (onboardingSelectors.currentStep() === OnboardingStep.PreMineShards) {
        this.showOutline();
        this.showArrow();
      } else if (
        onboardingSelectors.currentStep() > OnboardingStep.PreMineShards
      ) {
        this.hideOutline();
        this.hideArrow();
      }
    });
  }

  public onInitialize(engine: Engine<any>): void {
    const viewport = engine.screen.getScreenBounds();

    this.pos.x = viewport.width / 2 - 100;
    this.pos.y = viewport.height - 30 * Config.scale;
    this.name = "shardmine";
    this.anchor = vec(0.5, 1);

    this.spawner = new ItemSpawner(
      SpawnType.Splash,
      ItemType.Shard,
      3,
      undefined,
      new Vector(this.pos.x, this.pos.y),
    );
    this.scene?.add(this.spawner);

    this.pointer.useGraphicsBounds = true;
    this.graphics.use(Resources.Ore.toSprite());
    this.scale = vec(Config.scale, Config.scale);

    const spritesheet = SpriteSheet.fromImageSource({
      image: Resources.OreSmash,
      grid: {
        rows: 1,
        columns: 8,
        spriteWidth: 64,
        spriteHeight: 64,
      },
    });
    this.oreSmash = Animation.fromSpriteSheet(spritesheet, range(0, 7), 80);
    this.oreSmash.strategy = AnimationStrategy.End;
    this.oreSmash.scale = vec(Config.scale, Config.scale);

    const outlineMaterial = engine.graphicsContext.createMaterial({
      name: "outline",
      fragmentSource: outline,
    });
    this.outlineMaterial = outlineMaterial;

    const arrow = new OnboardingArrowDown(this.pos.x, this.pos.y - 180, false);
    this.arrow = arrow;
    this.scene?.add(arrow);
  }

  public update(engine: ex.Engine, delta: number) {
    super.update(engine, delta);
    this.z = this.pos.y;
  }

  public playOreSmash(pointerPos: Vector): void {
    if (this.oreSmash) {
      animManager.play(this.oreSmash, pointerPos);
    }
  }

  public showOutline() {
    if (this.graphics.material === null) {
      this.graphics.material = this.outlineMaterial;
    }
  }

  public hideOutline() {
    if (this.graphics.material) {
      this.graphics.material = null;
    }
  }

  public showArrow() {
    if (this.arrow !== undefined) {
      this.arrow.show();
    }
  }

  public hideArrow() {
    if (this.arrow) {
      this.arrow.hide();
    }
  }
}
