import {
  Engine,
  Scene,
  Actor,
  Vector,
  Color,
  BoundingBox,
  CollisionType,
  CompositeCollider,
  EdgeCollider,
  vec,
  SceneActivationContext,
} from "excalibur";

import { Resources } from "@/game/resources";
import { Beast } from "@/game/actors/beast";
import { ItemSpawner, ItemType, SpawnType } from "../actors/item-spawner";
import { ShardMine } from "@/game/actors/shard-mine.ts";
import { eventBus } from "@/components/event-bus.ts";
import { animManager } from "@/game/actors/animation-manager";
import { Background } from "@/game/actors/background";
import { LeafEmitter } from "@/game/actors/leaf-emitter";
import { FloatText } from "@/game/actors/float-text";
import { Config } from "../config";
import { captureEvent } from "@/lib/analytics";
import { EnergyBall } from "../actors/energy-ball";
import { playSfxSound } from "@/components/sounds";
import { vibrate } from "@/lib/utils";
import { playerStore } from "@/components/player-store";

export class Ranch extends Scene {
  background?: Actor;
  scale: number = 1;
  beastId: number;
  beast?: Beast;
  coinDropper?: ItemSpawner;
  shardDropper?: ItemSpawner;

  ground?: Actor;
  walls?: Actor;
  energyBall?: EnergyBall;
  shadow?: Actor;

  constructor(specieStageId: number) {
    super();
    this.beastId = specieStageId;
  }

  public onActivate(
    context: SceneActivationContext<{ specieStageId: number }>,
  ): void {
    if (context.data && this.beast === undefined) {
      this.beastId = context.data.specieStageId;
      this.beast = new Beast(this.beastId);
      this.add(this.beast);
    }
  }

  public onInitialize(engine: Engine) {
    engine.add(animManager);

    const viewport = engine.screen.getScreenBounds();
    this.scale = viewport.width / Resources.Pasture.width;
    Config.scale = this.scale;

    this.background = new Background(
      viewport.width / 2,
      viewport.height,
      this.scale,
    );
    this.add(this.background);

    this.add(new ShardMine());
    this.add(new LeafEmitter());

    this.coinDropper = new ItemSpawner(
      SpawnType.Drop,
      ItemType.Coin,
      1,
      new BoundingBox(
        0,
        viewport.height - 80 * this.scale,
        viewport.width,
        viewport.height,
      ),
    );
    this.add(this.coinDropper);
    this.shardDropper = new ItemSpawner(
      SpawnType.Drop,
      ItemType.Shard,
      1,
      new BoundingBox(
        0,
        viewport.height - 80 * this.scale,
        viewport.width,
        viewport.height,
      ),
    );
    this.add(this.shardDropper);

    eventBus.on("levelUp", (level: number) => {
      let spawner = new ItemSpawner(
        SpawnType.Splash,
        ItemType.Coin,
        0,
        undefined,
        new Vector(this.beast!.pos.x, this.beast!.pos.y),
      );
      this.add(spawner);
      spawner.spawn(10);
      this.camera.shake(10, 10, 10);

      if (this.beast) {
        let distanceFromTop = this.beast.pos.y - viewport.top;
        let floatHeight = Math.min(distanceFromTop * 0.6, 240);

        this.add(
          new FloatText(
            this.beast.pos.x,
            this.beast.pos.y - floatHeight,
            "LEVEL UP!",
            48,
            Color.White,
            "Eazy Chat",
            1000,
          ),
        );
        this.add(
          new FloatText(
            this.beast.pos.x,
            this.beast.pos.y - floatHeight + 44,
            `${playerStore.beast.reincarnations >= 1 ? level * 5 : level}x coins/hr`,
            28,
            Color.Yellow,
            "Eazy Chat",
            1000,
          ),
        );
      }
    });

    eventBus.on("levelUpRaffleTicket", () => {
      let raffleTicketSpawner = new ItemSpawner(
        SpawnType.Splash,
        ItemType.RaffleTicket,
        0,
        undefined,
        new Vector(this.beast!.pos.x, this.beast!.pos.y),
      );
      this.add(raffleTicketSpawner);
      raffleTicketSpawner.spawn(1);
      if (this.beast) {
        let distanceFromTop = this.beast.pos.y - viewport.top;
        let floatHeight = Math.min(distanceFromTop * 0.6, 240);
        this.add(
          new FloatText(
            this.beast.pos.x,
            this.beast.pos.y - floatHeight + 78,
            "+1 Raffle Ticket",
            24,
            Color.Red,
            "Eazy Chat",
            1000,
          ),
        );
      }
    });

    eventBus.on("coinsWhileAway", (coins: number) => {
      console.log("[Event coinsWhileAway]: coins ", coins);
      this.coinDropper?.spawn(coins);
    });
    eventBus.on("dailyStreakClaimed", (shards: number) => {
      console.log("[Event dailyStreakClaimed]: shards ", shards);
      this.shardDropper?.spawn(shards);
    });
  }

  addEnergyBall(props: { currentHealth: number; maxHealth: number }) {
    if (this.energyBall?.isKilled() == false) return;

    const viewport = this.engine.screen.getScreenBounds();
    this.energyBall = new EnergyBall({
      x: viewport.width / 2,
      y: viewport.height / 2,
      health: props.currentHealth,
      maxHealth: props.maxHealth,
    });
    this.add(this.energyBall);

    this.ground = new Actor({
      name: "ground",
      x: viewport.width / 2,
      y: viewport.height - 100,
      width: viewport.width,
      height: 10,
      color: Color.Transparent,
    });
    this.ground.body.collisionType = CollisionType.Fixed;
    this.add(this.ground);

    this.shadow = new Actor({
      x: this.energyBall.pos.x,
      y: this.ground.pos.y - 2 * Config.scale,
      radius: 8,
      color: Color.Black,
    });
    this.shadow.scale = vec(Config.scale, Config.scale * 0.6);
    this.shadow.graphics.opacity = 0.5;
    this.add(this.shadow);
    this.energyBall.shadow = this.shadow;

    const wallColliders = new CompositeCollider([
      new EdgeCollider({
        begin: vec(0, -3 * Config.scale),
        end: vec(viewport.width, -3 * Config.scale),
      }),
      new EdgeCollider({
        begin: vec(-3 * Config.scale, 0),
        end: vec(-3 * Config.scale, viewport.height),
      }),
      new EdgeCollider({
        begin: vec(viewport.width + 3 * Config.scale, 0),
        end: vec(viewport.width + 3 * Config.scale, viewport.height),
      }),
    ]);
    this.walls = new Actor({
      name: "walls",
      collider: wallColliders,
    });
    this.walls.body.collisionType = CollisionType.Fixed;
    this.add(this.walls);

    this.energyBall.on("collisionstart", (evt) => {
      playSfxSound("pop", this.energyBall?.sfxRate());
      vibrate("light");
      if (evt.other === this.ground) {
        this.energyBall!.isTouchingGround = true;
        console.log("COMBO", this.energyBall!.combo);
        if (this.energyBall!.combo > 0) {
          eventBus.emit("energyBallComboEnded", this.energyBall!.combo);
          // captureEvent("energy_ball_combo_ended", {
          //   combo: this.energyBall!.combo,
          // });
          this.energyBall!.resetCombo();
        }
      }
      if (
        evt.other === this.walls ||
        (evt.other === this.ground && !this.energyBall!.isSquashing)
      ) {
        this.energyBall!.squash(evt.contact.normal, this.energyBall!.vel.size);
      }
    });
    this.energyBall.on("collisionend", (evt) => {
      if (evt.other === this.ground) {
        this.energyBall!.isTouchingGround = false;
        this.energyBall!.timeOnGround = 0;
      }
    });
  }

  removeEnergyBall() {
    this.energyBall?.kill();
    this.ground?.kill();
    this.walls?.kill();
    this.shadow?.kill();
  }
}
