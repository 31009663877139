import { A, useLocation } from "@solidjs/router";
import { capturePageView } from "@/lib/analytics";
import { For, Show, Suspense, createEffect, mergeProps } from "solid-js";
import { cn } from "@/lib/utils";
import {
  airdropIcon,
  arrowDown,
  friendsIcon,
  homeIcon,
  questsIcon,
  upgradesIcon,
} from "@/assets/images";
import { createQuery } from "@tanstack/solid-query";
import { playerStore } from "./player-store";
import { makePersisted } from "@solid-primitives/storage";
import { createStore } from "solid-js/store";
import {
  QuestsService,
  RaffleTicketsService,
  ReferralsService,
} from "@/api-client";

const [navbarAlertsStore, setNavbarAlertsStore] = makePersisted(
  createStore({
    friends: true,
  }),
  {
    name: "bottom-navbar-show-alerts",
    storage: localStorage,
  },
);

export const navLinks = [
  {
    href: "/play",
    icon: homeIcon,
    text: "Play",
  },
  {
    href: "/play/ranch",
    icon: upgradesIcon,
    text: "Upgrades",
  },
  {
    href: "/play/friends",
    icon: friendsIcon,
    text: "Friends",
  },
  {
    href: "/play/quests",
    icon: questsIcon,
    text: "Quests",
  },
  {
    href: "/play/airdrop",
    icon: airdropIcon,
    text: "Airdrop",
  },
];

const NAV_BG: Record<(typeof navLinks)[number]["text"], string> = {
  Play: "#D97A34",
  Upgrades: "#FF6644",
  Friends: "#8EEB45",
  Quests: "#FFCB11",
  Airdrop: "#8AC4FA",
};

export default function BottomNavbar(props: {
  interaction?: boolean;
  showArrowUpgrades?: boolean;
}) {
  const finalProps = mergeProps({ interaction: true }, props);

  const referralsStateQuery = createQuery(() => ({
    queryKey: ["referralsState"],
    queryFn: () => ReferralsService.getReferralsState(),
    enabled: props.interaction,
  }));
  const ticketsCountQuery = createQuery(() => ({
    queryKey: ["raffleTickets"],
    queryFn: RaffleTicketsService.getRaffleTickets,
    enabled: props.interaction,
  }));

  const playerQuestsQuery = createQuery(() => ({
    queryKey: ["activeQuests"],
    queryFn: () => QuestsService.getActiveQuests(),
  }));

  const location = useLocation();

  createEffect(() => {
    console.log("PATHNAME", location.pathname);
  });

  createEffect(() => {
    capturePageView(location.pathname, {
      to: location.pathname,
    });
  }, location.pathname);

  return (
    <nav
      class={cn(
        "pointer-events-auto fixed bottom-4 left-0 right-0 z-[100] mx-auto max-w-xl",
        !finalProps.interaction && "pointer-events-none static",
      )}
    >
      <div class="flex justify-around p-2">
        <For each={navLinks}>
          {(link) => (
            <A
              href={link.href}
              class={cn(
                "relative flex h-14 w-14 flex-col items-center justify-center gap-1 rounded-full border border-[#A35238] p-2 transition-colors",
                location.pathname === link.href && "bg-yellow-400",
              )}
              style={{
                background:
                  "linear-gradient(180deg, #FCB74F 60.5%, #FA9044 100%)",
              }}
              onClick={
                link.text === "Friends"
                  ? () => setNavbarAlertsStore("friends", false)
                  : undefined
              }
            >
              <div
                class="absolute h-10 w-10 rounded-full border border-[#A35238] bg-white"
                style={{
                  background: NAV_BG[link.text],
                }}
              />
              <span class="eazy-chat text-outline absolute -bottom-2 z-10 text-base text-white">
                {link.text}
              </span>
              <img
                class="relative h-10 w-10 object-contain text-white transition-colors"
                src={link.icon}
              />
              <Suspense>
                <Show
                  when={
                    (referralsStateQuery.data?.luckySerumGained &&
                      !playerStore.beast.isLucky &&
                      ["Friends"].includes(link.text)) ||
                    (!playerQuestsQuery.data?.every(
                      (q) => q.status === "Completed",
                    ) &&
                      ["Quests"].includes(link.text)) ||
                    (navbarAlertsStore.friends &&
                      ["Friends"].includes(link.text)) ||
                    (ticketsCountQuery.data?.count &&
                      ["Airdrop"].includes(link.text))
                  }
                >
                  <div class="absolute -left-1 -top-1 flex h-5 w-5 items-center justify-center rounded-full bg-[#BD1717]">
                    <span class="eazy-chat mt-1 text-sm text-white">!</span>
                  </div>
                </Show>
              </Suspense>
              <Show
                when={
                  !finalProps.interaction &&
                  props.showArrowUpgrades &&
                  ["Upgrades"].includes(link.text)
                }
              >
                <img
                  src={arrowDown}
                  class="up-and-down absolute -top-[50px] z-10 mt-2 w-[80px] object-contain"
                />
              </Show>
            </A>
          )}
        </For>
      </div>
    </nav>
  );
}
