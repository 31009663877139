import { Modal, ModalDescription } from "./ui/modal";
import { Button } from "./ui/button";
import { createSignal, Suspense } from "solid-js";
import { hourglassImage } from "@/assets/images";
import createCountdownTimer from "./lib/countdown-timer";
import { DateTime } from "luxon";
import { raffleTicketsLogo, rewardBg } from "@/assets/images";
import { captureEvent } from "@/lib/analytics";
import { initUtils } from "@tma.js/sdk-solid";
import { botName } from "@/routes/FriendsRoute";
import { createQuery } from "@tanstack/solid-query";
import { ReferralsService, SpecialSignupTicketsService } from "@/api-client";
import posthog from "posthog-js";
import { CheckIcon, CopyIcon } from "@/assets/extra-icons";
import { showToast } from "./ui/toast";

const REFERRAL_BONUS_MESSAGE = `\n🧑‍🌾  Kuroro Beast Ranch: Select your companion, farm coins, upgrade your Ranch!\n\n🎟️  More friends invited, more level, more progress = More Tickets!\n\n🤑  More tickets = More Rewards and progress towards the big $KURO Airdrop!`;

function BonusTicketModalContent(props: {
  closeModal: () => void;
  title: string;
}) {
  const referralsStateQuery = createQuery(() => ({
    queryKey: ["referralsState"],
    queryFn: () => ReferralsService.getReferralsState(),
  }));
  const specialSignupTicketCountQuery = createQuery(() => ({
    queryKey: ["SpecialSignupTicketCount"],
    queryFn: () => SpecialSignupTicketsService.getTicketCount(),
  }));
  const [copySuccess, setCopySuccess] = createSignal(false);

  const referralUrl = () =>
    `https://t.me/${botName}/ranch?startapp=ref-${referralsStateQuery.data?.referralCode}`;

  const shareReferralUrl = () => {
    captureEvent("share_referral_url", {
      from: "bonus_ticket_modal",
    });
    initUtils().openTelegramLink(
      `https://t.me/share/url?url=${referralUrl()}&text=${encodeURIComponent(REFERRAL_BONUS_MESSAGE)}`,
    );
  };

  const copyReferralUrl = () => {
    captureEvent("copy_referral_url");
    navigator.clipboard.writeText(referralUrl());
    setCopySuccess(true);
    showToast({
      title: "Invite Link Copied!",
      description: "",
    });
    setTimeout(() => setCopySuccess(false), 2000);
  };

  return (
    <div class="relative flex flex-col justify-center rounded-xl bg-[#FFE7DF]">
      <div class="absolute -right-3 -top-3">
        <Button
          variant="ghost"
          class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
          onClick={props.closeModal}
        >
          <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
        </Button>
      </div>
      <div class="flex items-center justify-center rounded-t-xl bg-[#942645] px-2 pb-2 pt-3 text-center">
        <h3 class="eazy-chat text-outline text-2xl text-white">
          {props.title}
        </h3>
      </div>

      <div class="bg-[#FFE7DF] rounded-xl">
        <div class="my-2 p-2">
          <p class="px-1 text-center text-md leading-tight text-[#a15555] font-bold">
            For the next <b class="text-[#F06969]">72 hours</b> only, get +1
            Raffle Ticket for both you AND your friend that signs up with your
            invite code! (Up to 5 friends)
          </p>
        </div>

        {/* center */}
        <div
          class="h-100 relative mx-2 my-2 rounded-xl bg-[#c7636d] py-2"
          style={{
            "background-image": `url(${rewardBg})`,
            "background-size": "cover",
            "background-position": "center",
            "background-repeat": "no-repeat",
          }}
        >
          <h2 class="px-1 text-center text-[16px] text-white font-black">
            Invite Friends for Bonus Tickets!
          </h2>

          <div class="flex w-full items-center justify-center gap-3">
            <div class="flex w-fit items-center gap-1 rounded-full bg-[#942645] px-4 py-2">
              <img src={hourglassImage} class="h-4 w-3" />
              <p class="text-lg leading-4 text-white font-black">
                {createCountdownTimer(
                  DateTime.fromISO(
                    posthog
                      .getFeatureFlagPayload("first_raffle_special_signup")!
                      .toString(),
                    { zone: "utc" },
                  ).toString(),
                )()}
              </p>
            </div>

            <img src={raffleTicketsLogo} class="w-[90px] object-contain" />
          </div>

          <div class="mt-1 text-center text-[14px] text-white font-black">
            +1 Raffle Ticket per friend
          </div>
        </div>

        <div class="px-1 text-center text-lg text-[#a15555] font-black">
          <p class="">Bonus Tickets Claimed</p>
          <div class="text-lg text-white align-middle bg-[#942645] mt-1 py-1 w-24 m-auto rounded-full">
            {specialSignupTicketCountQuery.data?.count || 0}/
            {specialSignupTicketCountQuery.data?.maxCount || "-"}
          </div>
        </div>

        {/* footer */}
        <div class="flex justify-center gap-2 p-3 rounded-b-xl">
          <div class="flex gap-2">
            <Button
              size="lg"
              class="eazy-chat min-w-fit flex-1 border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
              onClick={shareReferralUrl}
            >
              <span
                class="text-outline mt-1 text-2xl text-white"
                style={{
                  "-webkit-text-stroke-color": "#B04831",
                }}
              >
                Invite Friend
              </span>
            </Button>
            <Button
              onClick={copyReferralUrl}
              size="lg"
              variant="outline"
              class="eazy-chat w-auto border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] p-4 hover:opacity-70"
            >
              {copySuccess() ? (
                <CheckIcon
                  width={24}
                  height={24}
                  color="white"
                  stroke-width="3"
                />
              ) : (
                <CopyIcon
                  width={24}
                  height={24}
                  color="white"
                  stroke-width="3"
                />
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function BonusTicketModal(props: {
  isModalOpen: () => boolean;
  setIsModalOpen: (value: boolean) => void;
  closeModal: () => void;
  title: string;
}) {
  return (
    <Modal
      open={props.isModalOpen()}
      onOpenChange={props.setIsModalOpen}
      onPointerDownOutside={props.closeModal}
    >
      <ModalDescription>
        <Suspense fallback={<div>Loading...</div>}>
          <BonusTicketModalContent
            closeModal={props.closeModal}
            title={props.title}
          />
        </Suspense>
      </ModalDescription>
    </Modal>
  );
}
