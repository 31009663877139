import { default as bronzeTreasureEgg } from "./bronze-treasure-egg.png";
import { default as silverTreasureEgg } from "./silver-treasure-egg.png";
import { default as goldenTreasureEgg } from "./golden-treasure-egg.png";
import { default as energyBall } from "./energy-ball.png";
import { default as kuro } from "./kuro.png";
import { default as luckySerum } from "./lucky-serum.png";
import { default as shard } from "./shard.png";
import { default as silverTicket } from "./silver-ticket.png";
import { default as energyDrink } from "./energy-drink.png";

export const REWARD_IMAGES = {
  "bronze-treasure-egg": bronzeTreasureEgg,
  "silver-treasure-egg": silverTreasureEgg,
  "golden-treasure-egg": goldenTreasureEgg,
  "energy-ball": energyBall,
  kuro: kuro,
  "lucky-serum": luckySerum,
  shards: shard,
  "silver-ferry-ticket": silverTicket,
  "energy-drink": energyDrink,
};

export const REWARD_COLORS = {
  "bronze-treasure-egg": "#8C6435",
  "silver-treasure-egg": "#969FA0",
  "golden-treasure-egg": "#FFCE28",
  "energy-drink": "#FFCE28",
  "energy-ball": "#9467BE",
  kuro: "#9C2F2F",
  "lucky-serum": "#FFCE28",
  shards: "#9467BE",
  "silver-ferry-ticket": "#C0C0C0",
};
