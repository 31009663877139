import { Modal } from "./ui/modal";
import { Button } from "./ui/button";
import { createSignal, For, Show, Suspense } from "solid-js";
import { arrowRight, checkImg, rebornBanner } from "@/assets/images";
import { Checkbox, CheckboxControl, CheckboxLabel } from "./ui/checkbox";
import { createMutation } from "@tanstack/solid-query";
import { ReincarnateService } from "@/api-client";
import { captureEvent } from "@/lib/analytics";
import { playerStore, refreshPlayerState } from "./player-store";
import { eventBus } from "./event-bus";
import { LockIcon } from "@/assets/extra-icons";

const LIST = [
  "+5x Coin Multiplier per level up",
  "Unlock Halo",
  "Can buy x2 Raffle Tickets / day",
  "Lucky, Hats, & Shards maintained",
  "Upgrade & Booster costs reset",
];

function Content(props: { closeModal: () => void }) {
  const [checked, setChecked] = createSignal(false);
  const reincarnateMutation = createMutation(() => ({
    mutationFn: async () => {
      return await ReincarnateService.reincarnate();
    },
    onSuccess: (result) => {
      refreshPlayerState();
      captureEvent("reincarnate");
      eventBus.emit("reincarnate");
      props.closeModal();
    },
  }));

  return (
    <div class="relative flex flex-col justify-center rounded-xl bg-[#FFB3B3]">
      <div class="absolute -right-3 -top-3">
        <Button
          variant="ghost"
          class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
          onClick={props.closeModal}
        >
          <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
        </Button>
      </div>
      <div class="flex flex-col items-center justify-center rounded-t-xl bg-[#113d20] px-2 pb-1 pt-2 text-center">
        <h3 class="eazy-chat text-outline text-3xl text-white">Be Reborn!</h3>
      </div>
      <div class="rounded-b-xl bg-[#336C40]">
        <div class="relative mx-2 my-2">
          <img src={rebornBanner} class="object-contain" />
        </div>
        <div class="flex items-center justify-center bg-[#FFD0C5] p-2 text-center text-sm font-black text-[#582821]">
          <p>
            You can now Reincarnate your Beast back to Level 1, but with
            permanently enhanced Coin & Ticket earning Power!
          </p>
        </div>
        <div class="rounded-b-xl bg-[#FFE7DF]">
          <div class="mx-2 flex flex-col gap-2 p-2">
            <For each={LIST}>
              {(item) => (
                <div class="flex min-w-[285px] items-center gap-2">
                  <div class="eazy-chat rounded-full border border-[#387C53] bg-[#55B85c] px-4 py-1">
                    <img src={checkImg} class="h-5 w-5 object-contain" />
                  </div>
                  <p class="text-sm font-black text-[#582821]">{item}</p>
                </div>
              )}
            </For>
          </div>
          <div class="flex items-center justify-center px-8 py-4">
            <Checkbox
              class="flex items-center space-x-4"
              checked={checked()}
              onChange={setChecked}
            >
              <CheckboxControl class="flex h-6 w-6 items-center justify-center border-2 border-[#582821] data-[checked]:bg-white data-[checked]:text-black" />
              <CheckboxLabel class="text-sm font-medium leading-none text-[#582821]">
                Yes, I understand my Beast Level, Upgrades, and Coins will be
                reset. Let’s do it!
              </CheckboxLabel>
            </Checkbox>
          </div>
          <div class="flex items-center justify-center pb-4">
            <Button
              onClick={() => reincarnateMutation.mutate()}
              disabled={
                checked() === false ||
                reincarnateMutation.isPending ||
                playerStore.beast.level < 50 ||
                playerStore.beast.reincarnations !== 0
              }
              size="sm"
              class="eazy-chat text-outline relative z-10 min-w-fit border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
            >
              <Show when={playerStore.beast.level < 50}>
                <div class="mx-2 flex items-center justify-center">
                  <LockIcon />
                </div>
              </Show>
              <div class="rounded-md"></div>
              <span class="z-10 pt-1 text-white">
                {playerStore.beast.level >= 50 ? "Reincarnate!" : "Level 50"}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ReincarnationModal(props: {
  isModalOpen: boolean;
  closeModal: () => void;
}) {
  return (
    <Modal open={props.isModalOpen}>
      <Suspense fallback={<div>Loading...</div>}>
        <Content closeModal={props.closeModal} />
      </Suspense>
    </Modal>
  );
}
