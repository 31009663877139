import { Beast } from "@/components/types.ts";
import { gameConfig } from "@/components/lib/game-config.ts";
import { unwrap } from "solid-js/store";

const happinessDecayRate = gameConfig.happiness.decayPerSecond / 1000;
const happinessBufferTime = gameConfig.happiness.bufferTimeSeconds * 1000;

export function calculateHappiness(
  happinessSnapshot: Beast["happinessSnapshot"],
  atTimestamp?: number,
) {
  atTimestamp = atTimestamp || new Date().getTime();

  const elapsedTime = atTimestamp - happinessSnapshot.updatedAt.getTime();

  if (elapsedTime <= happinessBufferTime) {
    return happinessSnapshot.value;
  }

  const newHappiness =
    happinessSnapshot.value -
    Math.floor((elapsedTime - happinessBufferTime) * happinessDecayRate);

  return Math.max(0, newHappiness);
}

export function calculateHappinessDepletesAt(
  happinessSnapshot: Beast["happinessSnapshot"],
) {
  return new Date(
    happinessSnapshot.updatedAt.getTime() +
      happinessBufferTime +
      (happinessDecayRate > 0 ? happinessSnapshot.value / happinessDecayRate : 0),
  );
}
