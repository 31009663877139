import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { createResource } from "solid-js";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";

export function makeConnection(url: string, token: string) {
  return new HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => token,
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
    })
    .withHubProtocol(new MessagePackHubProtocol())
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();
}

export function createConnection(url: string, token: string) {
  const [connection] = createResource(async () => {
    const connection = makeConnection(url, token);
    await connection.start();
    return connection;
  });
  return connection;
}
