import boc from "../beasts/hats/boc.png";
import halo from "../beasts/hats/halo.png";
import leaf from "../beasts/hats/leaf.png";
import straw from "../beasts/hats/straw.png";
import witch from "../beasts/hats/witch.png";
import acorn from "../beasts/hats/acorn.png";
import eggshell from "../beasts/hats/eggshell.png";
import mushroom from "../beasts/hats/mushroom.png";
import luckySerum from "./lucky-serum.png";

export const SHOP_IMAGES = {
  boc: boc,
  halo: halo,
  leaf: leaf,
  straw: straw,
  witch: witch,
  acorn: acorn,
  eggshell: eggshell,
  mushroom: mushroom,
  "lucky-serum": luckySerum,
};
