import { createSignal, Show, createEffect } from "solid-js";
import { BansService, PlayerBannedState } from "@/api-client";
import pastureSrc from "@/assets/loader/pasture.png";
import logoSrc from "@/assets/loader/loading_screen_logo.webp";
import banHammerSrc from "@/assets/loader/ban-hammer.png";
import "./Views.css";

export function BansWrapper(props: { children: any }) {
  const [banState, setBanState] = createSignal<PlayerBannedState>();

  createEffect(async () => {
    const thisBanState = await BansService.getBanState();
    setBanState(thisBanState);
  });

  return (
    <Show when={banState()?.status === "Banned"} fallback={props.children}>
      <div
        class="relative z-[90000] flex h-[100vh] w-[100vw] flex-col items-center justify-center"
        style={{
          "background-image": `url(${pastureSrc})`,
          "background-size": "cover",
          "background-position": "center bottom",
          "background-repeat": "no-repeat",
        }}
      >
        <img src={logoSrc} class="h-full w-full object-cover" />
        <div class="absolute bottom-[25%] left-[50%] box-border w-[95%] translate-x-[-50%] transform bg-black bg-opacity-65">
          <div
            class="flex items-center justify-center gap-2 p-2"
            style={{
              background: "rgba(255, 45, 0, 0.66)",
            }}
          >
            <img src={banHammerSrc} class="h-10 w-10" />
            <h2 class="eazy-chat text-2xl text-white">Banned</h2>
          </div>
          <div
            class="eazy-chat p-4 text-center text-xl text-white"
            style={{
              background: "rgba(0, 0, 0, 0.6)",
            }}
          >
            You have been permanently banned from Ranch for malicious activity /
            or cheating detected on your account.
          </div>
        </div>
      </div>
    </Show>
  );
}
