import { Actor, vec } from "excalibur";

import { Resources } from "../resources";

export class Background extends Actor {
  constructor(x: number, y: number, scale: number) {
    super({
      name: "background",
      x: x,
      y: y,
      anchor: vec(0.5, 1),
      scale: vec(scale, scale),
    });
  }

  public onInitialize(): void {
    this.graphics.use(Resources.Pasture.toSprite());
    this.z = -10;
  }
}