export const isDevelopment = import.meta.env.DEV;
export const isProduction = import.meta.env.PROD;

export const API_URL = import.meta.env.VITE_API_URL ?? "http://localhost:5171";
export const IS_RANCH_PROD = API_URL === "https://ranch-api.kuroro.com";
export const signalrUrl = `${API_URL}/game`;
export const POSTHOG_KEY = import.meta.env.VITE_POSTHOG_KEY || "phc_swuvri7t3YagIEehn50X9wAeT1oWjaOhHfMxGOMW8yj";
export const MAVIS_TRACKING_KEY = import.meta.env.VITE_MAVIS_TRACKING_KEY || "";
export const DEBUG_TOOLS = isDevelopment
  ? true
  : import.meta.env.VITE_DEBUG_TOOLS === "true";

console.log("Config is:", {
  isDevelopment,
  isProduction,
  API_URL,
  signalrUrl,
  POSTHOG_KEY,
});
