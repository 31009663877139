import * as rive from "@rive-app/canvas";
import giftBoxRiv from "@/assets/rive/giftbox-shards.riv";
import { onMount } from "solid-js";
import { playSfxSound, sounds } from "./sounds";

export function RiveAnimation(props: {
  coinsString: string;
  onClaim: () => void;
}) {
  let canvasRef: HTMLCanvasElement;
  let taps: number = 0;

  onMount(() => {
    console.log("PLEASE WORK");
    if (!canvasRef) return;

    const { innerWidth, innerHeight } = window;
    const size = Math.min(innerWidth, innerHeight);
    canvasRef.width = size;
    canvasRef.height = size * 1.5;

    const r = new rive.Rive({
      src: giftBoxRiv,
      // OR the path to a discoverable and public Rive asset
      // src: '/public/example.riv',
      canvas: canvasRef,
      layout: new rive.Layout({
        fit: rive.Fit.Contain,
        alignment: rive.Alignment.Center,
      }),
      autoplay: true,
      stateMachines: "GiftBoxReveal",
      onStateChange: (event: rive.Event) => {
        if (event.data) {
          let names = event.data as string[];
          if (names.includes("exit")) {
            console.log("State machine has reached an exit state");
            props.onClaim();
          }
        }
      },
      onLoad: () => {
        console.log("WORKS!");
        r.resizeDrawingSurfaceToCanvas();
        r.setTextRunValue("numCoins", props.coinsString);
      },
    });

    function onRiveEventReceived(riveEvent: rive.Event) {
      const eventData = riveEvent.data as rive.RiveEventPayload;
      const eventProperties = eventData?.properties;
      if (eventData.type === rive.RiveEventType.General) {
        console.log("Event name", eventData.name);
        taps += 1;
        playSfxSound("eggCrack");
      }
      if (taps >= 5) {
        const inputs = r.stateMachineInputs("GiftBoxReveal");
        const open = inputs.find((i) => i.name == "open");
        if (open) {
          open.value = true;
          playSfxSound("eggExplode");
        }
      }
    }

    // Add event listener and provide callback to handle Rive Event
    r.on(rive.EventType.RiveEvent, onRiveEventReceived);
  });

  return <canvas ref={canvasRef!} style={{ width: "100%", height: "100%" }} />;
}
