import { Howl } from "howler";
import sfxEat from "@/assets/sfx/feeding/sfx_Eat2.mp3";
import sfxNoShards from "@/assets/sfx/feeding/sfx_NoShards.mp3";
import sfxMine from "@/assets/sfx/mining/sfx_ShardTap1.mp3";
import sfxLuckyShardDrop from "@/assets/sfx/mining/sfx_ShardDrop2.mp3";
import sfxLevelUp from "@/assets/sfx/levelup/sfx_LevelUp.mp3";
import sfxBgMusicMP3 from "@/assets/sfx/bgm/mus_BG_Town.mp3";
import sfxPurchase from "@/assets/sfx/upgrades/cash-register.mp3";
import sfxWow from "@/assets/sfx/hatching/wow.mp3";
import sfxEggCrack from "@/assets/sfx/hatching/EggCrack.mp3";
import sfxEggExplode from "@/assets/sfx/hatching/EggExplode.mp3";
import sfxCoinTap from "@/assets/sfx/coins/coin-tap1.mp3";
import sfxEnergyDrop from "@/assets/sfx/energy/energy-drop.mp3";
import sfxEnergyUp from "@/assets/sfx/energy/energy-up.mp3";
import sfxPop from "@/assets/sfx/energy/pop.mp3";
import sfxBoop from "@/assets/sfx/raffle/boop.mp3";
import sfxMunch from "@/assets/sfx/raffle/munch.mp3";
import sfxButtonPush from "@/assets/sfx/raffle/button-push.mp3";
import sfxButtonPush2 from "@/assets/sfx/raffle/button-push2.mp3";
import sfxRaffleResult from "@/assets/sfx/raffle/raffle-result.mp3";
import sfxPing from "@/assets/sfx/raffle/ping.mp3";
import sfxRaffleBgm from "@/assets/sfx/raffle/raffle-bgm.mp3";
import { settingsStore } from "./settings-store";
import { isMobile } from "@/lib/utils";

let timeSfxLastPlayed: {[key: string]: number} = {};
var backgroundMusic: keyof typeof sounds = "bgMusic";

export const sounds = {
  eat: new Howl({ src: sfxEat }),
  mine: new Howl({ src: sfxMine }),
  luckyShardDrop: new Howl({ src: sfxLuckyShardDrop }),
  levelUp: new Howl({ src: sfxLevelUp }),
  noShards: new Howl({ src: sfxNoShards }),
  bgMusic: new Howl({
    src: sfxBgMusicMP3,
    html5: isMobile() ? false : true,
    loop: true,
    volume: 0.7,
    preload: isMobile() ? true : "metadata",
  }),
  raffleBgm:  new Howl({
    src: sfxRaffleBgm,
    loop: true,
    volume: 0.7,
  }),
  purchase: new Howl({ src: sfxPurchase }),
  wow: new Howl({ src: sfxWow }),
  eggCrack: new Howl({ src: sfxEggCrack }),
  eggExplode: new Howl({ src: sfxEggExplode }),
  coinTap1: new Howl({ src: sfxCoinTap }),
  energyDrop: new Howl({ src: sfxEnergyDrop }),
  energyUp: new Howl({ src: sfxEnergyUp }),
  pop: new Howl({ src: sfxPop }),
  boop: new Howl({ src: sfxBoop }),
  munch: new Howl({ src: sfxMunch }),
  buttonPush: new Howl({ src: sfxButtonPush }),
  buttonPush2: new Howl({ src: sfxButtonPush2 }),
  raffleResult: new Howl({ src: sfxRaffleResult }),
  ping: new Howl({ src: sfxPing }),
};

export const sfxSounds = [
  sounds.eat,
  sounds.mine,
  sounds.luckyShardDrop,
  sounds.levelUp,
  sounds.noShards,
  sounds.purchase,
  sounds.wow,
  sounds.eggCrack,
  sounds.eggExplode,
  sounds.coinTap1,
];

export const playSfxSound = (sound: keyof typeof sounds, rate: number = 1.0) => {
  let now = Date.now();
  if (now - timeSfxLastPlayed[sound] < 50) {
    // If less than 100ms has passed since the last sound, do nothing
    return;
  }
  sounds[sound].volume(settingsStore.sfxVolume);
  sounds[sound].rate(rate);
  sounds[sound].play();
  timeSfxLastPlayed[sound] = Date.now();
};

export const playBgMusic = () => {
  sounds[backgroundMusic].volume(settingsStore.bgMusicVolume);
  sounds[backgroundMusic].play();
};

export const setBgMusic = (bgm: keyof typeof sounds) => {
  sounds[bgm].volume(settingsStore.bgMusicVolume);
  sounds[backgroundMusic].stop();
  sounds[bgm].play();
  backgroundMusic = bgm;
};