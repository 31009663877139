import { Match, Switch } from "solid-js";
import { createAsync, Navigate } from "@solidjs/router";
import { getOnboardingState } from "@/components/cached-queries.ts";

// TODO: Probably move this to a layout
export function GameBootstrapRoute() {
  const onboardingState = createAsync(() => getOnboardingState());

  return (
    <Switch>
      <Match when={onboardingState()?.isCompleted === true}>
        <Navigate href="/play" />
      </Match>
      <Match when={onboardingState()?.isCompleted === false}>
        <Navigate href="/onboarding" />
      </Match>
    </Switch>
  );
}
