import { Actor, vec } from "excalibur";

import { Resources } from "@/game/resources";
import { Config } from "@/game/config";

export class RaffleTicket extends Actor {
  
  constructor(x: number, y: number) {
    super({ x: x, y: y });
  }

  public onInitialize(): void {
    this.name = 'raffle-ticket';
    let scale = Config.scale;
    this.scale = vec(scale, scale);
    this.anchor = vec(0.5, 1);

    let sprite = Resources.RaffleTicket.toSprite();
    this.graphics.use(sprite);
  }
}