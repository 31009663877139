import { Engine, Scene, Actor, Vector, Color } from "excalibur";

import { Resources } from "@/game/resources";
import { ItemSpawner, ItemType, SpawnType } from "../actors/item-spawner";
import { eventBus } from "@/components/event-bus.ts";
import { animManager } from "@/game/actors/animation-manager";
import { Background } from "@/game/actors/background";
import { LeafEmitter } from "@/game/actors/leaf-emitter";
import { FloatText } from "@/game/actors/float-text";
import { Config } from "../config";
import { createEffect } from "solid-js";
import { onboardingSelectors } from "@/components/onboarding-store";
import { OnboardingShardMine } from "../actors/onboarding-shard-mine";
import { OnboardingBeast } from "../actors/onboarding-beast";

export class OnboardingRanch extends Scene {
  background?: Actor;
  scale: number = 1;
  beastId?: number;
  beast?: OnboardingBeast;

  constructor() {
    super();

    createEffect(() => {
      if (onboardingSelectors.beastId() && !this.beastId) {
        const beastId = onboardingSelectors.beastId()!;
        this.beast?.kill();
        this.beastId = beastId;
        this.beast = new OnboardingBeast(beastId);
      }
    });
  }

  public onInitialize(engine: Engine) {
    engine.add(animManager);

    const viewport = engine.screen.getScreenBounds();
    this.scale = viewport.width / Resources.Pasture.width;
    Config.scale = this.scale;

    this.background = new Background(
      viewport.width / 2,
      viewport.height,
      this.scale,
    );
    this.add(this.background);

    if (this.beastId) {
      this.beast = new OnboardingBeast(this.beastId);
      this.add(this.beast);
    }
    this.add(new OnboardingShardMine());
    this.add(new LeafEmitter());

    eventBus.on("levelUpOnboarding", () => {
      if (this.beast) {
        let spawner = new ItemSpawner(
          SpawnType.Splash,
          ItemType.Coin,
          3,
          undefined,
          new Vector(this.beast.pos.x, this.beast.pos.y),
        );
        this.add(spawner);
        spawner.spawn(10);
        // this.camera.shake(10, 10, 10);

        let distanceFromTop = this.beast.pos.y - viewport.top;
        let floatHeight = Math.min(distanceFromTop * 0.6, 240);

        this.add(
          new FloatText(
            this.beast.pos.x,
            this.beast.pos.y - floatHeight,
            "LEVEL UP!",
            48,
            Color.White,
            "Eazy Chat",
            1000,
          ),
        );
      }
    });
  }
}
