import * as ex from "excalibur";
import {TiledResource} from "@excaliburjs/plugin-tiled"

import { Config } from "@/game/config";
import { Resources } from "@/game/resources";
import { Beast } from "@/game/actors/match/beast"
import { eventBus } from "@/components/event-bus";
import { TimerLabel } from "@/game/actors/match/timer-label";
import { Corral } from "@/game/actors/match/corral";

const idleImageModules = import.meta.glob('@/assets/beasts/idle/*.png', { eager: true });

import forestMapPath from "@/assets/match/maps/forest.tmx?url";

export const MatchResources = {
  forestMap: new TiledResource(forestMapPath, {
    //useTilemapCameraStrategy: true,
  }),
};

const specieStageIds = ["001", "010", "019", "028", "031", "034", "037", "061", "064", "070", "073"];
export var BeastAnims: {[id: string]: ex.Animation} = {};

export function _makeBeastAnim(imageSource: ex.ImageSource) {
  const spritesheet = ex.SpriteSheet.fromImageSource({
    image: imageSource,
    grid: {
      rows: 4,
      columns: 1,
      spriteWidth: 32,
      spriteHeight: 32,
    },
  });

  return ex.Animation.fromSpriteSheet(spritesheet, ex.range(0, 3), 200);
}

const getFileName = (path: string) => {
  const parts = path.split('/');
  const fileNameWithExtension = parts[parts.length - 1];
  return fileNameWithExtension.split('.')[0];
};

export class Match extends ex.Scene {
  private beasts: Beast[] = [];
  private timerLabel?: TimerLabel;
  private gameTime: number = 60; // 60 seconds game time
  private isGameOver: boolean = false;
  private gameOverLabel?: ex.Label;
  private level: number = 1;
  private corral?: Corral;

  override onPreLoad(loader: ex.DefaultLoader): void {
    for (const res of Object.values(MatchResources)) {
      loader.addResource(res);
    }
    for (const path in idleImageModules) {
      const module = idleImageModules[path] as { default: string };
      const imageName = getFileName(path);
      if (!specieStageIds.includes(imageName))
        continue;
      const imageSource = new ex.ImageSource(module.default);
      loader.addResource(imageSource);
      BeastAnims[`${imageName}-idle`] = _makeBeastAnim(imageSource);
    }
  }

  onInitialize(engine: ex.Engine) {
    //this.backgroundColor = ex.Color.fromRGB(128, 180, 100);
    const viewport = engine.screen.getScreenBounds();
    console.log(viewport);
    console.log(engine.drawWidth, engine.drawHeight);
    Config.scale = viewport.width / Resources.Pasture.width;

    eventBus.on('beastClicked', (clickedBeast: Beast) => {
      this.handleBeastClick(clickedBeast);
    });
  }

  onActivate(context: ex.SceneActivationContext<{ level: number }>): void {
    if (context.data && context.data.level) {
      this.level = context.data.level;
    }
    this.resetGame();
  }

  setupLevel() {
    const colors = [
      ex.Color.Red, ex.Color.Blue, ex.Color.Green, ex.Color.Yellow, ex.Color.Violet, ex.Color.Black,
      ex.Color.LightGray, ex.Color.Cyan, ex.Color.Orange, ex.Color.White
    ];
    const numPairs = Math.min(1 + this.level, colors.length);
    const random = new ex.Random();

    let layers = MatchResources.forestMap.getTileLayers();
    for (const layer of layers) {
      layer.tilemap.scale = ex.vec(Config.scale / 2, Config.scale / 2);
    }
    MatchResources.forestMap.addToScene(this, {
      pos: ex.vec(0, 0),
    });

    this.corral = new Corral(this.engine.drawWidth, this.engine.drawHeight);
    this.add(this.corral);

    for (let i = 0; i < numPairs; i++) {
      const color = colors[i];
      //const index = random.integer(0, specieStageIds.length - 1);
      const index = i;
      for (let j = 0; j < 2; j++) {
        const x = 20 + Math.random() * (this.engine.drawWidth - 40);
        const y = 20 + Math.random() * (this.engine.drawHeight - 150 - 40);
        const beast = new Beast(x, y, color, 15 + this.level * 10);
        beast.identifier = specieStageIds[index];
        this.beasts.push(beast);
        this.add(beast);
      }
    }

    // Adjust game time based on level
    this.gameTime = Math.max(20, 60 - this.level * 3);
    if (!this.timerLabel) {
      this.timerLabel = new TimerLabel(this.engine.drawWidth / 2, 20);
      this.timerLabel.z = 1000;
      this.add(this.timerLabel);
    }
    this.timerLabel.updateTime(this.gameTime);
  }

  resetGame() {
    this.beasts.forEach(beast => beast.kill());
    this.beasts = [];
    this.isGameOver = false;
    this.gameOverLabel?.kill();
    this.setupLevel();
  }

  onPreUpdate(_engine: ex.Engine, delta: number) {
    if (!this.isGameOver) {
      this.gameTime -= delta / 1000;
      this.timerLabel!.updateTime(this.gameTime);

      if (this.gameTime <= 0) {
        this.endGame(false);
      }
    }
  }

  handleBeastClick(clickedBeast: Beast) {
    if (this.isGameOver) return;

    const beastsInCorral = this.beasts.filter(beast => 
        beast.isInCorral() && !beast.isMatched()
    );

    if (beastsInCorral.length === 0 || 
        beastsInCorral.every(beast => beast.color.equal(clickedBeast.color))) {
        // Move the beast to the corral
        clickedBeast.moveToCorral(this.corral!);
        clickedBeast.actions.callMethod(() => {
          if (beastsInCorral.length > 0) {
            // If there's a match, set both beasts as matched
            const matchedBeast = beastsInCorral[0];
            clickedBeast.setMatched();
            matchedBeast.setMatched();

            if (this.beasts.every(beast => beast.isMatched())) {
                this.endGame(true);
            }
          }
        });
    }
}

  endGame(playerWon: boolean) {
    this.isGameOver = true;
    const viewport = this.engine.screen.getScreenBounds();

    const gameOverText = playerWon ? 'You Win!' : 'Game Over';
    this.gameOverLabel = new ex.Label({
      text: gameOverText,
      font: new ex.Font({
        family: 'Arial',
        size: 48,
        unit: ex.FontUnit.Px
      }),
      color: playerWon ? ex.Color.Green : ex.Color.Red,
      z: 200,
    });
    this.gameOverLabel.pos = new ex.Vector(viewport.width / 2, viewport.height / 2);
    this.gameOverLabel.anchor = new ex.Vector(0.5, 0.5);
    this.add(this.gameOverLabel);

    this.beasts.forEach(beast => {
      beast.actions.clearActions();
      beast.kill();
    });

    this.engine.clock.schedule(() => {
      this.engine.goToScene('levelSelection');
    }, 1000);
  }
}