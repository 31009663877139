import {
  Actor,
  Engine,
  Vector,
  Scene,
  Color,
  Text,
  Timer,
  Font,
  GraphicsGroup,
  Graphic,
  Transform,
  EasingFunctions,
  FontUnit,
  TextAlign,
  Label,
  AnimationStrategy,
  vec,
  ImageSource,
  SceneActivationContext,
  FrameEvent,
} from "excalibur";
import { Resources } from "../resources";
import { Config } from "../config";
import { eventBus } from "@/components/event-bus";
import { RaffleReward, RaffleTicketsService } from "@/api-client";
import { playSfxSound, setBgMusic } from "@/components/sounds";

export interface RaffleMachineData {
  initialTickets: number;
}

export class RaffleMachine extends Scene {
  private reel?: Reel = undefined;
  private spinButton?: Actor;
  scale: number = 1;
  idleAnim?: ex.Animation;
  spinAnim?: ex.Animation;
  buttonAnim?: ex.Animation;
  feedTicketAnim?: ex.Animation;
  progressBarAnim?: ex.Animation;
  tickets: number = 0;
  ticketsRemaining: number = 0;
  ticketsText?: Label;
  spinning = false;

  constructor() {
    super();
  }

  onActivate(context: SceneActivationContext<RaffleMachineData>): void {
    if (context.data) {
      const { initialTickets } = context.data;
      console.log(initialTickets);
      this.tickets = initialTickets!;
      this.ticketsRemaining = initialTickets!;
      this.ticketsText!.text = `x${this.ticketsRemaining}`;
    }

    console.log("WHATTTTTTTT");
    setBgMusic("raffleBgm");
  }

  onDeactivate(context: SceneActivationContext): void {
    setBgMusic("bgMusic");
  }

  onInitialize(engine: Engine) {
    const viewport = engine.screen.getScreenBounds();
    this.scale = viewport.width / Resources.Pasture.width;
    Config.scale = this.scale;

    this.idleAnim = Resources.RaffleMachine.getAnimation("default")!;
    this.spinAnim = Resources.RaffleMachine.getAnimation("lights")!;
    this.buttonAnim = Resources.RaffleMachine.getAnimation("push_button")!;
    this.feedTicketAnim = Resources.RaffleMachine.getAnimation("feed")!;
    this.progressBarAnim =
      Resources.TicketProgressBar.getAnimation("progress")!;
    this.progressBarAnim.pause();

    let bg = new Actor({
      x: engine.drawWidth / 2,
      y: engine.drawHeight,
      z: 1,
      anchor: new Vector(0.5, 1),
      scale: new Vector(Config.scale, Config.scale),
    });
    bg.graphics.use(this.idleAnim);
    this.add(bg);

    let progressBar = new Actor({
      x: engine.drawWidth / 2 - 29 * Config.scale,
      y: engine.drawHeight - 43 * Config.scale,
      z: 2,
      anchor: new Vector(0.5, 0.5),
      scale: new Vector(Config.scale, Config.scale),
    });
    progressBar.graphics.use(this.progressBarAnim);
    this.add(progressBar);

    (this.reel = new Reel(
      engine.drawWidth / 2,
      engine.drawHeight - 108 * Config.scale,
    )),
      this.add(this.reel);

    this.spinButton = new Actor({
      x: engine.drawWidth / 2 + 29 * Config.scale,
      y: engine.drawHeight - 43 * Config.scale,
      radius: 11 * Config.scale,
      anchor: new Vector(0.5, 0.5),
      color: Color.Transparent,
      z: 12,
    });
    this.add(this.spinButton);

    this.ticketsText = new Label({
      x: engine.drawWidth / 2 - 29 * Config.scale,
      y: engine.drawHeight - 46 * Config.scale,
      text: `x${this.ticketsRemaining}`,
      font: new Font({
        family: "Eazy Chat",
        size: 36,
        unit: FontUnit.Px,
        color: Color.White,
        strokeColor: Color.Black,
        lineWidth: 1,
        bold: true,
        textAlign: TextAlign.Center,
        shadow: {
          blur: 1,
          offset: new Vector(0, 0),
          color: Color.Black,
        },
      }),
      color: Color.White,
      anchor: new Vector(0.5, 0.5),
      z: 10,
    });
    this.add(this.ticketsText);

    const buttonText = new Label({
      x: engine.drawWidth / 2 + 29 * Config.scale,
      y: engine.drawHeight - 46 * Config.scale,
      text: "SPIN",
      font: new Font({
        family: "Eazy Chat",
        size: 36,
        unit: FontUnit.Px,
        color: Color.White,
        strokeColor: Color.Black,
        lineWidth: 1,
        bold: true,
        textAlign: TextAlign.Center,
        shadow: {
          blur: 1,
          offset: new Vector(0, 0),
          color: Color.Black,
        },
      }),
      color: Color.White,
      z: 10,
    });
    this.add(buttonText);

    this.buttonAnim.strategy = AnimationStrategy.Loop;
    this.buttonAnim.events.on("loop", () => {
      bg.graphics.use(this.feedTicketAnim!);
      this.ticketsText!.actions.scaleBy(vec(0.1, 0.1), 2)
        .callMethod(() => {
          this.ticketsRemaining -= 1;
          this.ticketsText!.text = `x${this.ticketsRemaining}`;
          this.progressBarAnim!.goToFrame(
            Math.floor(
              (1 - this.ticketsRemaining / this.tickets) *
                this.progressBarAnim!.frames.length,
            ),
          );
          console.log(this.progressBarAnim?.currentFrameIndex);
        })
        .scaleBy(vec(-0.1, -0.1), 2);
    });

    this.feedTicketAnim.strategy = AnimationStrategy.Loop;
    this.feedTicketAnim.events.on("frame", (frame: FrameEvent) => {
      if (frame.frameIndex === 2) {
        playSfxSound("munch");
      }
    });
    this.feedTicketAnim.events.on("loop", () => {
      bg.graphics.use(this.spinAnim!);
      this.spin();
    });
    this.spinAnim.strategy = AnimationStrategy.Loop;

    eventBus.on("stopSpinning", (raffleItem: RaffleItem) => {
      bg.graphics.use(this.idleAnim!);
      this.spinning = false;
      buttonText!.opacity = 1.0;
      console.log(`won raffle item ${raffleItem.name}!`);
      eventBus.emit("raffleResult", raffleItem);
    });

    this.spinButton.on("pointerdown", () => {
      if (this.spinning || this.ticketsRemaining <= 0) return;
      bg.graphics.use(this.buttonAnim!);
      this.spinning = true;
      buttonText!.opacity = 0.3;
      playSfxSound("buttonPush2");
    });

    const backButton = new Label({
      x: 56,
      y: engine.drawHeight - 52,
      text: "Back",
      font: new Font({
        family: "Eazy Chat",
        size: 36,
        unit: FontUnit.Px,
        color: Color.White,
        strokeColor: Color.Black,
        lineWidth: 1,
        bold: true,
        textAlign: TextAlign.Center,
        shadow: {
          blur: 1,
          offset: new Vector(0, 0),
          color: Color.Black,
        },
      }),
      color: Color.White,
      z: 10,
    });
    backButton.on("pointerdown", () => {
      eventBus.emit("goToRoute", "/play");
    });
    this.add(backButton);
  }

  spin() {
    this.reel?.spin();
  }

  public onPreUpdate(engine: Engine, delta: number): void {
    if (this.reel && this.reel.spinning) {
      let elapsedTime = this.reel!.getElapsedTime(engine);
      if (elapsedTime > 4500) {
        let rate = 1 - (elapsedTime - 4500) / 5500;
        this.spinAnim!.speed = rate;
      } else {
        this.spinAnim!.speed = 1;
      }
    }
  }
}

export type RaffleItem = {
  rewardId: RaffleReward;
  itemId: string;
  name: string;
  rarity: string;
  image: ImageSource;
  count: number;
  description: string;
};

export const RAFFLE_ITEMS: RaffleItem[] = [
  {
    name: "Bronze Treasure Egg",
    image: Resources.RaffleBronzeEgg,
    rewardId: "BronzeTreasureEgg",
    itemId: "bronze-treasure-egg",
    count: 1,
    description:
      'Mysterious egg that opens during "The Hatchening" Airdrop event to reveal a special prize!',
    rarity: "Uncommon",
  },
  {
    name: "Silver Treasure Egg",
    image: Resources.RaffleSilverEgg,
    rewardId: "SilverTreasureEgg",
    itemId: "silver-treasure-egg",
    count: 1,
    description:
      'Mysterious egg that opens during "The Hatchening" Airdrop event to reveal a special prize!',
    rarity: "Rare",
  },
  {
    name: "Gold Treasure Egg",
    image: Resources.RaffleGoldEgg,
    rewardId: "GoldenTreasureEgg",
    itemId: "golden-treasure-egg",
    count: 1,
    description:
      'Mysterious egg that opens during "The Hatchening" Airdrop event to reveal a special prize!',
    rarity: "Epic",
  },
  {
    name: "Energy Ball",
    image: Resources.RaffleEnergyBall,
    rewardId: "EnergyBall",
    itemId: "energy-ball",
    count: 1,
    description: "Bonus Energy Ball! Activate in your Airdrop rewards.",
    rarity: "Common",
  },
  {
    name: "Lucky Serum",
    image: Resources.RaffleLuckySerum,
    rewardId: "LuckySerum",
    itemId: "lucky-serum",
    count: 1,
    description:
      "Transform your Beast into a Lucky Beast! Activate in your Airdrop rewards.",
    rarity: "Mythic",
  },
  {
    name: "Shards",
    image: Resources.RaffleShard,
    rewardId: "Shards",
    itemId: "shards",
    count: 300,
    description: "300 Shards! Redeem in your Airdrop rewards.",
    rarity: "Common",
  },
  {
    name: "Silver Ticket",
    image: Resources.RaffleTSikverTicker,
    rewardId: "SilverTicket",
    itemId: "silver-ferry-ticket",
    count: 1,
    description:
      "Silver Tickets can be redeemed by opening a support ticket in our Discord and providing your Telegram name + wallet address.",
    rarity: "Legendary",
  },
  {
    name: "$Kuro",
    image: Resources.RaffleKuro,
    rewardId: "Kuro100",
    itemId: "kuro",
    count: 100,
    description:
      "You won 100 $KURO! Unlocks at TGE after 'The Hatchening' Airdrop event",
    rarity: "Epic",
  },
  {
    name: "$Kuro",
    image: Resources.RaffleKuro,
    rewardId: "Kuro500",
    itemId: "kuro",
    count: 500,
    description:
      "You won 500 $KURO! Unlocks at TGE after 'The Hatchening' Airdrop event",
    rarity: "Mythic",
  },
  {
    name: "$Kuro",
    image: Resources.RaffleKuro,
    rewardId: "Kuro50K",
    itemId: "kuro",
    count: 50000,
    description:
      "You won the grand prize of 50,000 $KURO! Unlocks at TGE after 'The Hatchening' Airdrop event",
    rarity: "Legendary",
  },
  {
    rewardId: "EnergyDrink",
    itemId: "energy-drink",
    name: "Energy Drink",
    image: Resources.EnergyDrink,
    count: 1,
    description:
      "For 15 seconds you’ll have Unlimited Energy to mine as much as you can!",
    rarity: "Common",
  },
  {
    name: "$Kuro",
    image: Resources.RaffleKuro,
    rewardId: "Kuro50",
    itemId: "kuro",
    count: 50,
    description:
      "You won 50 $KURO! Unlocks at TGE after 'The Hatchening' Airdrop event",
    rarity: "Epic",
  },
];

class Reel extends Actor {
  private items: Actor[];
  private itemWidth: number = 32 * Config.scale;
  public spinning: boolean = false;
  private itemOffset: number = 0;
  private background: Actor;
  private chosenItemIndex: number = 2;
  private spinDuration: number = 10000;
  private spinStartTime: number = 0;
  private initialOffset: number = 0;
  private targetOffset: number = 0;
  private debugIndicator: Actor;

  constructor(x: number, y: number) {
    super({
      x: x,
      y: y,
      width: 32 * 5 * Config.scale,
      height: 32 * Config.scale,
      anchor: new Vector(0.5, 0.5),
    });
    this.items = this.createItems();
    this.background = new Actor({
      width: this.width,
      height: this.height,
      color: Color.White,
    });
    this.debugIndicator = new Actor({
      width: 5,
      height: this.height,
      color: Color.Red,
    });
  }

  onInitialize(engine: Engine) {
    this.addChild(this.background);
    this.items.forEach((item) => this.addChild(item));
    //this.addChild(this.debugIndicator);
    this.updateItemPositions();
  }

  createItems(): Actor[] {
    return RAFFLE_ITEMS.map((item) => {
      let actor = new Actor({
        scale: vec(Config.scale / 2, Config.scale / 2),
      });
      actor.graphics.use(item.image.toSprite());
      if (item.count > 1) {
        let label = new Label({
          x: 0,
          y: 10,
          text: `x${item.count}`,
          font: new Font({
            family: "Eazy Chat",
            size: 18,
            unit: FontUnit.Px,
            color: Color.White,
            strokeColor: Color.Black,
            lineWidth: 1,
            bold: true,
            textAlign: TextAlign.Center,
            shadow: {
              blur: 1,
              offset: new Vector(0, 0),
              color: Color.Black,
            },
          }),
          color: Color.White,
        });
        actor.addChild(label);
      }
      return actor;
    });
  }

  onPreUpdate(engine: Engine, delta: number) {
    if (this.spinning) {
      const elapsedTime = this.getElapsedTime(engine);
      const progress = Math.min(elapsedTime / this.spinDuration, 1);

      const easedProgress = this.customEasing(progress);

      this.itemOffset =
        this.initialOffset +
        (this.targetOffset - this.initialOffset) * easedProgress;
      this.updateItemPositions();

      if (progress >= 1) {
        this.stopSpinning();
      }
    }
  }

  getElapsedTime(engine: Engine) {
    const elapsedTime =
      engine.currentScene.engine.clock.now() - this.spinStartTime;
    return elapsedTime;
  }

  updateItemPositions() {
    const totalItems = this.items.length;
    this.items.forEach((item, index) => {
      let x =
        (index * this.itemWidth - this.itemOffset) %
        (totalItems * this.itemWidth);

      // Adjust to be relative to the center
      x -= this.width / 2 - this.itemWidth / 2;

      // Wrap around effect
      if (x < -this.width / 2 - this.itemWidth / 2) {
        x += totalItems * this.itemWidth;
      }

      if (item.pos.x > this.width / 2 && x < this.width / 2) {
        playSfxSound("ping");
      }

      item.pos = new Vector(x, 0);
    });

    // Update debug indicator position
    this.debugIndicator.pos = new Vector(0, 0);
  }

  spin() {
    if (!this.spinning) {
      this.useRaffleTicket().then(() => {
        this.spinning = true;
      });
    }
  }

  useRaffleTicket() {
    return RaffleTicketsService.useRaffleTicket().then((reward) => {
      const rewardItemIndex = RAFFLE_ITEMS.findIndex(
        (item) => item.rewardId === reward,
      );

      this.spinStartTime = this.scene!.engine.clock.now();
      this.initialOffset = this.itemOffset;
      this.calculateTargetOffset(rewardItemIndex);
      this.chosenItemIndex = rewardItemIndex;

      console.log({
        chosenItemIndex: this.chosenItemIndex,
        initialOffset: this.initialOffset,
        itemOffset: this.itemOffset,
        targetOffset: this.targetOffset,
      });
    });
  }

  calculateTargetOffset(targetIndex: number) {
    const totalItems = this.items.length;

    // itemOffset: distance from start to current position (NOT CURRENT ITEM)
    // itemWidth: width of each item

    // itemOffset to item index?

    const distanceToChosen =
      (targetIndex - this.chosenItemIndex + this.items.length) %
      this.items.length;
    // Increase the minimum rotations for a faster initial spin
    const minRotations = 4;
    this.targetOffset =
      this.itemOffset +
      (minRotations * totalItems + distanceToChosen) * this.itemWidth;
  }

  stopSpinning() {
    this.spinning = false;
    this.itemOffset = this.targetOffset % (this.items.length * this.itemWidth);
    this.updateItemPositions();

    this.items[this.chosenItemIndex].color = Color.White;

    eventBus.emit("stopSpinning", RAFFLE_ITEMS[this.chosenItemIndex]);
  }

  private customEasing(t: number): number {
    // Fast start (first half)
    if (t < 0.45) {
      return 5 * t * t;
    }
    // Slow end (second half)
    const overshoot = 0.05;
    return 1 - Math.pow(-2 * t + 2, 2) / 2 + Math.sin(t * Math.PI) * overshoot;
  }
}
