import {
  Actor,
  DefaultLoader,
  Engine,
  ImageSource,
  Scene,
  Sound,
  vec,
} from "excalibur";
import { Resources } from "../resources";
import { Background } from "../actors/background";
import { Config } from "../config";
import { LeafEmitter } from "../actors/leaf-emitter";
import { eventBus } from "@/components/event-bus";
import { OnboardingEgg } from "../actors/onboarding-egg";
import { OnboardingBeast } from "../actors/onboarding-beast";
import eggBlueSrc from "@/assets/objects/eggs/egg-blue.png";
import eggGreenSrc from "@/assets/objects/eggs/egg-green.png";
import eggYellowSrc from "@/assets/objects/eggs/egg-yellow.png";
import eggBlueHatchSrc from "@/assets/objects/eggs/egg-explosion-blue.png";
import eggGreenHatchSrc from "@/assets/objects/eggs/egg-explosion-green.png";
import eggYellowHatchSrc from "@/assets/objects/eggs/egg-explosion-yellow.png";
import nestSrc from "@/assets/objects/egg-nest.png";
import nestFrontSrc from "@/assets/objects/egg-nest-front.png";
import eggCrackSrc from "@/assets/sfx/hatching/EggCrack.mp3";
import eggExplodeSrc from "@/assets/sfx/hatching/EggExplode.mp3";
import eggWhooshSrc from "@/assets/sfx/hatching/EggWhoosh.mp3";
import { createEffect } from "solid-js";
import {
  onboardingSelectors,
  OnboardingStep,
} from "@/components/onboarding-store";

export type OnboardingResources = {
  EggBlue: ImageSource;
  EggGreen: ImageSource;
  EggYellow: ImageSource;
  EggBlueHatch: ImageSource;
  EggGreenHatch: ImageSource;
  EggYellowHatch: ImageSource;
  Nest: ImageSource;
  NestFront: ImageSource;
  EggCrack: Sound;
  EggExplosion: Sound;
  EggWhoosh: Sound;
};

export class Onboarding extends Scene {
  background?: Actor;
  scale: number = 1;
  beastId?: number;
  beast?: OnboardingBeast;
  egg?: OnboardingEgg;
  onboardingResources?: OnboardingResources;
  outlineMaterial: ex.Material | null = null;

  constructor() {
    super();

    createEffect(() => {
      if (onboardingSelectors.beastId() && !this.beastId){
        const beastId = onboardingSelectors.beastId()!;
        this.beastId = beastId;
        this.egg = new OnboardingEgg(beastId);
        this.egg.z = 1000;
        this.egg.showOutline();
        this.add(this.egg);
      }
    });
  }

  override onPreLoad(loader: DefaultLoader): void {
    this.onboardingResources = {
      EggBlue: new ImageSource(eggBlueSrc),
      EggGreen: new ImageSource(eggGreenSrc),
      EggYellow: new ImageSource(eggYellowSrc),
      EggBlueHatch: new ImageSource(eggBlueHatchSrc),
      EggGreenHatch: new ImageSource(eggGreenHatchSrc),
      EggYellowHatch: new ImageSource(eggYellowHatchSrc),
      Nest: new ImageSource(nestSrc),
      NestFront: new ImageSource(nestFrontSrc),
      EggCrack: new Sound(eggCrackSrc),
      EggExplosion: new Sound(eggExplodeSrc),
      EggWhoosh: new Sound(eggWhooshSrc),
    };
    for (const res of Object.values(this.onboardingResources)) {
      loader.addResource(res);
    }
  }

  onInitialize(engine: Engine<any>): void {
    const viewport = engine.screen.getScreenBounds();
    this.scale = viewport.width / Resources.Pasture.width;
    Config.scale = this.scale;

    this.background = new Background(
      viewport.width / 2,
      viewport.height,
      this.scale,
    );
    this.add(this.background);

    let nest = new Actor({
      x: viewport.width / 2,
      y: viewport.height - 260,
    });
    nest.graphics.use(this.onboardingResources!.Nest.toSprite());
    nest.scale = vec(this.scale, this.scale);
    nest.anchor = vec(0.5, 1);
    //this.add(nest);

    let nestFront = new Actor({
      x: viewport.width / 2,
      y: viewport.height - 260,
    });
    nestFront.graphics.use(this.onboardingResources!.NestFront.toSprite());
    nestFront.scale = vec(this.scale, this.scale);
    nestFront.anchor = vec(0.5, 1);
    //this.add(nestFront);

    this.add(new LeafEmitter());

    eventBus.on("beastHatchedOnboarding", () => {
      this.engine.goToScene("onboarding-ranch");
    });
  }
}
