import {
  playerStore,
  playerStoreSelector,
  refreshPlayerState,
  takeCoins,
} from "@/components/player-store.ts";
import { Button } from "@/components/ui/button.tsx";
import { createSignal, Index, Match, Show, Switch } from "solid-js";
import { Card, CardContent } from "@/components/ui/card.tsx";
import { TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs.tsx";
import { Tabs } from "@kobalte/core/tabs";
import {
  createMutation,
  createQuery,
  useQueryClient,
} from "@tanstack/solid-query";
import { gameConfig } from "@/components/lib/game-config.ts";
import {
  arrowDown,
  coinIcon,
  rewardBg,
  upgradeStarImg,
} from "@/assets/images.ts";
import { createAutoRefreshValue } from "@/lib/hooks.ts";
import { captureEvent } from "@/lib/analytics";
import { UPGRADE_IMAGES } from "@/assets/upgrades/upgrade-images";
import { PageContainer } from "@/components/PageContainer";
import { useIsRouting, useSearchParams } from "@solidjs/router";
import { Suspense } from "solid-js/web";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { playSfxSound } from "@/components/sounds";
import "../components/upgrades.css";
import StarBurst from "@/components/starburst";
import { CloseButton } from "@/components/CloseButton";
import { cn, formatCompactNumber } from "@/lib/utils";
import { PurchasableUpgrade, UpgradesService } from "@/api-client";
import { BoostersIcon, UpgradesIcon } from "@/assets/extra-icons";
import BoostersTab from "@/components/BoostersTab";
import {
  ftueBoosterActions,
  FTUEBoostersStep,
  ftueBoosterStoreSelectors,
} from "@/components/DialogBoostersOverlay";
import posthog from "posthog-js";
import { showToast } from "@/components/ui/toast";

function UpgradeCard(props: {
  purchasableUpgrade: PurchasableUpgrade;
  tabsValue: TabValue;
}) {
  const [isAnimating, setIsAnimating] = createSignal(false);
  const [isBursting, setIsBursting] = createSignal(false);
  const startAnimation = () => {
    triggerBurst();
    setIsAnimating(true);
    const timer = setTimeout(() => {
      setIsAnimating(false);
      clearTimeout(timer);
    }, 2000);
  };

  const triggerBurst = () => {
    setIsBursting(true);
  };

  const handleAnimationComplete = () => {
    setIsBursting(false);
  };

  const queryClient = useQueryClient();
  const buyUpgradeMutation = createMutation(() => ({
    mutationFn: () => {
      return UpgradesService.buyUpgrade({
        requestBody: {
          upgradeId: props.purchasableUpgrade.upgradeId,
        },
      });
    },
    onSuccess: (result) => {
      refreshPlayerState();
      captureEvent("buy_upgrade", {
        upgradeId: props.purchasableUpgrade.upgradeId,
        upgradeName: props.purchasableUpgrade.name,
        upgradeLevel: props.purchasableUpgrade.level,
      });

      startAnimation();
      playSfxSound("purchase");
      queryClient.setQueryData(
        ["purchasableUpgradesQuery"],
        (data: PurchasableUpgrade[]) =>
          data.map((u) => (u.upgradeId === result.upgradeId ? result : u)),
      );
    },
    onError: async (error) => {
      console.error("Error buying upgrade", error);
      showToast({
        title: "Error buying upgrades",
        description: "Reloading state",
      });
      await refreshPlayerState();
      queryClient.invalidateQueries({
        queryKey: ["purchasableUpgradesQuery"],
      });
    },
  }));

  const canBuy = () =>
    playerStoreSelector.coins() >= props.purchasableUpgrade.cost;

  const buttonText = () => {
    if (buyUpgradeMutation.isPending) {
      return "Buying...";
    } else if (canBuy()) {
      return (
        <div class="flex items-center gap-2">
          Buy{" "}
          <div class="flex items-center justify-center gap-1">
            <img src={coinIcon} class="h-5 w-5 object-contain" />
            <span class="mt-1">
              {formatCompactNumber(props.purchasableUpgrade.cost)}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div class="flex items-center gap-2">
          Need{" "}
          <div class="flex items-center justify-center gap-1">
            <img src={coinIcon} class="h-5 w-5 object-contain" />
            <span class="mt-1">
              {formatCompactNumber(props.purchasableUpgrade.cost)}
            </span>
          </div>
        </div>
      );
    }
  };

  return (
    <div class="flex flex-1" style={{ width: "100%" }}>
      <Card
        class="relative flex flex-1 border bg-cover bg-center bg-no-repeat text-white"
        style={{
          "box-shadow": "0px 2px 4px 0px #740F0F40",
          "border-color": props.purchasableUpgrade.canBePurchased
            ? CARD_BORDER[props.tabsValue]
            : "#F2935E",
          "background-color": props.purchasableUpgrade.canBePurchased
            ? CARD_BG[props.tabsValue]
            : "#FFBA68",
        }}
      >
        <div class={`${isAnimating() ? "green" : ""}`} style={{}}></div>
        <div
          class="absolute bottom-0 left-0 right-0 top-0 z-[5] min-h-full min-w-full bg-cover bg-center bg-no-repeat"
          style={{
            "background-image": `url(${rewardBg})`,
          }}
        />
        <CardContent class="z-10 flex h-full w-full flex-col justify-between gap-2 p-2">
          <div class="flex flex-col">
            <div class="z-10 flex justify-between">
              <div>
                <h3 class="eazy-chat text-outline z-10 text-lg font-medium leading-5">
                  {props.purchasableUpgrade.name} <br />
                </h3>
              </div>
              <StarBurst
                starCount={8}
                starColor="#ff9900"
                burstDistance={100}
                starSize={30}
                duration={1000}
                isBursting={isBursting()}
                onAnimationComplete={handleAnimationComplete}
              >
                <Show when={!props.purchasableUpgrade.canBePurchased}>
                  <div
                    class="absolute -bottom-2 -left-2 -right-2 -top-2 h-12"
                    style={{
                      background: `url(${upgradeStarImg})`,
                      "background-size": "contain",
                      "background-repeat": "no-repeat",
                    }}
                  ></div>
                </Show>
                <div
                  class="relative mt-1 flex h-6 w-7 items-center justify-center rounded-full p-4 text-sm"
                  style={{
                    "background-color": props.purchasableUpgrade.canBePurchased
                      ? CARD_LEVEL_BG[props.tabsValue]
                      : "transparent",
                  }}
                >
                  <div classList={{ "level-green": isAnimating() }}></div>
                  <div
                    class={`star-container ${isAnimating() ? "animate" : ""}`}
                  >
                    <div class="star"></div>
                    <div class="star2"></div>
                  </div>
                  <span class="eazy-chat text-outline z-10 mt-1 text-xl">
                    {props.purchasableUpgrade.canBePurchased
                      ? props.purchasableUpgrade.level
                      : props.purchasableUpgrade.level - 1}
                  </span>
                </div>
              </StarBurst>
            </div>
            <div class="flex items-center justify-center">
              <img
                class="z-10 h-14 w-14 object-contain"
                src={
                  UPGRADE_IMAGES[
                    props.purchasableUpgrade
                      .upgradeId as keyof typeof UPGRADE_IMAGES
                  ]
                }
              />
            </div>
            <p class="eazy-chat text-outline z-10 text-center text-sm text-white">
              {`+${props.purchasableUpgrade.canBePurchased ? formatCompactNumber(props.purchasableUpgrade.earnIncrement) : formatCompactNumber(props.purchasableUpgrade.currentEarnAmount)}`}{" "}
              coins/{gameConfig.coins.earningUnit[0]}
            </p>
          </div>
          <Show when={props.purchasableUpgrade.canBePurchased}>
            <Button
              onClick={() => {
                // Deduct balance immediately
                takeCoins(props.purchasableUpgrade.cost);
                buyUpgradeMutation.mutate();
              }}
              disabled={buyUpgradeMutation.isPending || !canBuy()}
              size="sm"
              class="eazy-chat text-outline relative z-10 min-w-fit border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
            >
              <div
                class="rounded-md"
                classList={{ "button-green": isAnimating() }}
              ></div>
              <span class="z-10">{buttonText()} </span>
            </Button>
          </Show>
          <Show when={!props.purchasableUpgrade.canBePurchased}>
            <Button
              size="sm"
              class="eazy-chat text-outline relative z-10 min-w-fit bg-[#F5894C] text-lg leading-4 hover:opacity-70"
            >
              <div class="rounded-md"></div>
              <span class="z-10 mt-1">Max Level!</span>
            </Button>
          </Show>
        </CardContent>
      </Card>
    </div>
  );
}

function UpgradesList(props: {
  purchasableUpgrades: PurchasableUpgrade[];
  tabsValue: TabValue;
}) {
  return (
    <div class="grid grid-cols-2 gap-2 p-3">
      <Index each={props.purchasableUpgrades}>
        {(purchasableUpgrade, index) => (
          <UpgradeCard
            purchasableUpgrade={purchasableUpgrade()}
            tabsValue={props.tabsValue}
          />
        )}
      </Index>
    </div>
  );
}

const TAB_BG_CONTAINER = {
  BeastCare: "linear-gradient(180deg, #DC5555 19.38%, #CA3E3C 87.38%)",
  Ranch: "linear-gradient(180deg, #5AC85E 19.38%, #24A749 87.38%)",
  Tech: "linear-gradient(180deg, #4C80CE 19.38%, #3854BC 87.38%)",
};

const TAB_LIST_BG = {
  BeastCare: "#9B2222",
  Ranch: "#217639",
  Tech: "#214376",
};

const TAB_BG = {
  BeastCare: "linear-gradient(180deg, #DC5555 28.5%, #CA3E3C 100%)",
  Ranch: "linear-gradient(180deg, #5AC85E 28.5%, #24A749 100%)",
  Tech: "linear-gradient(180deg, #4777C0 28.5%, #3854BC 100%)",
};

const TAB_TEXT_OUTLINE = {
  BeastCare: "#902626",
  Ranch: "#348646",
  Tech: "#204781",
};

const TAB_COIN_BG = {
  BeastCare: "#C13939",
  Ranch: "#39A04A",
  Tech: "#3C60A6",
};

const CARD_BG = {
  BeastCare: "#F6B9B0",
  Ranch: "#94DE89",
  Tech: "#93BEF0",
};

const CARD_BORDER = {
  BeastCare: "#E78882",
  Ranch: "#4DBD59",
  Tech: "#5482DB",
};

const CARD_LEVEL_BG = {
  BeastCare: "#E78882",
  Ranch: "#78BA72",
  Tech: "#72A0BA",
};

type TabValue = "BeastCare" | "Ranch" | "Tech";

export default function UpgradesRoute() {
  const [tabsValue, setTabsValue] = createSignal<TabValue>("BeastCare");
  const [searchParams, setSearchParams] = useSearchParams();
  const mainActiveTab = () => searchParams.tab || "upgrades";

  const purchasableUpgradesQuery = createQuery(() => ({
    queryKey: ["purchasableUpgradesQuery"],
    queryFn: async () => {
      return UpgradesService.getPurchasableUpgrades().then((r) =>
        r.sort((a, b) =>
          a.canBePurchased == b.canBePurchased ? 0 : a.canBePurchased ? -1 : 1,
        ),
      );
    },
  }));

  const coins = createAutoRefreshValue(playerStoreSelector.coins);
  const categoryUpgrades = (category: string) =>
    purchasableUpgradesQuery.data?.filter((u) => u.category === category) ?? [];
  const beastCareUpgrades = () => categoryUpgrades("BeastCare");
  const ranchUpgrades = () => categoryUpgrades("Ranch");
  const techUpgrades = () => categoryUpgrades("Tech");

  const isRouting = useIsRouting();

  return (
    <PageContainer>
      <Tabs
        value={tabsValue()}
        onChange={setTabsValue}
        class={cn(
          "flex h-full flex-col",
          ftueBoosterStoreSelectors.initialized() &&
            !ftueBoosterStoreSelectors.finished() &&
            ftueBoosterStoreSelectors.currentStep() ===
              FTUEBoostersStep.TapBoosters &&
            "pointer-events-none",
        )}
      >
        <div
          style={{
            background: TAB_BG_CONTAINER[tabsValue()],
          }}
          class="sticky top-0 z-20 rounded-t-3xl border border-[#561212] px-3 py-2"
        >
          <CloseButton />
          <h2 class="flex justify-center gap-2 py-2 text-center font-bold">
            <img src={coinIcon} class="z-[1] h-[28px] w-[28px]" />
            <span
              style={{ background: TAB_COIN_BG[tabsValue()] }}
              class="eazy-chat text-outline -ml-8 rounded-full px-4 pl-8 pt-1"
            >
              <span class="text-white">{formatCompactNumber(coins())}</span>
              <span class="text-orange-300">
                {" "}
                + {formatCompactNumber(playerStore.coinsEarningRate)}/
                {gameConfig.coins.earningUnit[0]}
              </span>
            </span>
          </h2>
          <div
            class="flex w-full gap-2 rounded-t-md p-2"
            style={{
              background: TAB_LIST_BG[tabsValue()],
            }}
          >
            <Button
              class={cn(
                "flex flex-1 gap-2 border-2 border-transparent text-white opacity-50",
                mainActiveTab() === "upgrades" && "border-black opacity-100",
              )}
              style={{
                background:
                  mainActiveTab() === "upgrades"
                    ? TAB_BG[tabsValue()]
                    : "transparent",
              }}
              onClick={() => {
                setSearchParams({ tab: "upgrades" });
              }}
            >
              <UpgradesIcon />
              <span class="eazy-chat text-outline-1 text-xl">Upgrades</span>
            </Button>
            <Show when={posthog.isFeatureEnabled("boosters")}>
              <Button
                class={cn(
                  "relative flex flex-1 gap-2 border-2 border-transparent text-white",
                  mainActiveTab() === "boosters" && "border-black opacity-100",
                  ftueBoosterStoreSelectors.initialized() &&
                    !ftueBoosterStoreSelectors.finished() &&
                    ftueBoosterStoreSelectors.currentStep() ===
                      FTUEBoostersStep.TapBoosters &&
                    "pointer-events-auto",
                )}
                style={{
                  background:
                    mainActiveTab() === "boosters"
                      ? TAB_BG[tabsValue()]
                      : "transparent",
                }}
                onClick={() => {
                  setSearchParams({ tab: "boosters" });
                  if (
                    ftueBoosterStoreSelectors.initialized() &&
                    !ftueBoosterStoreSelectors.finished()
                  ) {
                    ftueBoosterActions.moveToNextStep();
                  }
                }}
              >
                <Show
                  when={
                    ftueBoosterStoreSelectors.initialized() &&
                    !ftueBoosterStoreSelectors.finished() &&
                    ftueBoosterStoreSelectors.currentStep() ===
                      FTUEBoostersStep.TapBoosters
                  }
                >
                  <img
                    src={arrowDown}
                    class="up-and-down absolute -top-[50px] z-10 mt-2 w-[60px] object-contain opacity-100"
                  />
                </Show>
                <div
                  class={cn(
                    "opacity-50",
                    mainActiveTab() === "boosters" && "opacity-100",
                  )}
                >
                  <BoostersIcon />
                </div>
                <span
                  class={cn(
                    "eazy-chat text-outline-1 text-xl opacity-50",
                    mainActiveTab() === "boosters" && "opacity-100",
                  )}
                >
                  Boosters
                </span>
              </Button>
            </Show>
          </div>
          <Show when={mainActiveTab() === "upgrades"}>
            <TabsList
              class="grid w-full grid-cols-3 gap-2 rounded-t-none p-2 pt-0"
              style={{
                background: TAB_LIST_BG[tabsValue()],
              }}
            >
              <TabsTrigger
                value="BeastCare"
                class="text-white opacity-50 data-[selected]:text-white data-[selected]:opacity-100"
                style={{
                  background: TAB_BG[tabsValue()],
                }}
              >
                <span
                  class="eazy-chat text-outline text-lg"
                  style={{
                    "-webkit-text-stroke-color": TAB_TEXT_OUTLINE[tabsValue()],
                  }}
                >
                  Beast Care
                </span>
              </TabsTrigger>
              <TabsTrigger
                value="Ranch"
                class="text-white opacity-50 data-[selected]:text-white data-[selected]:opacity-100"
                style={{
                  background: TAB_BG[tabsValue()],
                }}
              >
                <span
                  class="eazy-chat text-outline text-lg"
                  style={{
                    "-webkit-text-stroke-color": TAB_TEXT_OUTLINE[tabsValue()],
                  }}
                >
                  Ranch
                </span>
              </TabsTrigger>
              <TabsTrigger
                value="Tech"
                class="text-white opacity-50 data-[selected]:text-white data-[selected]:opacity-100"
                style={{
                  background: TAB_BG[tabsValue()],
                }}
              >
                <span
                  class="eazy-chat text-outline text-lg"
                  style={{
                    "-webkit-text-stroke-color": TAB_TEXT_OUTLINE[tabsValue()],
                  }}
                >
                  Tech
                </span>
              </TabsTrigger>
            </TabsList>
          </Show>
        </div>
        <Show when={!isRouting()} fallback={<div>LOADINGGGG</div>}>
          <Suspense
            fallback={
              <div class="grid grid-cols-2 gap-2 overflow-y-auto bg-[#FFE7DF] p-3">
                <Skeleton
                  class="rounded-lg border border-[#E78882] bg-[#F6B9B0]"
                  height={200}
                />
                <Skeleton
                  class="rounded-lg border border-[#E78882] bg-[#F6B9B0]"
                  height={200}
                />
                <Skeleton
                  class="rounded-lg border border-[#E78882] bg-[#F6B9B0]"
                  height={200}
                />
                <Skeleton
                  class="rounded-lg border border-[#E78882] bg-[#F6B9B0]"
                  height={200}
                />
              </div>
            }
          >
            <Switch>
              <Match when={mainActiveTab() === "upgrades"}>
                <TabsContent
                  value="BeastCare"
                  class="overflow-y-auto overscroll-y-contain pb-10"
                >
                  <UpgradesList
                    purchasableUpgrades={beastCareUpgrades()}
                    tabsValue={tabsValue()}
                  />
                </TabsContent>
                <TabsContent
                  value="Ranch"
                  class="overflow-y-auto overscroll-y-contain pb-10"
                >
                  <UpgradesList
                    purchasableUpgrades={ranchUpgrades()}
                    tabsValue={tabsValue()}
                  />
                </TabsContent>
                <TabsContent
                  value="Tech"
                  class="overflow-y-auto overscroll-y-contain pb-10"
                >
                  <UpgradesList
                    purchasableUpgrades={techUpgrades()}
                    tabsValue={tabsValue()}
                  />
                </TabsContent>
              </Match>
              <Match when={mainActiveTab() === "boosters"}>
                <BoostersTab />
              </Match>
            </Switch>
          </Suspense>
        </Show>
      </Tabs>
    </PageContainer>
  );
}
