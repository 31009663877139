import { isTMA, postEvent } from "@tma.js/sdk-solid";
import { WebApp } from "@/twa-sdk-updated/core";

let isTelegram = true;

isTMA().then((v) => (isTelegram = v));

export async function setTelegramCloseConfirm(needsConfirmation: boolean) {
  const isTelegramMiniAppEnvironment = await isTMA();

  if (!isTelegramMiniAppEnvironment) return false;

  postEvent("web_app_setup_closing_behavior", {
    need_confirmation: needsConfirmation,
  });
}

export function openLink(url: string) {
  if (!isTelegram) {
    window.open(url);
    return;
  }

  WebApp.openLink(url);

  // postEvent("web_app_open_link", {
  //   url,
  //   try_instant_view: true,
  // });
}

export function openTelegramLink(url: string) {
  if (!isTelegram) {
    window.open(url);
    return;
  }

  WebApp.openTelegramLink(url);
}
