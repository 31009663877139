import { Player } from "@/components/types.ts";
import { gameConfig } from "@/components/lib/game-config.ts";

export function calculateEnergy(energySnapshot: Player["energySnapshot"]) {
  const now = new Date().getTime();
  const elapsedTime = now - energySnapshot.updatedAt.getTime();
  const energyGained = Math.floor(
    (elapsedTime * gameConfig.energy.replenishRatePerSecond) / 1000,
  );

  return Math.min(100, energySnapshot.value + energyGained);
}
