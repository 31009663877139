import {
  createEffect,
  createSignal,
  Match,
  onCleanup,
  Show,
  Switch,
} from "solid-js";
import { Modal } from "@/components/ui/modal.tsx";
import {
  createMutation,
  createQuery,
  useQueryClient,
} from "@tanstack/solid-query";
import {
  CoinShopItemListing,
  CoinsShopService,
  InventoryService,
  ShopService,
} from "@/api-client";
import { Button } from "./ui/button";
import {
  coinIcon,
  energyBallBanner,
  energyDrinkBanner,
  groupRaffleTicketsImg,
  haloBeast,
  raffleTicketImg,
  rewardBg,
  starIcon,
} from "@/assets/images";
import { formatCompactNumber, isMobile } from "@/lib/utils";
import { BOOSTER_IMAGES } from "./BoostersTab";
import {
  playerStore,
  playerStoreSelector,
  refreshPlayerState,
  refreshPlayerStatusEffects,
  takeCoins,
} from "./player-store";
import { playSfxSound } from "./sounds";
import { captureEvent } from "@/lib/analytics";
import RainAnimation from "./RainAnimation";
import { WebApp } from "@/twa-sdk-updated/core";
import connection from "./connection";
import { useGame } from "./GameProvider";
import { initializeEnergyBall } from "./energy-ball";
import { setModalOpenState } from "./WelcomeMessages";
import { useNavigate } from "@solidjs/router";
import { Slider } from "./ui/slider";
import {
  NumberField,
  NumberFieldGroup,
  NumberFieldInput,
} from "./ui/number-field";

function PaySection(props: {
  boosterItem: CoinShopItemListing;
  animateSuccess: (quantity: number) => void;
  showEnergyBallAgain: () => void;
  accentColor: string;
}) {
  const starShopListingQuery = createQuery(() => ({
    queryKey: ["starShopListing"],
    queryFn: () => ShopService.getBoosterShopListings(),
  }));
  const boosterShopItem = () =>
    starShopListingQuery.data?.find(
      (listing) => listing.id === props.boosterItem.itemId,
    );
  const queryClient = useQueryClient();
  const buyBoosterMutation = createMutation(() => ({
    mutationFn: async () => {
      return await CoinsShopService.buyItem({
        requestBody: {
          itemId: props.boosterItem.itemId,
        },
      });
    },
    onSuccess: (result) => {
      refreshPlayerState();
      captureEvent("buy_booster_coins", {
        boosterId: props.boosterItem.itemId,
        boosterName: props.boosterItem.name,
      });
      props.animateSuccess(1);
      queryClient.invalidateQueries({
        queryKey: ["raffleTickets"],
      });
      queryClient.invalidateQueries({
        queryKey: ["coinShopStockListing"],
      });
      if (props.boosterItem.itemId === "energy-ball") {
        props.showEnergyBallAgain();
      }
    },
  }));
  const canBuy = () =>
    playerStoreSelector.coins() >= props.boosterItem.coinCost;
  const minQuantity = 1;
  const maxQuantity = 999;
  const maxSliderQuantity = 100;
  const [sliderQuantity, setSliderQuantity] = createSignal([minQuantity]);
  const [inputQuantity, setInputQuantity] = createSignal<number | string>(
    minQuantity,
  );
  const quantity = () => {
    const value = Number(inputQuantity());
    return isNaN(value) ? 0 : value;
  };
  // For each 3 raffle tickets in quantity, give 1 bonus raffle ticket
  const bonusRaffleTickets = () => Math.floor(quantity() / 3);
  const purchaseMutationWithStars = createMutation(() => ({
    mutationFn: () => {
      console.log("boosterShopItem", boosterShopItem());
      if (!boosterShopItem()?.id) {
        throw new Error("Booster shop item not found");
      }

      captureEvent("purchase_booster_item_intention", {
        itemId: boosterShopItem()!.id,
        itemName: boosterShopItem()!.name,
        itemCost: boosterShopItem()!.starsCost,
      });
      return ShopService.initiatePurchase({
        listingId: boosterShopItem()!.id,
        quantity: quantity(),
      });
    },
    onSuccess: (result) => {
      console.log("DATA", result);
      WebApp.openInvoice(result.invoiceLink, (cb) => {
        // This callbacks doesn't seem to work btw
        // Using the ItemPurchased event instead
        console.log("INVOICE CALLBACK", cb);
        queryClient.invalidateQueries({
          queryKey: ["raffleTickets"],
        });
        queryClient.invalidateQueries({
          queryKey: ["coinShopStockListing"],
        });
        queryClient.invalidateQueries({
          queryKey: ["starShopListing"],
        });
        refreshPlayerState();
        props.animateSuccess(quantity());
        playSfxSound("purchase");
        captureEvent("purchase_booster_item_success", {
          itemId: boosterShopItem()!.id,
          itemName: boosterShopItem()!.name,
          itemCost: boosterShopItem()!.starsCost,
        });
        if (props.boosterItem.itemId === "energy-ball") {
          props.showEnergyBallAgain();
        }
      });
    },
  }));

  createEffect(() => {
    if (connection()) {
      connection()?.on("ItemPurchased", (listingsId: string[]) => {
        if (!boosterShopItem()?.id) {
          console.error("Booster shop item not found");
        }

        if (listingsId.includes(boosterShopItem()!.id)) {
          queryClient.invalidateQueries({
            queryKey: ["raffleTickets"],
          });
          queryClient.invalidateQueries({
            queryKey: ["coinShopStockListing"],
          });
          queryClient.invalidateQueries({
            queryKey: ["starShopListing"],
          });
          refreshPlayerState();
          props.animateSuccess(quantity());
          playSfxSound("purchase");
          captureEvent("purchase_booster_item_success", {
            itemId: boosterShopItem()!.id,
            itemName: boosterShopItem()!.name,
            itemCost: boosterShopItem()!.starsCost,
          });
          if (props.boosterItem.itemId === "energy-ball") {
            props.showEnergyBallAgain();
          }
        }
      });
    }
  });

  const buttonText = () => {
    if (buyBoosterMutation.isPending) {
      return "Buying...";
    } else if (canBuy()) {
      return (
        <div class="flex items-center gap-2">
          Buy{" "}
          <div class="flex items-center justify-center gap-1">
            <img src={coinIcon} class="h-5 w-5 object-contain" />
            <span class="mt-1">
              {formatCompactNumber(props.boosterItem.coinCost)}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div class="flex items-center gap-2">
          Need{" "}
          <div class="flex items-center justify-center gap-1">
            <img src={coinIcon} class="h-5 w-5 object-contain" />
            <span class="mt-1">
              {formatCompactNumber(props.boosterItem.coinCost)}
            </span>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      class="flex flex-col bg-opacity-20"
      style={{
        background: props.accentColor,
      }}
    >
      <p class="text-center text-lg font-bold text-white">Quantity</p>
      <div class="flex items-center justify-center gap-4 px-4">
        <span class="font-bold text-white">{minQuantity}</span>
        <Slider
          class="w-full"
          minValue={minQuantity}
          maxValue={maxSliderQuantity}
          value={sliderQuantity()}
          onChange={(value) => {
            setSliderQuantity(value);
            setInputQuantity(value[0]);
          }}
          disabled={
            purchaseMutationWithStars.isPending || buyBoosterMutation.isPending
          }
          // disabled={true}
        />
        <span class="font-bold text-white">{maxSliderQuantity}</span>
      </div>
      <div class="mb-2 flex items-center justify-center">
        <NumberField
          minValue={0}
          maxValue={maxQuantity}
          // disabled={true}
          // readOnly={true}
          value={inputQuantity()}
          onChange={(value) => {
            if (!value) {
              if (!value || isNaN(Number(value))) {
                setInputQuantity("");
                return;
              }
            }
            const newValue = Math.min(
              maxQuantity,
              Math.max(0, parseInt(value)),
            );
            setInputQuantity(newValue);
            setSliderQuantity([
              Math.min(maxSliderQuantity, Math.max(minQuantity, newValue)),
            ]);
          }}
        >
          <NumberFieldGroup>
            <NumberFieldInput class="w-[50px] bg-white px-0 font-bold" />
          </NumberFieldGroup>
        </NumberField>
      </div>
      <div class="flex items-center justify-center gap-4 px-2 pb-4">
        <Button
          onClick={() => {
            // Deduct balance immediately
            takeCoins(props.boosterItem.coinCost);
            buyBoosterMutation.mutate();
          }}
          disabled={
            buyBoosterMutation.isPending ||
            !canBuy() ||
            !props.boosterItem.inStock ||
            quantity() > 1 ||
            quantity() === 0
          }
          size="sm"
          class="eazy-chat text-outline relative z-10 min-w-fit flex-1 border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
        >
          <div class="z-10 text-white">{buttonText()}</div>
        </Button>
        <Button
          onClick={() => {
            purchaseMutationWithStars.mutate();
          }}
          disabled={purchaseMutationWithStars.isPending || !Boolean(quantity())}
          size="sm"
          class="eazy-chat text-outline relative z-10 min-w-fit flex-1 border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
        >
          <div class="z-10">
            <Show when={boosterShopItem()}>
              <div class="mt-1 flex items-center justify-center gap-2 text-white">
                Buy{" "}
                <div class="flex items-center justify-center gap-1">
                  <img src={starIcon} class="-mt-1 h-5 w-5 object-contain" />
                  <span class="">
                    {formatCompactNumber(
                      Boolean(quantity())
                        ? (boosterShopItem()?.starsCost || 0) * quantity()
                        : boosterShopItem()?.starsCost || 0,
                    )}
                  </span>
                </div>
              </div>
            </Show>
          </div>
        </Button>
      </div>
      <Show when={props.boosterItem.itemId === "raffle-ticket"}>
        <div class="z-10 flex items-center justify-center">
          <div class="h-1 flex-1 bg-[#FFE600]" />
          <div class="flex items-center justify-center rounded-full bg-[#FFE600] px-4">
            <p
              class="text-center text-[16px] font-bold leading-tight text-white"
              style={{
                "text-shadow": "1px 1px 2px rgba(0, 0, 0, 0.70)",
              }}
            >
              NEW!
            </p>
          </div>
          <div class="h-1 flex-1 bg-[#FFE600]" />
        </div>
        <div class="-mt-2 flex items-center justify-between gap-2 bg-[#FDB24E] pl-2">
          <div class="py-1 pt-3">
            <p class="text-outline eazy-chat text-[14px] font-bold leading-tight text-white">
              Every 3 Raffle Tickets you buy with Stars get a FREE bonus Raffle
              Ticket!
            </p>
          </div>
          <div class="flex h-full flex-col items-center justify-center bg-black bg-opacity-50 p-1">
            <img src={raffleTicketImg} class="h-6 w-6 object-contain" />
            <span
              class="text-outline eazy-chat min-w-[40px] text-center font-bold text-white"
              style={{
                "-webkit-text-stroke-color": "#B04831",
              }}
            >
              +{bonusRaffleTickets()}
            </span>
          </div>
        </div>
      </Show>
    </div>
  );
}

function BuyRaffleTicketsModalContent(props: {
  closeModal: () => void;
  boosterItem: CoinShopItemListing;
  animateSuccess: (quantity: number) => void;
  showEnergyBallAgain: () => void;
}) {
  const navigate = useNavigate();

  return (
    <div class="relative flex flex-col justify-center rounded-xl rounded-b-xl bg-[#FFB3B3]">
      <div class="absolute -right-3 -top-3">
        <Button
          variant="ghost"
          class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
          onClick={props.closeModal}
        >
          <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
        </Button>
      </div>
      <div class="flex items-center justify-center rounded-t-xl bg-[#E68686] px-2 pt-1 text-center">
        <h3 class="eazy-chat text-outline text-2xl text-white">
          Buy Raffle Tickets
        </h3>
      </div>
      <div class="flex flex-col gap-2 bg-[#FFE7DF]">
        <div class="flex items-center justify-center">
          <div
            class="relative mx-1 my-2 flex h-[115px] w-[155px] items-center justify-center rounded-xl bg-[#E68686]"
            style={{
              "background-image": `url(${rewardBg})`,
              "background-size": "cover",
              "background-position": "center",
              "background-repeat": "no-repeat",
            }}
          >
            <img
              src={raffleTicketImg}
              class="h-[90px] w-[90px] object-contain"
            />
            <span class="eazy-chat text-outline absolute bottom-2 text-center text-2xl leading-tight text-white">
              x{1}
            </span>
          </div>
        </div>
        <div class="flex items-center justify-center px-6">
          <ul class="list-disc space-y-2 rounded-b-lg text-base leading-tight text-black">
            <li>You can buy up to x1 Raffle Ticket per day using Coins.</li>
            <li>
              Prizes include 2 Million $KURO per month, Silver Tickets,
              Boosters, rare Treasure Eggs and more!
            </li>
            <li>Redeem your Tickets in the Airdrop tab!</li>
          </ul>
        </div>
        <PaySection
          boosterItem={props.boosterItem}
          animateSuccess={props.animateSuccess}
          showEnergyBallAgain={props.showEnergyBallAgain}
          accentColor="#E68686"
        />
      </div>
      <Show when={isMobile() && playerStore.beast.reincarnations < 1}>
        <div class="flex flex-col gap-2 rounded-b-lg bg-[#FFB3B3] p-4">
          <div class="flex items-center justify-center gap-2">
            <img src={haloBeast} class="h-[50px] w-[50px] object-contain" />
            <p class="leading-tight">
              Reincarnate your Beast to be able to buy x2 Raffle Tickets per
              day!
            </p>
          </div>
          <div class="flex items-center justify-center">
            <Button
              onClick={() => {
                navigate("/play?reincarnationModal=true");
              }}
              size="sm"
              class="eazy-chat text-outline relative z-10 min-w-fit border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
            >
              <div class="z-10 text-white">Learn More</div>
            </Button>
          </div>
        </div>
      </Show>
    </div>
  );
}

function BuyEnergyBallModalContent(props: {
  closeModal: () => void;
  boosterItem: CoinShopItemListing;
  animateSuccess: (quantity: number) => void;
  showEnergyBallAgain: () => void;
}) {
  return (
    <div class="relative flex flex-col justify-center rounded-xl rounded-b-xl bg-[#FFB3B3]">
      <div class="absolute -right-3 -top-3">
        <Button
          variant="ghost"
          class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
          onClick={props.closeModal}
        >
          <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
        </Button>
      </div>
      <div class="flex items-center justify-center rounded-t-xl bg-[#9749B1] px-2 pt-1 text-center">
        <h3 class="eazy-chat text-outline text-2xl text-white">
          Buy Energy Ball
        </h3>
      </div>
      <div class="rounded-b-xl bg-[#FFE7DF]">
        <div class="relative mx-1 my-2">
          <img src={energyBallBanner} class="object-contain" />
        </div>
        <div>
          <p class="eazy-chat text-outline bg-[#9749B1] text-center text-base text-white">
            How it works
          </p>
        </div>
        <div class="rounded-b-xl bg-[#F2B5DB]">
          <div class="flex items-center justify-center px-6 py-4">
            <ul class="list-disc space-y-2 rounded-b-lg text-base leading-tight text-black">
              <li>
                Tap your Energy Ball and keep it airborne to build up a combo
                for increased damage!
              </li>
              <li>Gain +1 Energy for every 10 damage you deal!</li>
              <li>Deal 2000 damage to pop the ball and get +100 Energy!</li>
            </ul>
          </div>
          <PaySection
            boosterItem={props.boosterItem}
            animateSuccess={props.animateSuccess}
            showEnergyBallAgain={props.showEnergyBallAgain}
            accentColor="#9749B1"
          />
        </div>
      </div>
    </div>
  );
}

function BuyEnergyDrinkModalContent(props: {
  closeModal: () => void;
  boosterItem: CoinShopItemListing;
  animateSuccess: (quantity: number) => void;
  showEnergyBallAgain: () => void;
}) {
  return (
    <div class="relative flex flex-col justify-center rounded-xl rounded-b-xl bg-[#DBEFB2]">
      <div class="absolute -right-3 -top-3">
        <Button
          variant="ghost"
          class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
          onClick={props.closeModal}
        >
          <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
        </Button>
      </div>
      <div class="flex items-center justify-center rounded-t-xl bg-[#A2D632] px-2 pt-1 text-center">
        <h3 class="eazy-chat text-outline text-2xl text-white">
          Buy Energy Drink
        </h3>
      </div>
      <div class="rounded-b-xl bg-[#DBEFB2]">
        <div class="relative mx-1 my-2">
          <img src={energyDrinkBanner} class="object-contain" />
        </div>
        <div>
          <p class="eazy-chat text-outline bg-[#A2D632] text-center text-base text-white">
            How it works
          </p>
        </div>
        <div class="rounded-b-xl bg-[#C7E38B]">
          <div class="flex items-center justify-center px-6 py-4">
            <ul class="list-disc space-y-2 rounded-b-lg text-base leading-tight text-black">
              <li>For 15 seconds you’ll have Unlimited Energy!</li>
              <li>
                Mine as many as Crystal Shards as you can before this hyper
                caffeinated beverage wears off!
              </li>
            </ul>
          </div>
          <PaySection
            boosterItem={props.boosterItem}
            animateSuccess={props.animateSuccess}
            showEnergyBallAgain={props.showEnergyBallAgain}
            accentColor="#A2D632"
          />
        </div>
      </div>
    </div>
  );
}

export function BuyBoosterItemModal(props: {
  isModalOpen: () => boolean;
  setIsModalOpen: (value: boolean) => void;
  closeModal: () => void;
  boosterItem: CoinShopItemListing;
}) {
  const [showSuccessAnimation, setShowSuccessAnimation] = createSignal(false);
  const [successQuantity, setSuccessQuantity] = createSignal(1);
  let timer: any;
  const animateSuccess = (quantity: number) => {
    const shouldClose = props.boosterItem.itemId !== "energy-drink";

    playSfxSound("purchase");
    setShowSuccessAnimation(true);
    setSuccessQuantity(quantity);
    timer = setTimeout(() => {
      if (shouldClose) {
        setShowSuccessAnimation(false);
        props.closeModal();
      }
    }, 4000);
  };
  const navigate = useNavigate();
  const game = useGame();
  const useItemMutation = createMutation(() => ({
    mutationFn: () => {
      return InventoryService.useItem({
        requestBody: {
          itemId: props.boosterItem.itemId,
        },
      });
    },
    onSuccess: async (result) => {
      captureEvent("item_redeemed", {
        item: props.boosterItem.itemId,
      });
      await refreshPlayerStatusEffects();
      setShowSuccessAnimation(false);
      props.closeModal();
      navigate("/play");
    },
  }));

  const showEnergyBallAgain = async () => {
    if (game()) {
      console.log("here");
      await initializeEnergyBall(game()!, true);
    }
  };

  onCleanup(() => {
    clearTimeout(timer);
  });

  return (
    <Modal
      open={props.isModalOpen()}
      onOpenChange={props.setIsModalOpen}
      onPointerDownOutside={props.closeModal}
      showRewardsOverlay={showSuccessAnimation()}
      hideBlur={showSuccessAnimation()}
      // alignItems="items-start"
    >
      <Switch>
        <Match when={showSuccessAnimation()}>
          <div class="relative flex h-full w-full flex-col items-center justify-center gap-4">
            <RainAnimation
              image={
                BOOSTER_IMAGES[
                  props.boosterItem.itemId as keyof typeof BOOSTER_IMAGES
                ]
              }
              count={50}
              duration={2}
            />
            <p class="eazy-chat text-outline -mt-[200px] text-center text-4xl text-[#FBFF23]">
              x{successQuantity()} {props.boosterItem.name} Purchased!
            </p>
            <img
              src={
                BOOSTER_IMAGES[
                  props.boosterItem.itemId as keyof typeof BOOSTER_IMAGES
                ]
              }
              style={{ "image-rendering": "pixelated" }}
              class="h-[100px] w-[100px] object-contain"
            />
            <Show when={props.boosterItem.itemId === "energy-drink"}>
              <div class="flex items-center justify-center gap-2">
                <Button
                  onClick={() => {
                    useItemMutation.mutate();
                  }}
                  disabled={useItemMutation.isPending}
                  size="sm"
                  class="eazy-chat text-outline relative z-10 min-w-fit border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
                >
                  <div class="z-10 text-white">Use</div>
                </Button>
                <Button
                  onClick={() => {
                    navigate("/play/airdrop?isRewardsViewOpen=true");
                  }}
                  size="sm"
                  class="eazy-chat text-outline relative z-10 min-w-fit border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
                >
                  <div class="z-10 text-white">Save for later</div>
                </Button>
              </div>
            </Show>
          </div>
        </Match>
        <Match when={props.boosterItem.itemId === "raffle-ticket"}>
          <BuyRaffleTicketsModalContent
            closeModal={props.closeModal}
            boosterItem={props.boosterItem}
            animateSuccess={animateSuccess}
            showEnergyBallAgain={showEnergyBallAgain}
          />
        </Match>
        <Match when={props.boosterItem.itemId === "energy-ball"}>
          <BuyEnergyBallModalContent
            closeModal={props.closeModal}
            boosterItem={props.boosterItem}
            animateSuccess={animateSuccess}
            showEnergyBallAgain={showEnergyBallAgain}
          />
        </Match>
        <Match when={props.boosterItem.itemId === "energy-drink"}>
          <BuyEnergyDrinkModalContent
            closeModal={props.closeModal}
            boosterItem={props.boosterItem}
            animateSuccess={animateSuccess}
            showEnergyBallAgain={showEnergyBallAgain}
          />
        </Match>
      </Switch>
    </Modal>
  );
}
