import { PageContainer } from "@/components/PageContainer";
import { createEffect, createSignal, For, Show } from "solid-js";
import { Card, CardContent } from "@/components/ui/card.tsx";
import { Button } from "@/components/ui/button.tsx";
import { createQuery } from "@tanstack/solid-query";
import { Suspense } from "solid-js/web";
import { initUtils } from "@tma.js/sdk-solid";
import {
  friendsRewardBg,
  giftImg,
  giftPinkImg,
  reloadImg,
  sadFacePink,
  shardGroup,
} from "@/assets/images";
import { useIsRouting, useNavigate } from "@solidjs/router";
import { cn } from "@/lib/utils";
import { Skeleton } from "@/components/ui/skeleton";
import { CheckIcon, CopyIcon } from "@/assets/extra-icons";
import { CloseButton } from "@/components/CloseButton";
import { showToast } from "@/components/ui/toast";
import {
  Pagination,
  PaginationEllipsis,
  PaginationItem,
  PaginationItems,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { captureEvent } from "@/lib/analytics";
import { LuckyReferralModal } from "@/components/LuckyReferralModal";
import { playerStore, refreshPlayerState } from "@/components/player-store";
import { eventBus } from "@/components/event-bus";
import { IS_RANCH_PROD } from "@/config.ts";
import { InventoryService, ReferralsService } from "@/api-client";

export const botName = IS_RANCH_PROD ? "KuroroRanchBot" : "KuroroRanchTestBot";
const REFERRAL_MESSAGE = `\n🐾 Kuroro Beast Ranch: Collect adorable Beasts, earn Coins, and build your dream Ranch!\n\n💰 More upgrades = More airdrop rewards! 🚀`;

function CardComponent(props: {
  image: string;
  title: string;
  description: string;
  bgColor: string;
  bgColorDescription: string;
  textColor: string;
}) {
  return (
    <Card
      class={cn(
        "flex flex-1 flex-col justify-between border border-[#944242] bg-[#E68686]",
        props.bgColor,
      )}
      style={{
        "background-image": `url(${friendsRewardBg})`,
        "background-size": "contain",
        "background-repeat": "no-repeat",
        "background-position": "top",
      }}
    >
      <CardContent class="relative flex flex-1 items-center p-4">
        <div class="flex h-full flex-shrink-0 items-center justify-center px-2">
          <img src={props.image} class="h-12 w-12 object-contain" />
        </div>
        <span class="eazy-chat text-outline flex-1 text-[17px] leading-4 text-white">
          {props.title}
        </span>
      </CardContent>
      <div
        class={cn(
          "rounded-b-lg bg-[#F4B9B3] px-4 py-2 pl-6",
          props.bgColorDescription,
        )}
      >
        <p
          class={cn(
            "eazy-chat text-xs leading-4 text-[#804242]",
            props.textColor,
          )}
        >
          {props.description}
        </p>
      </div>
    </Card>
  );
}

function PaginationButtons(props: {
  page: number;
  setPage: (page: number) => void;
  pages: () => number;
}) {
  return (
    <div class="flex">
      <Pagination
        count={props.pages()}
        itemComponent={(itemProps) => (
          <PaginationItem
            page={itemProps.page}
            size="icon"
            class={cn(
              "h-9 w-9 bg-[#FFD0C5] font-bold text-[#7D3737]",
              props.page === itemProps.page &&
                "aria-[current=page]:border-none aria-[current=page]:bg-[#7D3737] aria-[current=page]:text-white aria-[current=page]:shadow-none aria-[current=page]:hover:bg-transparent",
            )}
          >
            {itemProps.page}
          </PaginationItem>
        )}
        page={props.page}
        onPageChange={props.setPage}
        ellipsisComponent={() => <PaginationEllipsis />}
      >
        <PaginationPrevious class="text-[#7D3737]" />
        <PaginationItems />
        <PaginationNext class="text-[#7D3737]" />
      </Pagination>
    </div>
  );
}

export default function FriendsRoute() {
  const navigate = useNavigate();
  const [page, setPage] = createSignal(1);
  const [luckyExplanationModalOpen, setLuckyExplanationModalOpen] =
    createSignal(false);
  const referralsStateQuery = createQuery(() => ({
    queryKey: ["referralsState"],
    queryFn: () => ReferralsService.getReferralsState(),
  }));
  const pages = () =>
    referralsStateQuery.data?.totalReferrals
      ? Math.ceil(referralsStateQuery.data?.totalReferrals / 10)
      : 1;
  const refereesQuery = createQuery(() => ({
    queryKey: ["referees", page()],
    queryFn: () =>
      ReferralsService.getReferees({
        page: page(),
      }),
  }));

  const referralUrl = () =>
    `https://t.me/${botName}/ranch?startapp=ref-${referralsStateQuery.data?.referralCode}`;

  const shareReferralUrl = () => {
    captureEvent("share_referral_url");
    initUtils().openTelegramLink(
      `https://t.me/share/url?url=${referralUrl()}&text=${encodeURIComponent(REFERRAL_MESSAGE)}`,
    );
  };

  const isRouting = useIsRouting();
  const [copySuccess, setCopySuccess] = createSignal(false);
  const copyReferralUrl = () => {
    captureEvent("copy_referral_url");
    navigator.clipboard.writeText(referralUrl());
    setCopySuccess(true);
    showToast({
      title: "Invite Link Copied!",
      description: "",
    });
    setTimeout(() => setCopySuccess(false), 2000);
  };

  const handleClaimLucky = () => {
    InventoryService.useItem({
      requestBody: {
        itemId: "lucky-serum",
      },
    }).then(() => {
      captureEvent("lucky_claimed");
      refreshPlayerState();
      referralsStateQuery.refetch();
      refereesQuery.refetch();
      navigate("/play");
      eventBus.emit("luckySerumClaimed");
    });
  };

  createEffect(() => {
    console.log("referalState", referralsStateQuery.data);
  });

  return (
    <PageContainer hideShadowInnerBottom>
      <div class="relative flex h-full w-full flex-col">
        <div class="sticky top-0 z-10 flex flex-col items-center justify-center bg-[#CD6868] px-3 pb-1 pt-4 text-center">
          <CloseButton />
          <h2 class="eazy-chat text-outline-1 text-4xl font-bold text-white">
            Invite friends!
          </h2>
          <p class="eazy-chat text-outline text-sm leading-4 text-white">
            You and your friends will both receive bonuses!
          </p>
        </div>
        <div class="flex flex-col gap-2 overflow-y-auto pb-1">
          <div class="bg-[#CD6868] p-3">
            <div class="flex gap-2">
              <CardComponent
                image={giftPinkImg}
                title="Invite a friend"
                description="+5k Coins for you and your friend"
                bgColor="bg-[#E68686]"
                bgColorDescription="bg-[#F4B9B3]"
                textColor="text-[#804242]"
              />
              <CardComponent
                image={shardGroup}
                title="Friend gets to Lvl 10"
                description="+200 Shards for you and your friend"
                bgColor="bg-[#7D57B9]"
                bgColorDescription="bg-[#C8A5C9]"
                textColor="text-[#6941A9]"
              />
            </div>
            {referralsStateQuery.data?.luckySerumGained ||
            playerStore.beast.isLucky ? (
              <div class="relative mt-4 w-full rounded-lg bg-[#EBAD58] px-2 py-1">
                <div class="absolute right-2 top-1 h-5 w-5 rounded-full bg-red-500 p-1">
                  <p
                    class="eazy-chat text-center text-[10px] text-white"
                    onClick={() => {
                      setLuckyExplanationModalOpen(true);
                    }}
                  >
                    ?
                  </p>
                </div>
                <p class="eazy-chat text-outline mt-1 text-center text-[20px] text-white">
                  You got a <span class="text-[#FFD532]">Lucky</span> Beast!
                </p>
                <div class="flex items-center justify-center">
                  <div>
                    {playerStore.beast.isLucky ? (
                      <div class="flex items-center justify-center">
                        <CheckIcon color="white" />
                        <p class="eazy-chat text-outline mt-1 text-center text-white">
                          {" "}
                          Claimed
                        </p>
                      </div>
                    ) : (
                      <Button
                        size="sm"
                        class="eazy-chat min-w-fit flex-1 border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
                        onClick={handleClaimLucky}
                      >
                        <span
                          class="text-outline mt-1 text-2xl text-white"
                          style={{
                            "-webkit-text-stroke-color": "#B04831",
                          }}
                        >
                          Claim
                        </span>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                class="mt-4 w-full rounded-lg bg-[#FFB4AA] px-2 py-4"
                onClick={() => {
                  setLuckyExplanationModalOpen(true);
                }}
              >
                <div class="flex items-center justify-center">
                  <For each={Array.from({ length: 10 }, (_, i) => i)}>
                    {(i) => (
                      <div class="flex items-center justify-center">
                        <Show when={i !== 0}>
                          <div
                            class={cn(
                              "h-1 w-4 bg-white",
                              i <
                                (referralsStateQuery.data?.totalReferrals ||
                                  0) && "bg-[#71B450]",
                            )}
                          />
                        </Show>
                        <div
                          class={cn(
                            "h-3 w-3 rounded-full bg-white",
                            i <
                              (referralsStateQuery.data?.totalReferrals || 0) &&
                              "bg-[#71B450]",
                          )}
                        />
                      </div>
                    )}
                  </For>
                </div>
                <p class="eazy-chat text-outline mt-1 text-center text-white">
                  {Math.min(referralsStateQuery.data?.totalReferrals ?? 0, 10)}
                  /10
                </p>
                <div class="flex items-center justify-center gap-2">
                  <p class="eazy-chat mt-1 text-xs text-[#804242]">
                    Get 10 referrals for a guaranteed{" "}
                    <span class="text-outline text-[#FFD321]">Lucky</span>{" "}
                    Beast!
                  </p>
                  <div class="h-5 w-5 rounded-full bg-red-500 p-1">
                    <p class="eazy-chat text-center text-[10px] text-white">
                      ?
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div class="mt-2 px-3">
            <div class="mb-1 flex items-center justify-between">
              <h3 class="eazy-chat text-outline text-xl text-white">
                My friends
              </h3>
              <Button
                variant="ghost"
                class="hover:bg-transparent hover:opacity-65"
                onClick={() => {
                  referralsStateQuery.refetch();
                  refereesQuery.refetch();
                  refreshPlayerState();
                  captureEvent("friends_reload", {
                    page: page(),
                  });
                }}
              >
                <img src={reloadImg} class="h-[30px] w-[30px]" />
              </Button>
            </div>
            <div class="flex flex-col gap-1">
              <Suspense
                fallback={
                  <Skeleton
                    class="rounded-lg border border-[#E78882] bg-[#F6B9B0]"
                    height={112}
                  />
                }
              >
                <For
                  each={refereesQuery.data}
                  fallback={
                    <div class="flex flex-col items-center justify-center rounded-lg bg-[#FFD0C5] p-4 text-center">
                      <p class="eazy-chat text-[#804242]">
                        You haven’t invited anyone yet!
                      </p>
                      <img src={sadFacePink} class="h-14 w-14 object-contain" />
                    </div>
                  }
                >
                  {(friend) => (
                    <div
                      class={cn(
                        "flex items-center gap-1 rounded-lg bg-[#FFD0C5] p-2",
                        friend.level >= 10 && "bg-[#FCB74F]",
                      )}
                    >
                      <div class="flex min-w-[100px] items-center justify-between">
                        <span
                          class={cn(
                            "eazy-chat mt-1 truncate text-xs tracking-widest text-[#7D3737]",
                            friend.level >= 10 && "text-white",
                          )}
                        >
                          {friend.username}
                        </span>
                      </div>
                      <div class="flex min-w-[80px] items-center gap-2">
                        <img
                          src={friend.level >= 10 ? giftImg : giftPinkImg}
                          class="h-4 w-4 object-contain"
                        />
                        <span
                          class={cn(
                            "eazy-chat mt-1 text-xs tracking-widest text-[#7D3737]",
                            friend.level >= 10 && "text-white",
                          )}
                        >
                          Lvl {friend.level}
                        </span>
                      </div>
                      <div class="h-4 w-full rounded bg-[#441717]">
                        <div
                          class={cn(
                            "h-full rounded bg-[#74CD3E]",
                            friend.level >= 10 && "bg-[#F3933B]",
                          )}
                          style={{
                            width: `${Math.min(
                              (friend.level / 10) * 100,
                              100,
                            )}%`,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </For>
              </Suspense>
              <Show when={(refereesQuery.data || []).length > 0 && pages() > 1}>
                <PaginationButtons
                  page={page()}
                  setPage={setPage}
                  pages={pages}
                />
              </Show>
            </div>
          </div>
        </div>
        <div class="sticky bottom-2 z-10 px-3 py-2">
          <div class="flex gap-2">
            <Button
              size="lg"
              class="eazy-chat min-w-fit flex-1 border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
              onClick={shareReferralUrl}
            >
              <span
                class="text-outline mt-1 text-2xl text-white"
                style={{
                  "-webkit-text-stroke-color": "#B04831",
                }}
              >
                Invite Friend
              </span>
            </Button>
            <Button
              onClick={copyReferralUrl}
              size="lg"
              variant="outline"
              class="eazy-chat w-auto border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] p-4 hover:opacity-70"
            >
              {copySuccess() ? (
                <CheckIcon
                  width={24}
                  height={24}
                  color="white"
                  stroke-width="3"
                />
              ) : (
                <CopyIcon
                  width={24}
                  height={24}
                  color="white"
                  stroke-width="3"
                />
              )}
            </Button>
          </div>
        </div>
      </div>
      <LuckyReferralModal
        isModalOpen={luckyExplanationModalOpen}
        setIsModalOpen={setLuckyExplanationModalOpen}
        closeModal={() => setLuckyExplanationModalOpen(false)}
      />
    </PageContainer>
  );
}
