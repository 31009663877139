import { Actor, Animation, range, SpriteSheet, vec } from "excalibur";

import { Resources } from "@/game/resources";
import { Config } from "@/game/config";

export class Energy extends Actor {
  constructor(x: number, y: number, isLucky: boolean = false) {
    super({ x: x, y: y });
  }

  public onInitialize(): void {
    this.name = 'energy';
    let scale = Config.scale;
    this.scale = vec(scale, scale);
    this.anchor = vec(0.5, 1);

    const spritesheet = SpriteSheet.fromImageSource({
      image: Resources.EnergyParticle,
      grid: {
        rows: 1,
        columns: 8,
        spriteWidth: 16,
        spriteHeight: 16,
      },
    });
  
    let anim = Animation.fromSpriteSheet(spritesheet, range(0, 7), 100);
    this.graphics.use(anim!);
  }
}