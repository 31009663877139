import {
  Engine,
  Input,
  DisplayMode,
  ImageFiltering,
  SolverStrategy,
  vec,
  FadeInOut,
  Color,
} from "excalibur";

import { loader } from "./resources";
import { Onboarding } from "./scenes/onboarding.ts";
import { OnboardingRanch } from "./scenes/onboarding-ranch.ts";
import { Ranch } from "@/game/scenes/ranch.ts";
import { Match } from "@/game/scenes/match/match.ts";
import { LevelSelection } from "./scenes/match/level-selection.ts";
import { RaffleMachine } from "./scenes/raffle-machine.ts";

export class Game extends Engine {
  constructor() {
    super({
      pixelArt: true,
      canvasElementId: "game",
      pointerScope: Input.PointerScope.Canvas,
      displayMode: DisplayMode.FillContainer,
      suppressPlayButton: true,
      // backgroundColor: Color.Yellow,
      suppressHiDPIScaling: true,
      antialiasing: {
        pixelArtSampler: true, // turns on the sub-pixel shader for pixel art
        nativeContextAntialiasing: false, // turns off canvas aa
        multiSampleAntialiasing: true, // turns on msaa which smooths quad boundaries
        filtering: ImageFiltering.Pixel, // hints the image loader to use blended filtering
        canvasImageRendering: "auto", // applies the 'auto'-matic css to the canvas CSS image-rendering
      },
      maxFps: 30,
      fixedUpdateFps: 30,
      physics: {
        solver: SolverStrategy.Realistic,
        gravity: vec(0, 500),
      },
      scenes: {
        ranch: {
          scene: Ranch,
          transitions: {
            in: new FadeInOut({duration: 500, direction: 'in', color: Color.Black}),
            out: new FadeInOut({duration: 500, direction: 'out', color: Color.Black})
          }
        },
        raffleMachine: {
          scene: RaffleMachine,
          transitions: {
            in: new FadeInOut({duration: 500, direction: 'in', color: Color.Black}),
            out: new FadeInOut({duration: 500, direction: 'out', color: Color.Black})
          }
        }
      }
    });
  }

  public async initializeOnboarding() {
    this.add("onboarding", new Onboarding());
    this.add("onboarding-ranch", new OnboardingRanch());

    await this.start(loader);
    await this.goToScene("onboarding");
  }

  public initialize(specieStageId: number) {
    console.log("ADDING SCENE!!!!!!!!!!!!");
    console.log("SCENE ADDED");

    const levelSelectionScene = new LevelSelection();
    this.add("levelSelection", levelSelectionScene);
    this.add("match", new Match());

    console.log("Trying to start game.");
    return this.start(loader).then(() => {
      return this.goToScene("ranch", {
        sceneActivationData: {
          specieStageId: specieStageId,
        }
      });
    });
  }
}
