import { cn } from "@/lib/utils";
import { PolymorphicProps } from "@kobalte/core/polymorphic";
import {
  Slider as SliderPrimitive,
  SliderRootProps,
} from "@kobalte/core/slider";
import { splitProps, ValidComponent } from "solid-js";

export function Slider(
  props: PolymorphicProps<ValidComponent, SliderRootProps>,
) {
  const [local, others] = splitProps(props, ["class"]);
  return (
    <SliderPrimitive
      class={cn(
        "relative flex w-[200px] touch-none select-none flex-col items-center",
        local.class,
      )}
      {...others}
    >
      <div class="flex w-full justify-between">
        {/* <SliderPrimitive.Label>Label</SliderPrimitive.Label> */}
        {/* <SliderPrimitive.ValueLabel /> */}
      </div>
      <SliderPrimitive.Track class="relative h-2 w-full rounded-full bg-gray-300">
        <SliderPrimitive.Fill class="absolute h-full rounded-full bg-[#9E4A37]" />
        <SliderPrimitive.Thumb class="-top-[5px] block h-5 w-5 rounded-full border-2 border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] hover:shadow-[0_0_0_5px_#b0483161] focus:shadow-[0_0_0_5px_#b0483161] focus:outline-none">
          <SliderPrimitive.Input />
        </SliderPrimitive.Thumb>
      </SliderPrimitive.Track>
    </SliderPrimitive>
  );
}
