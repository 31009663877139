import { Modal, ModalDescription } from "./ui/modal";
import { Button } from "./ui/button";
import { Suspense } from "solid-js";
import { BEASTS } from "@/lib/beasts-metadata";
import { playerStore } from "./player-store";

function LuckyContent(props: { closeModal: () => void }) {
  const beastMetadata = BEASTS.find(
    (b) => b.beastId === playerStore.beast.specieStageId,
  );

  return (
    <div class="relative flex flex-col justify-center rounded-xl bg-[#FFE7DF]">
      <div class="absolute -right-3 -top-3">
        <Button
          variant="ghost"
          class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
          onClick={props.closeModal}
        >
          <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
        </Button>
      </div>
      <div class="flex items-center justify-center rounded-t-xl bg-[#476EA9] px-2 py-3 text-center">
        <h3 class="eazy-chat text-outline text-2xl text-white">LUCKY BEASTS</h3>
      </div>
      <div class="bg-[##FFE7DF] p-1">
        <div class="my-2 flex items-center justify-center gap-4">
          <div>
            <p class="eazy-chat text-outline text-center text-[14px] text-white">
              Standard
            </p>
            <img
              src={beastMetadata?.beastImage}
              class="h-[80px] w-[80px] object-contain"
            />
          </div>
          <div>
            <p class="eazy-chat text-outline text-center text-[14px] text-[#FFD532]">
              Lucky
            </p>
            <img
              src={beastMetadata?.beastImageLucky}
              class="h-[80px] w-[80px] object-contain"
            />
          </div>
        </div>
        <ul class="list-disc rounded-lg bg-[#476EA9] px-4 py-2 pl-5 text-left text-sm tracking-wider text-white">
          <li>Lucky Beasts increase your overall Luck in Kuroro Ranch!</li>
          <li>
            Lucky Beasts have a 20% chance to get a Bonus Raffle Ticket when
            they level up! (<span class="font-bold">3</span> per day max)
          </li>
          <li>
            Owners of Lucky Beasts have a chance to get bonus crystals when
            mining!
          </li>
        </ul>
      </div>
    </div>
  );
}

export function LuckyReferralModal(props: {
  isModalOpen: () => boolean;
  setIsModalOpen: (value: boolean) => void;
  closeModal: () => void;
}) {
  return (
    <Modal open={props.isModalOpen()} onOpenChange={props.setIsModalOpen}>
      <Suspense fallback={<div>Loading...</div>}>
        <LuckyContent closeModal={props.closeModal} />
      </Suspense>
    </Modal>
  );
}
