import { Button } from "@/components/ui/button.tsx";
import "./Views.css";
import { sadFacePink, starterTrio } from "@/assets/images";
import { captureException } from "@sentry/solid";
import { createSignal, Show } from "solid-js";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerLabel,
  DrawerTrigger,
} from "@/components/ui/drawer.tsx";
import { showToast } from "@/components/ui/toast.tsx";
import { ExternalLinkIcon } from "@/assets/extra-icons.tsx";
import { getUserId } from "@/lib/analytics";

export function ErrorFallback(props: { error: Error }) {
  const userId = getUserId();
  const eventId = captureException(props.error);
  const errorTemplate = {
    userId: `tg-${userId}`,
    eventId,
    message: props.error.message,
    // stack: props.error.stack,
  };
  const errorString = "```" + JSON.stringify(errorTemplate, null, 2) + "```";

  return (
    <div
      class="relative z-[999999] mx-auto flex h-[100vh] max-w-xl flex-col items-center justify-center gap-6 bg-[#FFE7DF] bg-contain bg-center bg-no-repeat"
      style={{
        "background-image": `url(${starterTrio})`,
      }}
    >
      <h1 class="eazy-chat text-outline text-center text-4xl text-white">
        There was an error
      </h1>
      <img src={sadFacePink} class="h-24 w-24 object-contain" />
      <div class="flex flex-col gap-2">
        <Button
          size="lg"
          class="eazy-chat min-w-fit flex-1 border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
          onClick={() => (location.href = "/")}
        >
          <span
            class="text-outline mt-1 text-2xl font-bold text-white"
            style={{
              "-webkit-text-stroke-color": "#B04831",
            }}
          >
            Reload
          </span>
        </Button>
        <Drawer>
          <DrawerTrigger>
            <Button
              size="lg"
              class="eazy-chat min-w-fit flex-1 border text-lg leading-4 hover:opacity-70"
            >
              <span
                class="text-outline mt-1 text-2xl font-bold text-white"
                style={{
                  "-webkit-text-stroke-color": "#B04831",
                }}
              >
                Copy Error ID
              </span>
            </Button>
          </DrawerTrigger>
          <DrawerContent class="z-[9999999]">
            <DrawerHeader>
              <DrawerLabel>Share this ID in a discord ticket</DrawerLabel>
              <DrawerDescription></DrawerDescription>
            </DrawerHeader>
            <div class="text-center">
              This will help our devs to troubleshoot the issue.
            </div>
            <DrawerFooter class="flex flex-col gap-2">
              <Button
                onClick={() => {
                  navigator.clipboard
                    .writeText(errorString)
                    .then(() => {
                      showToast({
                        title: "Copied to clipboard ✅",
                      });
                    })
                    .catch(() => {
                      showToast({
                        title: "Failed to copy to clipboard",
                        variant: "error",
                      });
                    });
                }}
              >
                Copy Error ID
              </Button>
              <Button
                as={"a"}
                variant="outline"
                class="flex justify-center gap-2 border-2"
                href="https://discord.com/channels/912516607589363752/926821445793890304"
                target="_blank"
              >
                Open Ticket in Discord <ExternalLinkIcon />
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </div>
    </div>
  );
}
