import * as ex from "excalibur";
import { eventBus } from "@/components/event-bus";
import { vibrate } from "@/lib/utils";
import { BeastResources } from "@/game/resources";
import { Config } from "@/game/config";
import { Corral } from "@/game/actors/match/corral";
import { BeastAnims } from "@/game/scenes/match/match";

export class Beast extends ex.Actor {
  public identifier: string = "001";
  public isDragging: boolean = false;

  private matched: boolean = false;
  private startPosition: ex.Vector;
  private targetPosition: ex.Vector = ex.Vector.Zero;
  private idleTime: number = 0;
  private idleDuration: number = 0;
  private isMoving: boolean = false;
  private speed: number = 15;
  private inCorral: boolean = false;
  static random: ex.Random;

  constructor(x: number, y: number, color: ex.Color, speed: number) {
    super({
      x,
      y,
      width: 40,
      height: 40,
      color
    });
    this.speed = speed;
    this.startPosition = new ex.Vector(x, y);
    Beast.random = new ex.Random();

    this.on("pointerup", (event) => {
      event.cancel();
      if (!this.matched && !this.inCorral) {
        vibrate("light");
        eventBus.emit("beastClicked", this);
      }
    });
  }

  onInitialize(engine: ex.Engine) {
    this.graphics.use(this.getBeastAnim());
    this.setNewTarget(false);
  }

  private getBeastAnim(): ex.Animation {
    let animName = `${this.identifier}-idle`;
    const anim: ex.Animation = BeastAnims[animName];
    anim.scale = new ex.Vector(Config.scale / 2, Config.scale / 2);
    return anim;
  }

  setMatched() {
    this.matched = true;
    this.actions.fade(0, 500).callMethod(() => {
      this.graphics.hide();
      this.kill();
    });
  }

  isMatched(): boolean {
    return this.matched;
  }

  setInCorral(value: boolean) {
    this.inCorral = value;
  }

  isInCorral(): boolean {
    return this.inCorral;
  }

  moveToCorral(corral: Corral) {
    if (!this.inCorral) {
      let bounds = corral.graphics.bounds;
      let randomX = Beast.random.integer(bounds.left + 20, bounds.right - 20);
      let randomY = Beast.random.integer(bounds.top + 20, bounds.bottom - 20);
      this.setInCorral(true);
      this.actions.moveTo(ex.vec(randomX, randomY), 1000);
    }
  }

  resetPosition() {
    if (!this.inCorral) {
      this.actions.moveTo(this.startPosition.x, this.startPosition.y, 1000);
    }
  }

  private easeInOutQuad(t: number): number {
    return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;
  }

  private setNewTarget(allowIdle: boolean = true) {
    const padding = 50; // Keep away from edges
    const x = Math.random() * (this.scene!.engine.drawWidth - 2 * padding) + padding;
    const y = Math.random() * (this.scene!.engine.drawHeight - 100 - 2 * padding) + padding;
    this.targetPosition = new ex.Vector(x, y);
    if (allowIdle) {
      this.idleTime = 0;
      this.idleDuration = Math.random() * 4;
    }
    this.isMoving = false;
  }

  onPreUpdate(_engine: ex.Engine, delta: number) {
    if (!this.inCorral) {
      if (this.isMoving) {
        const direction = this.targetPosition.sub(this.pos);
        const distance = direction.distance();

        if (distance > this.speed * delta / 1000) {
          const movement = direction.normalize().scale(this.speed * delta / 1000);
          this.pos = this.pos.add(movement);
        } else {
          this.pos = this.targetPosition;
          this.isMoving = false;
          this.idleTime = 0;
        }
      } else {
        this.idleTime += delta / 1000;
        if (this.idleTime >= this.idleDuration) {
          this.setNewTarget();
          this.isMoving = true;
        }
      }
    }
  }

  update(engine: ex.Engine, delta: number) {
    super.update(engine, delta);
    this.z = this.pos.y;
  }
}
