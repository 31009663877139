import { createEffect, createResource, onCleanup } from "solid-js";
import { makeConnection } from "../lib/signalr.ts";
import { getAuthToken } from "@/components/lib/auth.ts";
import { signalrUrl } from "@/config.ts";

const [connection] = createResource(async () => {
  const connection = makeConnection(signalrUrl, getAuthToken());

  // Wait 20 seconds
  await new Promise((resolve) => setTimeout(resolve, 5000));
  await connection.start();
  return connection;
});

export const useConnectionEvent = (
  eventName: string,
  callback: (...args: any) => void,
) => {
  createEffect(() => {
    connection()?.on(eventName, callback);

    onCleanup(() => connection()?.off(eventName, callback));
  });
};

export default connection;
