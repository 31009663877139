import { Modal, ModalDescription } from "./ui/modal";
import { Button } from "./ui/button";
import { eventBus } from "@/components/event-bus.ts";
import { coinHD } from "@/assets/images";
import { formatCompactNumber } from "@/lib/utils";
import { setPlayerStore } from "./player-store";
import { GameService } from "@/api-client";
import { createQuery } from "@tanstack/solid-query";

export function createCoinsChangeQuery() {
  return createQuery(() => ({
    queryKey: ["coinsEarnedAway"],
    queryFn: GameService.coinsEarnedAway,
  }));
}

export function CoinsAwayModal(props: {
  coinsChange: number;
  closeModal: () => void;
}) {
  function handleClose() {
    GameService.updateCoinsSnapshot().then((data: any) => {
      setPlayerStore("coinsSnapshot", data);
    });
    eventBus.emit("coinsWhileAway", props.coinsChange);
    props.closeModal();
  }

  return (
    <Modal
      open={true}
      showRewardsOverlay
      onPointerDownOutside={handleClose}
    >
      <ModalDescription>
        <div class="flex flex-col justify-center gap-4">
          <div class="eazy-chat text-outline-1 text-center text-3xl text-[#FCB74F]">
            Welcome Back!
          </div>
          <div class="eazy-chat text-outline-1 text-center text-5xl text-white">
            Coins Found!
          </div>
          <img
            src={coinHD}
            style={{ "image-rendering": "pixelated" }}
            class="m-auto -mt-2 h-[70%] w-[70%] object-contain"
          />
          <div class="eazy-chat text-outline-1 text-center text-6xl text-white">
            +{formatCompactNumber(props.coinsChange)}
          </div>
          <Button
            onClick={handleClose}
            class="eazy-chat text-outline-1 mt-6 h-12 min-w-fit border border-[#004811] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-4xl leading-4 text-white"
          >
            <span class="mt-2 align-middle text-white">Claim</span>
          </Button>
        </div>
      </ModalDescription>
    </Modal>
  );
}
