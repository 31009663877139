import posthog from "posthog-js";
import { WebApp } from "@/twa-sdk-updated/core";
import { createStore } from "solid-js/store";
import { isDevelopment, MAVIS_TRACKING_KEY, POSTHOG_KEY } from "@/config.ts";
import { testUser } from "@/components/lib/test-token.ts";
import MavisTracking from "@kurorobeasts/mavis-app-tracking";

const ANALYTIC_PROVIDERS = {
  POSTHOG: POSTHOG_KEY,
  MAVIS: MAVIS_TRACKING_KEY,
};

type AnalyticProvider = keyof typeof ANALYTIC_PROVIDERS;

const ANALYTICS_ENABLED: AnalyticProvider[] = Object.entries(ANALYTIC_PROVIDERS)
  .filter(([, value]) => Boolean(value))
  .map(([name]) => name as AnalyticProvider);

const ANALYTICS_HEADING = `Analytics[${ANALYTICS_ENABLED.join(",")}]`;

const ZeroAddress: string = "0x0000000000000000000000000000000000000000";

let mavisTracker: MavisTracking | undefined;

if (ANALYTICS_ENABLED.includes("MAVIS")) {
  mavisTracker = new MavisTracking(MAVIS_TRACKING_KEY);
}

export const [clicksAnalyticStore, setClicksAnalyticStore] = createStore({
  feedClicksAnalytics: 0,
  mineClicksAnalytics: 0,
});

export const addClickAnalyticsToStore = (
  clickType: "feedClicksAnalytics" | "mineClicksAnalytics",
) => {
  setClicksAnalyticStore((prev) => {
    return {
      ...prev,
      [clickType]: prev[clickType] + 1,
    };
  });
};

export const resetClicksAnalyticsStore = () => {
  setClicksAnalyticStore({
    feedClicksAnalytics: 0,
    mineClicksAnalytics: 0,
  });
};

const captureEvent = (eventName: string, properties = {}) => {
  // if localhost, set console logs too
  if (window.location.hostname === "localhost") {
    console.log(`${ANALYTICS_HEADING}: Capture event`, eventName, properties);
  }

  if (ANALYTICS_ENABLED.includes("POSTHOG")) {
    posthog.capture(eventName, properties);
  }

  if (ANALYTICS_ENABLED.includes("MAVIS")) {
    mavisTracker!.track(eventName, properties);
  }
};

const capturePageView = (pageName: string, properties = {}) => {
  // if localhost, set console logs too
  if (window.location.hostname === "localhost") {
    console.log(
      `${ANALYTICS_HEADING}: Capture page view`,
      pageName,
      properties,
    );
  }

  if (ANALYTICS_ENABLED.includes("POSTHOG")) {
    posthog.capture("navigation", {
      screen: pageName,
      ...properties,
    });
  }

  if (ANALYTICS_ENABLED.includes("MAVIS")) {
    mavisTracker!.screen(pageName, properties);
  }
};

const identifyUser = () => {
  if (!WebApp.initDataUnsafe.user) {
    if (isDevelopment) {
      console.log("User not found, using test user");
      // if localhost, set console logs too
      if (window.location.hostname === "localhost") {
        console.log(`${ANALYTICS_HEADING}: Identify user`, testUser.id, {
          username: testUser.username,
        });
      }

      if (ANALYTICS_ENABLED.includes("POSTHOG")) {
        posthog.identify(testUser.id, {
          username: testUser.username,
        });
      }

      if (ANALYTICS_ENABLED.includes("MAVIS")) {
        mavisTracker!.identify(testUser.id, ZeroAddress, {
          username: testUser.username,
        });
      }
    }

    return;
  }

  const userId = WebApp.initDataUnsafe.user.id;
  const username = WebApp.initDataUnsafe.user.username;

  if (window.location.hostname === "localhost") {
    console.log(`${ANALYTICS_HEADING}: Identify user`, userId.toString(), {
      username,
    });
  }

  if (ANALYTICS_ENABLED.includes("POSTHOG")) {
    posthog.identify(userId.toString(), {
      username,
    });
  }

  if (ANALYTICS_ENABLED.includes("MAVIS")) {
    mavisTracker!.identify(userId.toString(), ZeroAddress, {
      username,
    });
  }
};

function getUserId() {
  if (!WebApp.initDataUnsafe.user) {
    return testUser.id;
  }

  return WebApp.initDataUnsafe.user.id;
}

export { captureEvent, identifyUser, capturePageView, getUserId };
