import { BfDto, BfService } from "@/api-client";
import { createSignal, createEffect, onCleanup } from "solid-js";

const SAVE_CLICKS_INTERVAL = 1000;

const ClickTracker = () => {
  const [clicks, setClicks] = createSignal<BfDto[]>([]);

  const handleClick = (e: MouseEvent) => {
    const newClick = { x: e.clientX, y: e.clientY };
    setClicks((prev) => [...prev, newClick]);
  };

  let clicksInternal: NodeJS.Timeout;
  createEffect(() => {
    window.addEventListener("click", handleClick);

    clicksInternal = setInterval(() => {
      const currentClicks = clicks();
      if (currentClicks.length > 0) {
        BfService.save({ requestBody: currentClicks })
          .then(() => {
            console.log("Clicks saved successfully");
            setClicks([]);
          })
          .catch((error) => {
            console.error("Error saving clicks:", error);
          });
      }
    }, SAVE_CLICKS_INTERVAL);

    onCleanup(() => {
      if (clicksInternal) {
        window.removeEventListener("click", handleClick);
        clearInterval(clicksInternal);
        setClicks([]);
      }
    });
  });

  return null;
};

export default ClickTracker;
