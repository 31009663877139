export const flash = `#version 300 es
precision mediump float;

uniform float u_time_ms;
uniform sampler2D u_graphic;

in vec2 v_uv;
in vec2 v_screenuv;
out vec4 fragColor;

uniform vec3 colour;
uniform vec2 size;
uniform float weight;

void main() {
  vec4 fromColor = texture(u_graphic, v_uv);
  vec4 toColor = vec4(1, 1, 1, fromColor.a);
  fragColor = mix(fromColor, toColor, weight);
  fragColor.rgb = fragColor.rgb * fragColor.a;
}
`