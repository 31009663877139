import {
  earthIcon,
  electricIcon,
  electricStarter,
  moleStarter,
  windIcon,
  windStarter,
} from "../assets/images.ts";

export const ELEMENTS = [
  {
    name: "earth",
    icon: earthIcon,
    color: "#B69B74",
  },
  {
    name: "wind",
    icon: windIcon,
    color: "#ABBCCB",
  },
  {
    name: "electric",
    icon: electricIcon,
    color: "#EC9222",
  },
];

export type BeastMetadata = {
  name: string;
  beastId: number;
  elements: string[];
  beastImage: string;
  beastImageLucky: string;
  beastArt: string;
  lore: string;
  colors: {
    text: string;
    secondary: string;
    background: string;
  };
};

export const BEASTS: BeastMetadata[] = [
  {
    name: "Digby",
    beastId: 211,
    elements: ["earth"],
    beastImage: "/images/digby-starter.gif",
    beastImageLucky: "/images/digby-starter-lucky.gif",
    beastArt: "./images/digby-art.png",
    lore: "In the subterranean labyrinths beneath the island’s surface, the Digby burrows with delight. These small creatures are as endearing as they are industrious, rolling around the ground with infectious glee. Their soft fur, reminiscent of lush moss, blends seamlessly with the earth, making them nearly invisible to the untrained eye. Legends speak of Digby tunnels that lead to hidden groves of rare, life-giving plants, their trails marked by the gentle hum of the earth’s energy.",
    colors: {
      text: "#488F4F",
      secondary: "#1C2A1B",
      background: "linear-gradient(180deg, #365E3A 0%, #1C2A1B 100%)",
    },
  },
  {
    name: "Toru",
    beastId: 215,
    elements: ["wind"],
    beastImage: "/images/toru-starter.gif",
    beastImageLucky: "/images/toru-starter-lucky.gif",
    beastArt: "./images/toru-art.png",
    lore: "Above the island canopies, the Toru glide with serene elegance. Often seen darting between treetops, they possess an uncanny ability to navigate even the densest foliage. Stories abound of Toru guiding lost travelers back to safety. Young Toru are known for their quiet, almost ethereal presence, and their playful aerial displays are a common sight during twilight hours, adding a touch of magic to the island’s skies.",
    colors: {
      text: "#7495AC",
      secondary: "#2A3A4F",
      background: "linear-gradient(180deg, #7495AC 0%, #2A3A4F 100%)",
    },
  },
  {
    name: "Ravolt",
    beastId: 219,
    elements: ["electric"],
    beastImage: "/images/ravolt-starter.gif",
    beastImageLucky: "/images/ravolt-starter-lucky.gif",
    beastArt: "./images/ravolt-art.png",
    lore: "Zipping through the meadows and forests of Kuroro, Ravolt is a sight to behold. This small, agile creature bounces around with electrifying speed, leaving a trail of tiny sparks in its wake. Despite their high voltage nature, Ravolts are known for their playful antics. They are often seen darting through the landscape, their rapid movements and energetic leaps a testament to their boundless energy.",
    colors: {
      text: "#AE77B8",
      secondary: "#2A1A3B",
      background: "linear-gradient(180deg, #432A5D 0%, #181023 100%)",
    },
  },
];
