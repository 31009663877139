import { cn } from "@/lib/utils";
import {
  TonConnectButton,
  useTonConnectUI,
  useTonWallet,
} from "./TonConnectUI";
import { Button } from "./ui/button";
import { walletConnected, walletDisconnected } from "@/assets/images";
import { showToast } from "./ui/toast";
import { WalletsService } from "@/api-client";

export function Wallet(props: { customWallet?: boolean }) {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();

  tonConnectUI()?.onStatusChange((value) => {
    if (!value) return;

    console.log("new wallet connected", { wallet: value });
    WalletsService.updateWallet({
      requestBody: {
        address: value?.account?.address!,
        appName: value?.device.appName,
      },
    }).then(() => {
      showToast({ title: "Wallet added", variant: "success" });
    });
  });

  if (props.customWallet) {
    return (
      <div class="w-full">
        <Button
          class={cn(
            "relative w-full min-w-fit items-center justify-between border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] px-4 py-1 text-lg hover:opacity-70",
            wallet() && "border-[#225D0C] from-[#68CF43] to-[#46A425]",
          )}
          onClick={async () => {
            if (wallet()) {
              // await tonConnectUI()?.disconnect();
              return;
            } else {
              await tonConnectUI()?.openModal();
            }
          }}
        >
          <img
            src={wallet() ? walletConnected : walletDisconnected}
            class="h-6 w-6 object-contain"
          />
          <span
            class="eazy-chat text-outline flex-1"
            style={{
              "-webkit-text-stroke-color": "#B04831",
            }}
          >
            {wallet() ? "Connected TON Wallet" : "Connect TON Wallet"}
          </span>
        </Button>
      </div>
    );
  }

  return (
    <div class="w-full">
      <TonConnectButton class="w-full" />;
    </div>
  );
}
