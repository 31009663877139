import {
  Actor,
  Engine,
  SpriteSheet,
  Animation,
  range,
  vec,
  AnimationStrategy,
  Vector,
  Random,
  Color,
} from "excalibur";
import { ItemSpawner, ItemType, SpawnType } from "../actors/item-spawner";
import { Resources } from "@/game/resources.ts";
import { animManager } from "./animation-manager";
import {
  playerStore,
  playerStoreActions,
  setPlayerStore,
} from "@/components/player-store.ts";
import { vibrate } from "@/lib/utils.ts";
import { FloatText } from "@/game/actors/float-text";
import { Config } from "../config";
import { eventBus } from "@/components/event-bus";
import { gameConfig } from "@/components/lib/game-config";

export class ShardMine extends Actor {
  private oreSmash?: Animation;
  private random: Random;
  private spawner?: ItemSpawner;

  constructor() {
    super({ x: 0, y: 0 });
    this.random = new Random();

    this.on("pointerdown", (event) => {
      const shardsChange = playerStoreActions.handleMining();
      if (shardsChange === undefined) {
        return;
      }
      const shardsText = shardsChange ? "+1" : "No Energy!";
      const shardsTextColor = shardsChange
        ? Color.Magenta
        : Color.fromRGB(255, 64, 64);
      this.playOreSmash(event.worldPos);
      vibrate("light");
      this.scene?.add(
        new FloatText(
          this.pos.x + this.random.integer(-32, 32),
          this.pos.y - 180,
          shardsText,
          32,
          shardsTextColor,
        ),
      );
      if (this.spawner && shardsChange) {
        this.spawner.itemType = ItemType.Shard;
        this.spawner.setLucky(false);
        this.spawner.spawn(1);
      }
    });

    eventBus.on("MineLuckyShard", () => {
      const shardsText = `+${gameConfig.luckies.luckyShardValue}`;
      const shardsTextColor = Color.fromRGB(255, 255, 128);
      vibrate("light");
      this.scene?.add(
        new FloatText(
          this.pos.x + this.random.integer(-32, 32),
          this.pos.y - 180,
          shardsText,
          32,
          shardsTextColor,
        ),
      );
      if (this.spawner) {
        this.spawner.itemType = ItemType.Shard;
        this.spawner.setLucky(true);
        this.spawner.spawn(1);
      }
      setPlayerStore({
        shards: playerStore.shards + gameConfig.luckies.luckyShardValue,
      });
    });
  }

  public onInitialize(engine: Engine<any>): void {
    const viewport = engine.screen.getScreenBounds();

    this.pos.x = viewport.width / 2 - 100;
    this.pos.y = viewport.height - 30 * Config.scale;
    this.name = "shardmine";
    this.anchor = vec(0.5, 1);

    this.spawner = new ItemSpawner(
      SpawnType.Splash,
      ItemType.Shard,
      0,
      undefined,
      new Vector(this.pos.x, this.pos.y),
    );
    this.scene?.add(this.spawner);

    this.pointer.useGraphicsBounds = true;
    this.graphics.use(Resources.Ore.toSprite());
    this.scale = vec(Config.scale, Config.scale);

    const spritesheet = SpriteSheet.fromImageSource({
      image: Resources.OreSmash,
      grid: {
        rows: 1,
        columns: 8,
        spriteWidth: 64,
        spriteHeight: 64,
      },
    });
    this.oreSmash = Animation.fromSpriteSheet(spritesheet, range(0, 7), 80);
    this.oreSmash.strategy = AnimationStrategy.End;
    this.oreSmash.scale = vec(Config.scale, Config.scale);
  }

  public update(engine: ex.Engine, delta: number) {
    super.update(engine, delta);
    this.z = this.pos.y;
  }

  public playOreSmash(pointerPos: Vector): void {
    if (this.oreSmash) {
      animManager.play(this.oreSmash.clone(), pointerPos);
    }
  }
}
