import { BEASTS } from "@/lib/beasts-metadata";
import { createStore } from "solid-js/store";
import { playSfxSound } from "./sounds";
import { eventBus } from "./event-bus";
import { captureEvent } from "@/lib/analytics";
import { captureException } from "@sentry/solid";
import { OnboardingService, StarterOption } from "@/api-client";

export enum OnboardingStep {
  WelcomeMessage,
  PreStarterSelection,
  StarterSelection,
  TappingEgg,
  BeastHatched,
  HelloBeast,
  FeedBeast,
  MoodXpExplanation,
  PreMineShards,
  MineShards,
  FeedBeastAgain,
  FeedBeastMore,
  BeastLevelUp,
  CoinSpendingUpgrades,
  CoinEarningAway,
  MoreLevelMoreCoins,
  BeastHappinessAway,
  BeastHappinessAway2,
  ThatsAll,
  // Lucky Section
  LuckyWelcome,
  LuckyWelcome2,
  LuckyWelcome3,
}

const stepsSkippable = [
  OnboardingStep.WelcomeMessage,
  OnboardingStep.PreStarterSelection,
  OnboardingStep.HelloBeast,
  OnboardingStep.MoodXpExplanation,
  OnboardingStep.BeastLevelUp,
  OnboardingStep.CoinSpendingUpgrades,
  OnboardingStep.CoinEarningAway,
  OnboardingStep.MoreLevelMoreCoins,
  OnboardingStep.BeastHappinessAway,
  OnboardingStep.BeastHappinessAway2,
  OnboardingStep.LuckyWelcome,
  OnboardingStep.LuckyWelcome2,
  OnboardingStep.LuckyWelcome3,
];

const stepsWithBackgroundBlur = [
  OnboardingStep.WelcomeMessage,
  OnboardingStep.PreStarterSelection,
  OnboardingStep.StarterSelection,
  OnboardingStep.MoodXpExplanation,
  OnboardingStep.CoinSpendingUpgrades,
];

const stepsWithStarterOption = [
  OnboardingStep.StarterSelection,
  OnboardingStep.TappingEgg,
  OnboardingStep.HelloBeast,
  OnboardingStep.LuckyWelcome,
];

const stepsWithShockedLabTech = [
  OnboardingStep.BeastHatched,
  OnboardingStep.LuckyWelcome,
];

const [onboardingStore, setOnboardingStore] = createStore({
  currentStep: OnboardingStep.WelcomeMessage,
  selectedStarter: null as StarterOption | null,
  beastIsLucky: false,
  finished: false,
  initialized: false,
  energy: 100,
  shards: 1,
  shardsMined: 0,
  experience: 0,
  level: 1,
  happiness: 0,
  coins: 0,
});

function selectStarter(selectedStarter: StarterOption) {
  setOnboardingStore("selectedStarter", selectedStarter);
}

function moveToStep(step: OnboardingStep) {
  setOnboardingStore("currentStep", step);
}

async function moveToNextStep() {
  let nextStep: OnboardingStep;
  if (onboardingSelectors.beastIsLucky()) {
    if (onboardingSelectors.currentStep() === OnboardingStep.HelloBeast) {
      onboardingActions.moveToStep(OnboardingStep.LuckyWelcome);
      nextStep = OnboardingStep.LuckyWelcome;
    } else if (
      onboardingSelectors.currentStep() === OnboardingStep.LuckyWelcome2
    ) {
      onboardingActions.moveToStep(OnboardingStep.LuckyWelcome3);
      nextStep = OnboardingStep.LuckyWelcome3;
    } else if (
      onboardingSelectors.currentStep() === OnboardingStep.LuckyWelcome3
    ) {
      onboardingActions.moveToStep(OnboardingStep.FeedBeast);
      nextStep = OnboardingStep.FeedBeast;
    } else {
      nextStep = onboardingSelectors.currentStep() + 1;
    }
  } else {
    nextStep = onboardingSelectors.currentStep() + 1;
  }

  console.log("nextStep", nextStep);

  setOnboardingStore("currentStep", nextStep);
  await OnboardingService.updateStep({
    requestBody: {
      newStep: OnboardingStep[nextStep],
    },
  });
}

function checkIfStepIsSkippable() {
  return stepsSkippable.includes(onboardingSelectors.currentStep());
}

function checkIfLastStep() {
  return onboardingSelectors.currentStep() === OnboardingStep.ThatsAll;
}

async function sendSelectedStarter() {
  if (onboardingSelectors.selectedStarter()) {
    await OnboardingService.selectStarter({
      requestBody: {
        starterOption: onboardingSelectors.selectedStarter()!,
      },
    });
  }
}

let finalStepDataSent = false;
function sendFinalStepData(onComplete: () => void) {
  if (!onboardingSelectors.finished() && !finalStepDataSent) {
    finalStepDataSent = true;
    OnboardingService.completeOnboarding()
      .then(() => {
        setOnboardingStore("finished", true);
        captureEvent("onboarding_finished", {
          selectedStarter: onboardingSelectors.selectedStarter(),
        });
        onComplete();
      })
      .catch((e) => {
        captureException(e);
        finalStepDataSent = false;
      });
  }
}

function checkIfRequiresStarterOption() {
  return stepsWithStarterOption.includes(onboardingSelectors.currentStep());
}

function checkIfStepHasBackgroundBlur() {
  return stepsWithBackgroundBlur.includes(onboardingSelectors.currentStep());
}

function checkIfShowSelectStarter() {
  return onboardingSelectors.currentStep() === OnboardingStep.StarterSelection;
}

function checkIfShowShockedLabTech() {
  return stepsWithShockedLabTech.includes(onboardingSelectors.currentStep());
}

function handleMiningOnboarding() {
  const shards = onboardingSelectors.shards();
  playSfxSound("mine");
  const energy = onboardingSelectors.energy();
  const hasEnergy = energy >= 1;
  const shardsChange = hasEnergy ? 1 : 0.1;
  let shardsMined = onboardingSelectors.shardsMined();

  setOnboardingStore("shards", shards + shardsChange);
  setOnboardingStore("shardsMined", shardsMined + 1);
  setOnboardingStore("energy", energy - 1);

  return shardsChange;
}

function handleFeedOnboarding() {
  const shards = onboardingSelectors.shards();
  const experience = onboardingSelectors.experience();
  const level = onboardingSelectors.level();
  const happiness = onboardingSelectors.happiness();
  if (shards < 1) {
    playSfxSound("noShards");
    return false;
  }

  if (
    onboardingSelectors.currentStep() === OnboardingStep.FeedBeast ||
    onboardingSelectors.currentStep() === OnboardingStep.FeedBeastAgain
  ) {
    onboardingActions.moveToNextStep();
  }

  playSfxSound("eat");

  const newExperience = experience + 1;
  const expToNextLevel = 13;
  const newHappiness = Math.min(happiness + 10, 100);
  const newCoins = onboardingSelectors.coins() + 20;

  const isLevelUp = newExperience >= expToNextLevel;

  setOnboardingStore("shards", shards - 1);

  setOnboardingStore("experience", isLevelUp ? 0 : newExperience);
  setOnboardingStore("level", isLevelUp ? level + 1 : level);
  setOnboardingStore("happiness", newHappiness);

  if (isLevelUp) {
    playSfxSound("levelUp");
    eventBus.emit("levelUpOnboarding");
    setOnboardingStore("coins", newCoins);

    if (onboardingSelectors.currentStep() === OnboardingStep.FeedBeastMore) {
      onboardingActions.moveToNextStep();
    }
  }

  return true;
}

function setInitialized({
  initialStep,
  starterSelected,
  beastIsLucky,
}: {
  initialStep: OnboardingStep;
  starterSelected: StarterOption | null;
  beastIsLucky: boolean;
}) {
  setOnboardingStore("initialized", true);
  setOnboardingStore("currentStep", initialStep);
  setOnboardingStore("selectedStarter", starterSelected);
  setOnboardingStore("beastIsLucky", beastIsLucky);

  if (
    initialStep === OnboardingStep.FeedBeastAgain ||
    initialStep === OnboardingStep.FeedBeastMore
  ) {
    setOnboardingStore("shards", 13);
  }
}

export const onboardingActions = {
  selectStarter,
  sendSelectedStarter,
  moveToStep,
  moveToNextStep,
  checkIfStepIsSkippable,
  checkIfStepHasBackgroundBlur,
  checkIfShowShockedLabTech,
  checkIfShowSelectStarter,
  checkIfRequiresStarterOption,
  handleMiningOnboarding,
  handleFeedOnboarding,
  checkIfLastStep,
  sendFinalStepData,
  setInitialized,
};

export const onboardingSelectors = {
  currentStep: () => onboardingStore.currentStep,
  selectedStarter: () => onboardingStore.selectedStarter,
  beastIsLucky: () => onboardingStore.beastIsLucky,
  beastId: () =>
    BEASTS.find((b) => b.name === onboardingStore.selectedStarter)?.beastId,
  finished: () => onboardingStore.finished,
  initialized: () => onboardingStore.initialized,
  energy: () => onboardingStore.energy,
  shards: () => onboardingStore.shards,
  shardsMined: () => onboardingStore.shardsMined,
  experience: () => onboardingStore.experience,
  level: () => onboardingStore.level,
  happiness: () => onboardingStore.happiness,
  coins: () => onboardingStore.coins,
};
