import { createSignal, createEffect, For, onCleanup } from "solid-js";
import { animate, stagger } from "motion";

export default function RainAnimation(props: { image: string; count: number; duration: number }) {
  const [isAnimating, setIsAnimating] = createSignal(true);

  createEffect(() => {
    if (isAnimating()) {
      const raindrops = document.querySelectorAll(".raindrop");
      const animation = animate(
        raindrops,
        { y: "150vh", opacity: [1, 1, 0] },
        {
          delay: stagger(0.1),
          duration: props.duration,
          easing: "ease-in",
        },
      );

      onCleanup(() => {
        animation.stop();
        setIsAnimating(false);
      });
    }
  });

  return (
    <div class="pointer-events-none fixed inset-0 overflow-hidden">
      <For each={Array(props.count).fill(0)}>
        {(_) => (
          <div
            class="raindrop absolute"
            style={{
              left: `${Math.random() * 100}%`,
              top: "-50px",
              opacity: isAnimating() ? 1 : 0,
              transition: "opacity 0.5s ease-out",
            }}
          >
            <img
              src={props.image}
              alt="Raindrop"
              class="h-10 w-10 object-contain"
              style={{
                transform: `rotate(${Math.random() * 100 - 50}deg)`,
              }}
            />
          </div>
        )}
      </For>
    </div>
  );
}
