import { useLocation, useNavigate } from "@solidjs/router";
import { For, Show, onCleanup, onMount } from "solid-js";
import { navLinks } from "./BottomNavbar";
import { LoaderIcon, TriangleDown } from "@/assets/extra-icons";
import { cn } from "@/lib/utils";
import { Suspense } from "solid-js/web";
import { pawsImg } from "@/assets/images";

export const PageContainer = (props: {
  children: any;
  hideShadowInnerBottom?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div class="relative">
      <div
        class="absolute inset-0 h-[100vh] bg-black bg-opacity-50 backdrop-blur-md"
        onClick={() => navigate("/play")}
      />
      <div
        class="pointer-events-none fixed left-0 right-0 mx-auto flex w-full max-w-xl justify-around"
        style={{
          bottom: "calc(52px + 5vh)",
          height: "40px",
        }}
      >
        <For each={navLinks}>
          {(navLink) => (
            <div
              style={{
                opacity: location.pathname === navLink.href ? 1 : 0,
              }}
            >
              <TriangleDown height="100%" />
            </div>
          )}
        </For>
      </div>
      <div
        class={cn(
          "fixed left-0 right-0 mx-auto max-w-xl overflow-hidden rounded-3xl bg-[#FFE7DF]",
          "duration-300 ease-in-out animate-in slide-in-from-bottom",
        )}
        style={{
          height: "calc(90vh - 70px)",
          bottom: "calc(80px + 5vh)",
          "background-image": `url(${pawsImg})`,
          "background-size": "contain",
          "background-position": "center 95%",
          "background-repeat": "no-repeat",
        }}
      >
        <Suspense
          fallback={
            <div class="mx-auto flex justify-center pt-16">
              <LoaderIcon class="h-12 w-12 animate-spin text-stone-400" />
            </div>
          }
        >
          {props.children}
        </Suspense>
        <Show when={!props.hideShadowInnerBottom}>
          <div
            class="pointer-events-none sticky -bottom-1 z-50 h-10 w-full"
            style={{
              background:
                "linear-gradient(180deg, rgba(255, 231, 223, 0) 0%, #FFE7DF 71.5%)",
            }}
          />
        </Show>
      </div>
    </div>
  );
};
