import { energyLittleIcon } from "@/assets/images";
import RainAnimation from "./RainAnimation";
import { DateTime } from "luxon";
import { createEffect, createSignal, onCleanup, onMount } from "solid-js";
import { playerStore, refreshPlayerStatusEffects } from "./player-store";

const createCountdownTimerUTC = (endDate: Date | undefined) => {
  if (!endDate) {
    return () => 0;
  }
  const [secondsRemaining, setSecondsRemaining] = createSignal(0);

  const calculateSecondsRemaining = () => {
    const now = DateTime.utc();
    const end = DateTime.fromJSDate(endDate, { zone: "utc" });
    const timeDiff = end.diff(now, "seconds").seconds;

    const newSecondsRemaining = Math.max(0, parseInt(timeDiff.toFixed(0)));
    setSecondsRemaining(newSecondsRemaining);

    if (newSecondsRemaining <= 0) {
      refreshPlayerStatusEffects();
    }
  };

  calculateSecondsRemaining();
  const timer = setInterval(calculateSecondsRemaining, 1000);
  onCleanup(() => clearInterval(timer));

  return secondsRemaining;
};

export default function StatusEffectLayout() {
  const unlimitedEnergyEffect = playerStore.statusEffects.find(
    (effect) => effect.type === "UnlimitedEnergyEffect",
  );
  const endsAt = unlimitedEnergyEffect?.expiresAt;

  const counter = createCountdownTimerUTC(endsAt);

  onMount(async () => {
    let timer = setInterval(async () => {
      await refreshPlayerStatusEffects();
    }, counter() * 1000);

    onCleanup(() => clearInterval(timer));
  });

  createEffect(async () => {
    if (counter() <= 0) {
      await refreshPlayerStatusEffects();
    }
  });

  return (
    <div class="pointer-events-none absolute bottom-0 left-0 right-0 top-0">
      <div class="mt-[120px] flex flex-col items-center justify-center">
        <p class="eazy-chat text-outline text-2xl text-[#FAEA59]">
          Unlimited Energy
        </p>
        <p class="eazy-chat text-outline text-2xl text-[#FAEA59]">
          {counter()}
        </p>
      </div>
      <RainAnimation image={energyLittleIcon} count={400} duration={2} />
    </div>
  );
}
