import {
  EnergyBallsService,
  InventoryItem,
  InventoryService,
  RaffleTicketsService,
} from "@/api-client";
import { ChevronLeftIcon } from "@/assets/extra-icons";
import {
  completedImg,
  giftImg,
  kuroImg,
  kuroRewardBg,
  raffleTicketsLogo,
  rewardBg,
  uncompletedImg,
} from "@/assets/images";
import { REWARD_COLORS, REWARD_IMAGES } from "@/assets/rewards/reward-images";
import { CloseButton } from "@/components/CloseButton";
import { initializeEnergyBall } from "@/components/energy-ball";
import { eventBus } from "@/components/event-bus";
import { useGame } from "@/components/GameProvider";
import { PageContainer } from "@/components/PageContainer";
import {
  playerStoreSelector,
  refreshPlayerState,
} from "@/components/player-store";
import { RaffleResultModal } from "@/components/RaffleResultModal";
import { useTonWallet } from "@/components/TonConnectUI";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Wallet } from "@/components/Wallet";
import { RAFFLE_ITEMS, RaffleItem } from "@/game/scenes/raffle-machine";
import { captureEvent } from "@/lib/analytics";
import { cn, formatCompactNumber } from "@/lib/utils";
import { useNavigate, useSearchParams } from "@solidjs/router";
import { createMutation, createQuery } from "@tanstack/solid-query";
import { toUserFriendlyAddress } from "@tonconnect/ui";
import posthog from "posthog-js";
import { createSignal, For, Match, Show, Switch } from "solid-js";

const ITEMS_REDEEMABLE = [
  "lucky-serum",
  "shards",
  "energy-ball",
  "energy-drink",
];

function RewardCard(props: {
  rewardItem: InventoryItem;
  setSelectedReward: (reward: RaffleItem) => void;
  isEnergyBallDestroyed: () => boolean;
  showEnergyBallAgain: () => void;
}) {
  const navigate = useNavigate();
  const raffleItem = RAFFLE_ITEMS.find(
    (item) => item.itemId === props.rewardItem.itemId,
  );

  const useItemMutation = createMutation(() => ({
    mutationFn: () => {
      return InventoryService.useItem({
        requestBody: {
          itemId: props.rewardItem.itemId,
        },
      });
    },
    onSuccess: async (result) => {
      captureEvent("item_redeemed", {
        item: props.rewardItem.itemId,
      });
      await refreshPlayerState();
      navigate("/play");
      if (props.rewardItem.itemId === "lucky-serum") {
        eventBus.emit("luckySerumClaimed");
      }
      if (props.rewardItem.itemId === "energy-ball") {
        props.showEnergyBallAgain();
      }
    },
  }));

  return (
    <Card
      class="relative flex h-[144px] flex-1 overflow-hidden border-0 text-white"
      style={{
        background:
          REWARD_COLORS[props.rewardItem.itemId as keyof typeof REWARD_COLORS],
      }}
      onClick={() => {
        if (raffleItem) {
          props.setSelectedReward({
            ...raffleItem,
            description:
              props.rewardItem.itemId === "kuro"
                ? `You won ${props.rewardItem.quantity} $KURO! Unlocks at TGE after 'The Hatchening' Airdrop event`
                : props.rewardItem.itemId === "shards"
                  ? `${props.rewardItem.quantity} Shards! Redeem in your Airdrop rewards.`
                  : raffleItem.description,
          });
        }
      }}
    >
      <div
        class="absolute bottom-0 left-0 right-0 top-0 z-[5] min-h-full min-w-full bg-cover bg-center bg-no-repeat"
        style={{
          "background-image": `url(${rewardBg})`,
        }}
      />
      <p class="eazy-chat text-outline absolute left-2 top-2 z-10 text-lg font-medium leading-5">
        x{formatCompactNumber(props.rewardItem.quantity)}
      </p>
      <Show
        when={
          props.rewardItem.itemId === "energy-ball" &&
          !props.isEnergyBallDestroyed()
        }
      >
        <div class="absolute inset-0 z-20 flex flex-col items-center justify-center gap-2 bg-red-500 bg-opacity-70 text-center">
          <p class="eazy-chat text-outline z-10 text-lg font-medium leading-5">
            Limit Reached!
          </p>
          <p class="eazy-chat text-outline z-10 text-lg font-medium leading-5">
            Clear your Energy Ball to redeem another!
          </p>
        </div>
      </Show>
      <Show
        when={
          props.rewardItem.itemId === "energy-drink" &&
          playerStoreSelector.hasEffect("UnlimitedEnergyEffect")
        }
      >
        <div class="absolute inset-0 z-20 flex flex-col items-center justify-center gap-2 bg-red-500 bg-opacity-70 text-center">
          <p class="eazy-chat text-outline z-10 text-lg font-medium leading-5">
            Limit Reached!
          </p>
          <p class="eazy-chat text-outline z-10 text-lg font-medium leading-5">
            Finish your current Energy Drink to redeem another!
          </p>
        </div>
      </Show>
      <CardContent class="z-10 flex h-full w-full flex-col justify-center gap-2 p-2">
        <div class="flex flex-col">
          <div class="flex items-center justify-center">
            <img
              class="z-10 h-14 w-14 object-contain"
              style={{ "image-rendering": "pixelated" }}
              src={
                REWARD_IMAGES[
                  props.rewardItem.itemId as keyof typeof REWARD_IMAGES
                ]
              }
            />
          </div>
        </div>
        <div class="z-10 flex justify-center">
          <div>
            <h3 class="eazy-chat text-outline z-10 text-lg font-medium leading-5">
              {raffleItem?.name}
            </h3>
          </div>
        </div>
        <Show when={ITEMS_REDEEMABLE.includes(props.rewardItem.itemId)}>
          <Button
            onClick={async (e: Event) => {
              e.stopPropagation();
              await useItemMutation.mutateAsync();
            }}
            disabled={
              useItemMutation.isPending ||
              (props.rewardItem.itemId === "energy-ball" &&
                !props.isEnergyBallDestroyed())
            }
            size="sm"
            class="eazy-chat text-outline relative z-10 min-w-fit border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
          >
            <span class="z-10 mt-1">Redeem</span>
          </Button>
        </Show>
      </CardContent>
    </Card>
  );
}

function createInventoryQuery() {
  return createQuery(() => ({
    queryKey: ["inventory"],
    queryFn: () => InventoryService.getInventory(),
  }));
}

function RewardsPage(props: { closeRewardsPage: () => void }) {
  const inventoryQuery = createInventoryQuery();
  const ticketsCountQuery = createQuery(() => ({
    queryKey: ["raffleTickets"],
    queryFn: RaffleTicketsService.getRaffleTickets,
  }));
  const energyBallStateQuery = createQuery(() => ({
    queryKey: ["energyBallState"],
    queryFn: EnergyBallsService.getEnergyBallState,
  }));
  const [selectedReward, setSelectedReward] = createSignal<RaffleItem | null>(
    null,
  );
  const game = useGame();

  const showEnergyBallAgain = async () => {
    if (game()) {
      await initializeEnergyBall(game()!, true);
    }
  };

  const isEnergyBallDestroyed = () => {
    if (energyBallStateQuery.data) {
      return energyBallStateQuery.data.isDestroyed;
    } else {
      return false;
    }
  };

  const ignoredIds = [
    "witch",
    "boc",
    "straw",
    "leaf",
    "halo",
    "eggshell",
    "mushroom",
    "acorn",
  ];
  const rewards = () =>
    inventoryQuery.data?.filter((i) => !ignoredIds.includes(i.itemId)) ?? [];

  return (
    <div class="h-full w-full overflow-y-hidden bg-[#7BB9E7]">
      <div class="relative flex h-[50px] items-center justify-center py-2">
        <Button
          variant="ghost"
          class="min-w-auto absolute left-1 top-1 flex items-center px-2 py-0 text-base"
          onClick={props.closeRewardsPage}
        >
          <ChevronLeftIcon />
          Back
        </Button>
        <p
          class="eazy-chat text-outline-1 text-2xl font-black text-white"
          style={{
            "-webkit-text-stroke-color": "#22436A",
          }}
        >
          My Rewards
        </p>
      </div>
      <div
        class="grid grid-cols-2 gap-2 overflow-y-auto overscroll-y-contain bg-[#FFE7DF] p-3"
        style={{
          height: "calc(100% - 100px)",
          "grid-template-rows": "repeat(auto-fill, 144px)",
        }}
      >
        <For each={rewards()}>
          {(rewardItem) => (
            <RewardCard
              rewardItem={rewardItem}
              setSelectedReward={setSelectedReward}
              isEnergyBallDestroyed={isEnergyBallDestroyed}
              showEnergyBallAgain={showEnergyBallAgain}
            />
          )}
        </For>
      </div>
      <RaffleResultModal
        isModalOpen={selectedReward() !== null}
        closeModal={() => {
          setSelectedReward(null);
        }}
        raffleResult={selectedReward()}
        ticketsCount={ticketsCountQuery.data?.count || 0}
        closeLabel="Close"
      />
    </div>
  );
}

export default function AirdropRoute() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isRewardsViewOpen, setIsRewardsViewOpen] = createSignal(
    searchParams.isRewardsViewOpen || false,
  );
  const inventoryQuery = createInventoryQuery();
  const wallet = useTonWallet();
  const ticketsCountQuery = createQuery(() => ({
    queryKey: ["raffleTickets"],
    queryFn: RaffleTicketsService.getRaffleTickets,
  }));

  const normalizedAddress = (): string => {
    if (wallet() && wallet()?.account) {
      const userFriendlyAddress = toUserFriendlyAddress(
        wallet()!.account.address,
      );
      return (
        userFriendlyAddress.slice(0, 4) + "…" + userFriendlyAddress.slice(-4)
      );
    }

    return "";
  };

  return (
    <PageContainer hideShadowInnerBottom>
      <div class="h-full overflow-auto">
        <div class="sticky top-0 z-10 flex h-[50px] flex-col items-center justify-center bg-[#476EA9] px-3 py-0 text-center">
          <CloseButton />
          <h2 class="eazy-chat text-outline-1 mt-1 text-4xl font-bold leading-tight text-white">
            Airdrop
          </h2>
        </div>
        <Switch
          fallback={
            <div>
              <div
                class="flex items-center justify-between gap-2 bg-[#7BB9E7] px-6 py-3"
                style={{
                  "background-image": `url(${kuroRewardBg})`,
                  "background-position": "left",
                  "background-size": "contain",
                  "background-repeat": "no-repeat",
                }}
              >
                <img src={kuroImg} class="h-[70px] w-[70px] object-contain" />
                <div class="flex flex-col rounded-xl bg-[#9FD7F7] p-3">
                  <p
                    class="eazy-chat text-outline-1 text-2xl font-black text-white"
                    style={{
                      "-webkit-text-stroke-color": "#22436A",
                    }}
                  >
                    $KURO Coming Soon
                  </p>
                  <p class="eazy-chat text-sm font-bold text-[#22436A]">
                    The $KURO token, central to the Kuroro Beasts gaming
                    ecosystem, is on its way! Complete the tasks below to
                    prepare for the Airdrop!
                  </p>
                </div>
              </div>
              <div class="my-2 flex flex-col gap-2">
                <div
                  class={cn(
                    "flex w-full flex-col gap-2 bg-[#FFD0C5] px-2 py-2",
                  )}
                >
                  <div class="flex w-full items-center gap-2">
                    <img
                      src={wallet() ? completedImg : uncompletedImg}
                      class="h-10 w-10 object-contain"
                    />
                    <Wallet customWallet />
                  </div>
                </div>
                <Show when={posthog.isFeatureEnabled("raffle_tickets")}>
                  <Show
                    when={
                      ticketsCountQuery.data?.count === 0 &&
                      inventoryQuery.data?.length === 0
                    }
                  >
                    <div class="my-6 flex items-center justify-center text-center">
                      <p class="eazy-chat tracking-wider text-[#380A08] opacity-50">
                        More tasks coming soon!
                      </p>
                    </div>
                  </Show>
                  {/* When has tickets */}
                  <Show when={(ticketsCountQuery.data?.count || 0) > 0}>
                    <div class="flex flex-col items-center justify-center gap-2 pb-4 text-center">
                      <p class="eazy-chat text-outline text-2xl font-bold text-white">
                        You have pending rewards!
                      </p>
                      <img
                        src={raffleTicketsLogo}
                        class="h-[100px] w-[150px] object-contain"
                      />
                      <div>
                        <Button
                          class={cn(
                            "relative w-full min-w-fit items-center justify-between border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] px-10 py-1 text-2xl hover:opacity-100",
                          )}
                          // Open tickets raffle
                          onClick={() => navigate("/raffle-machine")}
                        >
                          <span
                            class="eazy-chat text-outline flex-1"
                            style={{
                              "-webkit-text-stroke-color": "#B04831",
                            }}
                          >
                            Redeem Tickets
                          </span>
                        </Button>
                      </div>
                    </div>
                  </Show>
                  {/* When has rewards */}
                  <Show
                    when={inventoryQuery.data && inventoryQuery.data.length > 0}
                  >
                    <div class="flex flex-col items-center justify-center gap-2 pb-4 text-center">
                      <p class="eazy-chat text-outline text-2xl font-bold text-white">
                        You've earned rewards!
                      </p>
                      <img
                        src={giftImg}
                        class="h-[90px] w-[90px] object-contain"
                      />
                      <div>
                        <Button
                          class={cn(
                            "relative w-full min-w-fit items-center justify-between border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] px-10 py-1 text-2xl hover:opacity-100",
                          )}
                          onClick={() => setIsRewardsViewOpen(true)}
                        >
                          <span
                            class="eazy-chat text-outline flex-1"
                            style={{
                              "-webkit-text-stroke-color": "#B04831",
                            }}
                          >
                            View Rewards
                          </span>
                        </Button>
                      </div>
                    </div>
                  </Show>
                </Show>
              </div>
            </div>
          }
        >
          <Match when={isRewardsViewOpen()}>
            <RewardsPage closeRewardsPage={() => setIsRewardsViewOpen(false)} />
          </Match>
        </Switch>
      </div>
    </PageContainer>
  );
}
