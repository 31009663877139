import { Modal, ModalDescription } from "./ui/modal";
import { Button } from "./ui/button";
import { RaffleItem } from "@/game/scenes/raffle-machine";
import {
  raffleBronzeEgg,
  raffleSilverEgg,
  raffleGoldEgg,
  raffleEnergyBall,
  raffleLuckySerum,
  raffleShard,
  raffleKuro,
  raffleSilverTicket,
  raffleEnergyDrink,
} from "@/assets/images";

export function RaffleResultModal(props: {
  isModalOpen: boolean;
  closeModal: () => void;
  raffleResult: RaffleItem | null;
  ticketsCount: number;
  closeLabel?: string;
}) {
  function getImageSrc(raffleItem: RaffleItem | null): string {
    if (raffleItem === null) return "";
    let imageSrc: string = raffleShard;
    switch (raffleItem!.rewardId) {
      case "BronzeTreasureEgg":
        imageSrc = raffleBronzeEgg;
        break;
      case "SilverTreasureEgg":
        imageSrc = raffleSilverEgg;
        break;
      case "GoldenTreasureEgg":
        imageSrc = raffleGoldEgg;
        break;
      case "EnergyBall":
        imageSrc = raffleEnergyBall;
        break;
      case "LuckySerum":
        imageSrc = raffleLuckySerum;
        break;
      case "Kuro50":
      case "Kuro100":
      case "Kuro500":
      case "Kuro50K":
        imageSrc = raffleKuro;
        break;
      case "SilverTicket":
        imageSrc = raffleSilverTicket;
        break;
      case "EnergyDrink":
        imageSrc = raffleEnergyDrink;
        break;
    }
    return imageSrc;
  }

  function getRarityColor(rarity: string | undefined): string {
    switch (rarity) {
      case "Uncommon":
        return "#5fa823";
      case "Rare":
        return "#28679c";
      case "Epic":
        return "#8A2BE2";
      case "Mythic":
        return "#9c2868";
      case "Legendary":
        return "#FA9044";
      default:
        return "#000000";
    }
  }

  return (
    <Modal
      open={props.isModalOpen}
      showRewardsOverlay
      onPointerDownOutside={props.closeModal}
    >
      <ModalDescription>
        <div class="flex flex-col justify-center gap-4">
          <div class="eazy-chat text-outline-1 text-center text-5xl text-[#FCB74F]">
            {props.raffleResult?.name}
          </div>
          <div class="eazy-chat text-center text-xl text-white p-1 rounded-full m-auto w-40" style={{"background-color": getRarityColor(props.raffleResult?.rarity)}}>
            {props.raffleResult?.rarity}
          </div>
          <img
            src={getImageSrc(props.raffleResult)}
            style={{ "image-rendering": "pixelated" }}
            class="m-auto -mt-2 h-[60%] w-[60%] object-contain"
          />
          <div class="text-center text-2xl text-white font-bold">
            {props.raffleResult?.description}
          </div>
          <Button
            onClick={props.closeModal}
            class="eazy-chat text-outline-1 mt-2 h-12 min-w-fit border border-[#004811] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-4xl leading-4 text-white"
          >
            <span class="mt-2 align-middle text-white">
              {props.closeLabel ?? "Next"}
            </span>
          </Button>
        </div>
      </ModalDescription>
    </Modal>
  );
}
