import * as ex from 'excalibur';

export class Corral extends ex.Actor {
  constructor(width: number, height: number) {
    super({
      x: width / 2,
      y: height - 50,
      width: width,
      height: 100,
      color: ex.Color.Gray.lighten(0.5),
      z: 100,
    });
  }
}