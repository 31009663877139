import { labTech, labTechShocked } from "@/assets/images";
import "./Views.css";
import { Switch, Match, Show } from "solid-js";
import { createStore } from "solid-js/store";
import BackgroundOverlay from "./BackgroundOverlay";
import { cn } from "@/lib/utils";

export enum FTUEBoostersStep {
  CheckItOut,
  TapBoosters,
  HereBuy,
  BuyOnceADay,
  Researching,
}

const stepsSkippable = [
  FTUEBoostersStep.CheckItOut,
  FTUEBoostersStep.HereBuy,
  FTUEBoostersStep.BuyOnceADay,
];
const stepsWithShockedLabTech: FTUEBoostersStep[] = [];
const stepsWithBackgroundBlur = [FTUEBoostersStep.CheckItOut];

const [ftueBoosterStore, setFTUEBoosterStore] = createStore({
  initialized: false,
  currentStep: FTUEBoostersStep.CheckItOut,
  finished: false,
});

export const ftueBoosterStoreSelectors = {
  initialized: () => ftueBoosterStore.initialized,
  currentStep: () => ftueBoosterStore.currentStep,
  finished: () => ftueBoosterStore.finished,
};

function initiate() {
  setFTUEBoosterStore("initialized", true);
}

function moveToStep(step: FTUEBoostersStep) {
  setFTUEBoosterStore("currentStep", step);
}

async function moveToNextStep() {
  let nextStep: FTUEBoostersStep;
  nextStep = ftueBoosterStoreSelectors.currentStep() + 1;
  setFTUEBoosterStore("currentStep", nextStep);
}

function checkIfStepIsSkippable() {
  return stepsSkippable.includes(ftueBoosterStoreSelectors.currentStep());
}

function checkIfStepHasBackgroundBlur() {
  return stepsWithBackgroundBlur.includes(
    ftueBoosterStoreSelectors.currentStep(),
  );
}

function checkIfLastStep() {
  return (
    ftueBoosterStoreSelectors.currentStep() === FTUEBoostersStep.Researching
  );
}

function checkIfShowShockedLabTech() {
  return stepsWithShockedLabTech.includes(
    ftueBoosterStoreSelectors.currentStep(),
  );
}

function sendFinalStepData(onComplete: () => void) {
  if (!ftueBoosterStoreSelectors.finished()) {
    setFTUEBoosterStore("finished", true);
    setFTUEBoosterStore("initialized", false);
    onComplete();
  }
}

export const ftueBoosterActions = {
  moveToStep,
  moveToNextStep,
  checkIfStepIsSkippable,
  checkIfShowShockedLabTech,
  checkIfLastStep,
  sendFinalStepData,
  initiate,
  checkIfStepHasBackgroundBlur,
};

export function DialogText() {
  return (
    <Switch>
      <Match
        when={
          ftueBoosterStoreSelectors.currentStep() ===
          FTUEBoostersStep.CheckItOut
        }
      >
        <p class="text-center">
          Check it out! I added new 🚀 <span class="font-bold">Boosters</span>{" "}
          to your Upgrades page.
        </p>
      </Match>
      <Match
        when={
          ftueBoosterStoreSelectors.currentStep() ===
          FTUEBoostersStep.TapBoosters
        }
      >
        <p>
          Tap the <span class="font-bold">Boosters</span> page to see what’s
          available for purchase.
        </p>
      </Match>
      <Match
        when={
          ftueBoosterStoreSelectors.currentStep() === FTUEBoostersStep.HereBuy
        }
      >
        <p>
          Here you can buy temporary bonuses that help you progress faster, and
          even <span class="font-bold">Raffle Tickets</span> for the next
          monthly Raffle!
        </p>
      </Match>
      <Match
        when={
          ftueBoosterStoreSelectors.currentStep() ===
          FTUEBoostersStep.BuyOnceADay
        }
      >
        <p>
          You can only buy each Booster <span class="font-bold">once</span> per
          day using Coins. The Booster shop Restocks every 24 hours so make sure
          to check at least once a day!
        </p>
      </Match>
      <Match
        when={
          ftueBoosterStoreSelectors.currentStep() ===
          FTUEBoostersStep.Researching
        }
      >
        <p>
          When we’re not talking, I’m busy researching new kinds of Boosters to
          help you grow your Ranch! I’ll let you know whenever I’m ready to
          unveil a new Booster 🤫
        </p>
      </Match>
    </Switch>
  );
}

export default function DialogBoostersOverlay() {
  return (
    <div
      class={cn(
        "pointer-events-none absolute bottom-0 h-full w-full select-none",
        (ftueBoosterActions.checkIfStepIsSkippable() ||
          ftueBoosterActions.checkIfLastStep()) &&
          "pointer-events-auto",
      )}
      onPointerDown={() => {
        if (ftueBoosterActions.checkIfStepIsSkippable()) {
          ftueBoosterActions.moveToNextStep();
        } else if (ftueBoosterActions.checkIfLastStep()) {
          ftueBoosterActions.sendFinalStepData(() => {
            console.log("Final step data sent");
          });
        }
      }}
    >
      <Show when={ftueBoosterActions.checkIfStepHasBackgroundBlur()}>
        <div class="pointer-events-auto">
          <BackgroundOverlay />
        </div>
      </Show>
      <div class="relative flex h-full w-full flex-col justify-end p-4 pb-24">
        <div class="flex w-full justify-end">
          <img
            src={
              ftueBoosterActions.checkIfShowShockedLabTech()
                ? labTechShocked
                : labTech
            }
            class="-mb-3 w-[60%] object-contain"
          />
        </div>
        <div
          id="dialogue-bubble"
          class="pointer-events-auto relative min-h-[80px] overflow-y-auto rounded-lg bg-white p-[10px] text-lg leading-6"
        >
          <DialogText />
        </div>
      </div>
    </div>
  );
}
