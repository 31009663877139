import {
  ChevronLeftIcon,
  DiscordIcon,
  TelegramIcon,
  TiktokIcon,
  XTwitterIcon,
  YoutubeIcon,
} from "@/assets/extra-icons";
import { checkImg, hourglassImage, shardIcon } from "@/assets/images";
import { CloseButton } from "@/components/CloseButton";
import { PageContainer } from "@/components/PageContainer";
import {
  createEffect,
  createSignal,
  For,
  JSX,
  Match,
  onMount,
  Show,
  Switch,
} from "solid-js";
import { Button } from "@/components/ui/button.tsx";
import { Card, CardContent } from "@/components/ui/card.tsx";
import { playerStore, setPlayerStore } from "@/components/player-store.ts";
import { Dynamic } from "solid-js/web";
import { showToast } from "@/components/ui/toast.tsx";
import { cn, isMobile, showOneDecimal } from "@/lib/utils";
import { openLink, openTelegramLink } from "@/lib/telegram";
import { createQuery } from "@tanstack/solid-query";
import { captureEvent } from "@/lib/analytics";
import createCountdownTimer from "@/components/lib/countdown-timer";
import {
  ConnectSocialsService,
  PlayerQuestDto,
  Quest,
  QuestsService,
} from "@/api-client";
import { CUSTOM_QUESTS_IMAGES } from "@/assets/custom-quests/custom-quests-images";
import { useSearchParams } from "@solidjs/router";

type QuestType =
  | "FollowTwitterAccountQuest"
  | "JoinDiscordServerQuest"
  | "RetweetTweetQuest"
  | "JoinTelegramChannelQuest"
  | "FollowTiktokAccountQuest"
  | "LikeTiktokPostQuest"
  | "YoutubeSubscribeQuest"
  | "YoutubeContentQuest"
  | "CustomLinkQuest"
  | "InstagramFollowQuest"
  | "InstagramPostQuest";

export type PlayerQuest = PlayerQuestDto & {
  quest: Quest & { type: QuestType };
};

function openTwitterAuthorizeUrl() {
  ConnectSocialsService.connectTwitter().then((r) => {
    openLink(r.url);
  });
}

function openDiscordAuthorizeUrl() {
  ConnectSocialsService.connectDiscord().then((r) => {
    openLink(r.url);
  });
}

const ICONS: Record<QuestType, (props: any) => JSX.Element> = {
  FollowTwitterAccountQuest: XTwitterIcon,
  JoinDiscordServerQuest: DiscordIcon,
  RetweetTweetQuest: XTwitterIcon,
  JoinTelegramChannelQuest: TelegramIcon,
  FollowTiktokAccountQuest: TiktokIcon,
  LikeTiktokPostQuest: TiktokIcon,
  YoutubeSubscribeQuest: YoutubeIcon,
  YoutubeContentQuest: YoutubeIcon,
  CustomLinkQuest: () => null,
  InstagramFollowQuest: () => null,
  InstagramPostQuest: () => null,
};

const ICON_SCALE: Record<QuestType, number> = {
  FollowTwitterAccountQuest: 60,
  RetweetTweetQuest: 60,
  JoinDiscordServerQuest: 70,
  JoinTelegramChannelQuest: 100,
  FollowTiktokAccountQuest: 70,
  LikeTiktokPostQuest: 70,
  YoutubeSubscribeQuest: 70,
  YoutubeContentQuest: 70,
  CustomLinkQuest: 100,
  InstagramFollowQuest: 100,
  InstagramPostQuest: 100,
};

const ICON_BG_COLORS: Record<QuestType, string> = {
  FollowTwitterAccountQuest: "#000000",
  RetweetTweetQuest: "#000000",
  JoinDiscordServerQuest: "#ffffff",
  JoinTelegramChannelQuest: "#26A4E4",
  FollowTiktokAccountQuest: "#ffffff",
  LikeTiktokPostQuest: "#ffffff",
  YoutubeSubscribeQuest: "#ffffff",
  YoutubeContentQuest: "#ffffff",
  CustomLinkQuest: "#000000",
  InstagramFollowQuest: "#ffffff",
  InstagramPostQuest: "#ffffff",
};

// Only show confirm drawer for these quests ids
const QUESTS_THAT_REQUIRE_TIME_TO_VERIFY: QuestType[] = [
  "RetweetTweetQuest",
  "FollowTwitterAccountQuest",
  "FollowTiktokAccountQuest",
  "LikeTiktokPostQuest",
  "YoutubeSubscribeQuest",
  "YoutubeContentQuest",
  "CustomLinkQuest",
  "InstagramFollowQuest",
  "InstagramPostQuest",
];

function QuestVerificationView(props: {
  playerQuest: PlayerQuest;
  clearSelectedPlayerQuest: () => void;
  playerQuestsRefetch: () => void;
}) {
  const [isLoading, setIsLoading] = createSignal(false);
  const handleVerify = () => {
    if (isLoading()) return;

    setIsLoading(true);

    QuestsService.verifyQuest({
      questId: props.playerQuest.quest.id,
    })
      .then((verificationResult) => {
        if (!verificationResult.isCompleted) {
          showToast({
            title: "Verification Pending",
            description: verificationResult.failureReason ?? "",
          });
          captureEvent("quest_verification_failed", {
            questId: props.playerQuest.quest.id,
            questName: props.playerQuest.quest.name,
            questType: props.playerQuest.quest.type,
            failureReason: verificationResult.failureReason,
          });
        } else {
          showToast({
            title: "Quest completed!",
            description: `You've earned ${props.playerQuest.quest.reward} shards!`,
          });
          setPlayerStore(
            "shards",
            playerStore.shards + props.playerQuest.quest.reward!,
          );
          captureEvent("quest_verification_success", {
            questId: props.playerQuest.quest.id,
            questName: props.playerQuest.quest.name,
            questType: props.playerQuest.quest.type,
          });
          props.clearSelectedPlayerQuest();
        }
        props.playerQuestsRefetch();
      })
      .catch((e) => {
        console.error("ERROR!!!", e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div class="p-3 pb-10">
      <div class="flex h-full flex-grow flex-col items-center gap-4 pt-2">
        <div class="relative flex w-[100%] flex-col items-center justify-center gap-2 overflow-hidden rounded-xl bg-[#ddc4eb] p-4">
          <Button
            variant="ghost"
            class="min-w-auto absolute left-1 top-1 flex items-center p-0 text-base"
            onClick={props.clearSelectedPlayerQuest}
          >
            <ChevronLeftIcon />
            Back
          </Button>
          <div
            class={cn(
              "m-auto flex h-16 w-16 items-center justify-center rounded-full",
              props.playerQuest.status === "Completed" && "opacity-60",
            )}
            style={{
              "background-color": ICON_BG_COLORS[props.playerQuest.quest.type],
            }}
          >
            <Switch
              fallback={
                <Dynamic
                  component={ICONS[props.playerQuest.quest.type]}
                  class="object-contain"
                  style={{
                    height: `${ICON_SCALE[props.playerQuest.quest.type]}%`,
                    width: `${ICON_SCALE[props.playerQuest.quest.type]}%`,
                  }}
                />
              }
            >
              <Match when={props.playerQuest.quest.type === "CustomLinkQuest"}>
                <img
                  class="rounded-full"
                  // @ts-ignore TRUST ME BRO
                  src={CUSTOM_QUESTS_IMAGES[props.playerQuest.quest.iconId]}
                  style={{
                    height: `${ICON_SCALE[props.playerQuest.quest.type]}%`,
                    width: `${ICON_SCALE[props.playerQuest.quest.type]}%`,
                  }}
                />
              </Match>
              <Match
                when={
                  props.playerQuest.quest.type === "InstagramFollowQuest" ||
                  props.playerQuest.quest.type === "InstagramPostQuest"
                }
              >
                <img
                  class="rounded-full"
                  src={CUSTOM_QUESTS_IMAGES["Instagram"]}
                  style={{
                    height: `${ICON_SCALE[props.playerQuest.quest.type]}%`,
                    width: `${ICON_SCALE[props.playerQuest.quest.type]}%`,
                  }}
                />
              </Match>
            </Switch>
          </div>
          <div class="flex items-start justify-between">
            <h3 class="eazy-chat text-outline mt-2 text-center text-2xl text-white">
              {props.playerQuest.quest.name}
            </h3>
          </div>
          {QUESTS_THAT_REQUIRE_TIME_TO_VERIFY.includes(
            props.playerQuest.quest.type,
          ) && (
            <div class="mt-1 flex max-w-[300px] flex-col gap-1">
              <p class="text-center text-purple-900">
                Make sure you completed the Quest while our bots Verify
              </p>
            </div>
          )}
          <Button
            class="mx-auto mt-1 w-full bg-[#7952B9] px-4 text-center text-xl"
            style={{
              "-webkit-text-stroke-color": "#6E35A7",
            }}
            onClick={handleVerify}
            disabled={isLoading()}
          >
            <span
              class="eazy-chat mt-1"
              style={{
                "-webkit-text-stroke-color": "#6E35A7",
              }}
            >
              {isLoading() ? "Checking..." : "Check"}
            </span>
          </Button>
          <p
            class="text-black underline"
            onClick={() => {
              doGoQuestAction(props.playerQuest);
            }}
          >
            Did not Join? Click Here
          </p>
        </div>
      </div>
    </div>
  );
}

export function doGoQuestAction(playerQuest: PlayerQuest) {
  const { quest } = playerQuest;

  captureEvent("quest_go_action", {
    questId: quest.id,
    questName: quest.name,
    questType: quest.type,
  });

  switch (quest.type) {
    case "FollowTwitterAccountQuest":
      // @ts-ignore TRUST ME BRO
      const twitterAccount = quest.account;

      openLink(
        isMobile()
          ? `https://x.com/${twitterAccount}`
          : `https://x.com/intent/follow?screen_name=${twitterAccount}`,
      );
      break;
    case "RetweetTweetQuest":
      // @ts-ignore TRUST ME BRO
      const postId = quest.postId;

      openLink(
        // We need to fix types
        // @ts-ignore
        isMobile()
          ? `https://x.com/twitter/status/${postId}`
          : `https://x.com/intent/retweet?tweet_id=${postId}`,
      );
      break;
    case "JoinDiscordServerQuest":
      // @ts-ignore TRUST ME BRO
      const joinUrl = quest.joinUrl;
      openLink(joinUrl);
      break;

    case "JoinTelegramChannelQuest":
      // @ts-ignore TRUST ME BRO
      const channelUsername = quest.channelUsername;
      openTelegramLink(`https://t.me/${channelUsername}`);
      break;
    case "FollowTiktokAccountQuest":
      // @ts-ignore TRUST ME BRO
      const tiktokAccount = quest.account;

      openLink(`https://www.tiktok.com/@${tiktokAccount}`);
      break;
    case "LikeTiktokPostQuest":
      // @ts-ignore TRUST ME BRO
      const tiktokPostId = quest.postId;
      // @ts-ignore TRUST ME BRO
      const tiktokAccountPost = quest.account;

      openLink(
        `https://www.tiktok.com/@${tiktokAccountPost}/video/${tiktokPostId}`,
      );
      break;
    case "YoutubeSubscribeQuest":
      // @ts-ignore TRUST ME BRO
      const channelId = quest.channelId;

      openLink(
        `https://www.youtube.com/channel/${channelId}?sub_confirmation=1`,
      );
      break;
    case "YoutubeContentQuest":
      // @ts-ignore TRUST ME BRO
      const contentId = quest.contentId;
      // @ts-ignore TRUST ME BRO
      const contentType = quest.contentType;

      if (contentType === "Short") {
        openLink(`https://www.youtube.com/shorts/${contentId}`);
      } else {
        openLink(`https://www.youtube.com/watch?v=${contentId}`);
      }
      break;
    case "CustomLinkQuest":
      // @ts-ignore TRUST ME BRO
      const url = quest.url;

      openLink(url);
      break;
    case "InstagramFollowQuest":
      // @ts-ignore TRUST ME BRO
      const instagramAccount = quest.account;

      openLink(`https://www.instagram.com/${instagramAccount}`);
      break;
    case "InstagramPostQuest":
      // @ts-ignore TRUST ME BRO
      const instagramPostId = quest.postId;

      openLink(`https://www.instagram.com/p/${instagramPostId}`);
      break;
    default:
      break;
  }
}

export default function QuestsRoute() {
  const [selectedPlayerQuest, setSelectedPlayerQuest] =
    createSignal<PlayerQuest | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const playerQuestsQuery = createQuery(() => ({
    queryKey: ["activeQuests"],
    queryFn: () =>
      QuestsService.getActiveQuests()
        .then((res) => res as unknown as PlayerQuest[])
        .then((data) => {
          return data.sort((a, b) => {
            if (a.quest.endsAt && !b.quest.endsAt) {
              return -1;
            } else if (!a.quest.endsAt && b.quest.endsAt) {
              return 1;
            }

            if (a.status === "Completed" && b.status !== "Completed") {
              return 1;
            } else if (a.status !== "Completed" && b.status === "Completed") {
              return -1;
            }

            if (a.status === "Verifiable" && b.status !== "Verifiable") {
              return 1;
            } else if (a.status !== "Verifiable" && b.status === "Verifiable") {
              return -1;
            }

            if (a.quest.reward !== b.quest.reward) {
              return b.quest.reward - a.quest.reward;
            }

            return 0;
          });
        }),
  }));
  const connectedSocialsQuery = createQuery(() => ({
    queryKey: ["connectedSocials"],
    queryFn: () => ConnectSocialsService.getConnectedSocials(),
    refetchOnWindowFocus: true,
  }));

  function requiresConnection(questType: QuestType) {
    switch (questType) {
      case "FollowTwitterAccountQuest":
      case "RetweetTweetQuest":
        return connectedSocialsQuery.data?.twitter == false;
      case "JoinDiscordServerQuest":
        return connectedSocialsQuery.data?.discord == false;
      default:
        return false;
    }
  }

  function handleQuestAction(playerQuest: PlayerQuest) {
    const { quest, status } = playerQuest;

    if (status === "Completed") {
      return;
    }

    if (requiresConnection(quest.type)) {
      captureEvent("quest_connect_action", {
        questId: quest.id,
        questName: quest.name,
        questType: quest.type,
      });
      switch (quest.type) {
        case "FollowTwitterAccountQuest":
        case "RetweetTweetQuest":
          openTwitterAuthorizeUrl();
          return;
        case "JoinDiscordServerQuest":
          openDiscordAuthorizeUrl();
          return;
        default:
          return;
      }
    }

    if (playerQuest.status == "Active") {
      // Do GO action, then move the thing to verifiable
      QuestsService.moveQuestToVerifiable({
        questId: quest.id,
      }).then(() => {
        doGoQuestAction(playerQuest);
        playerQuestsQuery.refetch();
      });
      console.log(playerQuest, quest);

      return;
    }

    if (playerQuest.status == "Verifiable") {
      setSelectedPlayerQuest(playerQuest);
      return;
    }
  }

  function questActionText(playerQuest: PlayerQuest) {
    if (requiresConnection(playerQuest.quest.type)) {
      return "Connect";
    }

    switch (playerQuest.status) {
      case "Active":
        return "GO";
      case "Completed":
        return "Completed";
      case "Verifiable":
        return "Check";
    }
  }

  createEffect(() => {
    if (searchParams.questId && playerQuestsQuery.data) {
      const quest = playerQuestsQuery.data.find(
        (q: PlayerQuest) => q.quest.id === searchParams.questId,
      );
      if (quest) {
        setSelectedPlayerQuest(quest);
        setSearchParams({
          questId: undefined,
        });
      }
    }
  });

  return (
    <PageContainer hideShadowInnerBottom>
      <div class="relative flex h-full w-full flex-col">
        <div class="sticky top-0 z-10 flex flex-col items-center justify-center bg-[#7952B9] px-3 py-4 text-center">
          <CloseButton />
          <h2 class="eazy-chat text-outline-1 text-4xl font-bold text-white">
            Quests
          </h2>
          <div class="flex items-center">
            <div class="relative flex h-11 w-11 items-center justify-center rounded-full border-[2px] border-[#4C1C9A] bg-[#9060DD]">
              <img src={shardIcon} class="h-8 w-8 object-contain" />
            </div>
            <div class="-ml-5 rounded-xl bg-[#4C1C9A] px-5 py-1 pl-8">
              <span class="eazy-chat text-outline mt-2 text-xl text-white">
                {showOneDecimal(playerStore.shards)}
              </span>
            </div>
          </div>
        </div>
        {selectedPlayerQuest() ? (
          <QuestVerificationView
            playerQuest={selectedPlayerQuest()!}
            clearSelectedPlayerQuest={() => setSelectedPlayerQuest(null)}
            playerQuestsRefetch={playerQuestsQuery.refetch}
          />
        ) : (
          <div class="flex flex-col gap-2 overflow-y-auto p-2 pb-10 pt-4">
            <For each={playerQuestsQuery.data}>
              {(playerQuest) => (
                <Card
                  class={cn(
                    "rounded-full border border-[#E2A0A0] bg-[#FFD0C5]",
                    playerQuest.status === "Completed" &&
                      "border-[#4D8C57] bg-[#95D57E]",
                  )}
                >
                  <CardContent class="flex items-center gap-2 p-2">
                    <div
                      class={cn(
                        "relative flex h-12 w-12 items-center justify-center rounded-full",
                        playerQuest.status === "Completed" && "opacity-60",
                      )}
                      style={{
                        "background-color":
                          ICON_BG_COLORS[playerQuest.quest.type],
                      }}
                    >
                      <Switch
                        fallback={
                          <Dynamic
                            component={ICONS[playerQuest.quest.type]}
                            class="object-contain"
                            style={{
                              height: `${ICON_SCALE[playerQuest.quest.type]}%`,
                              width: `${ICON_SCALE[playerQuest.quest.type]}%`,
                            }}
                          />
                        }
                      >
                        <Match
                          when={playerQuest.quest.type === "CustomLinkQuest"}
                        >
                          <img
                            class="rounded-full"
                            src={
                              // @ts-ignore TRUST ME BRO
                              CUSTOM_QUESTS_IMAGES[playerQuest.quest.iconId]
                            }
                            style={{
                              height: `${ICON_SCALE[playerQuest.quest.type]}%`,
                              width: `${ICON_SCALE[playerQuest.quest.type]}%`,
                            }}
                          />
                        </Match>
                        <Match
                          when={
                            playerQuest.quest.type === "InstagramFollowQuest" ||
                            playerQuest.quest.type === "InstagramPostQuest"
                          }
                        >
                          <img
                            class="rounded-full"
                            src={CUSTOM_QUESTS_IMAGES["Instagram"]}
                            style={{
                              height: `${ICON_SCALE[playerQuest.quest.type]}%`,
                              width: `${ICON_SCALE[playerQuest.quest.type]}%`,
                            }}
                          />
                        </Match>
                      </Switch>
                    </div>
                    <div
                      class={cn(
                        "flex max-w-44 flex-1 flex-col",
                        playerQuest.status === "Completed" && "opacity-60",
                      )}
                    >
                      <div class="flex items-center">
                        <p class="eazy-chat text-outline truncate text-[15px] leading-4 text-white">
                          {playerQuest.quest.name}
                        </p>
                      </div>
                      <div class="flex">
                        <div class="mt-2 flex w-fit items-center gap-1 rounded-full border border-[#6E35A7] bg-[#AF88EE] px-2">
                          <img src={shardIcon} class="h-4 w-4" />
                          <p class="eazy-chat text-outline mt-1 text-base leading-4 text-white">
                            +{playerQuest.quest.reward}
                          </p>
                        </div>
                        <Show when={playerQuest.quest.endsAt}>
                          <div class="ml-2 mt-2 flex w-fit items-center gap-1 rounded-full border border-[#961B00] bg-red-400 px-2">
                            <img src={hourglassImage} class="h-4 w-3" />
                            <p class="eazy-chat text-outline mt-1 text-base leading-4 text-white">
                              {createCountdownTimer(
                                playerQuest.quest.endsAt?.toUTCString() ?? "",
                              )()}
                            </p>
                          </div>
                        </Show>
                      </div>
                    </div>
                    <div class="ml-auto">
                      <Button
                        disabled={
                          playerQuest.status == "Completed" ||
                          playerQuestsQuery.isFetching
                        }
                        size="lg"
                        class="eazy-chat w-24 rounded-full border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] px-4 disabled:opacity-100"
                        style={
                          playerQuest.status == "Completed"
                            ? {
                                background: "#43B64F",
                                "border-color": "#387C53",
                              }
                            : undefined
                        }
                        onClick={[handleQuestAction, playerQuest]}
                      >
                        {playerQuest.status === "Completed" ? (
                          <img src={checkImg} class="h-8 w-8 object-contain" />
                        ) : (
                          <span
                            class="text-outline mt-1 text-xl text-white"
                            style={{
                              "-webkit-text-stroke-color": "#B04831",
                            }}
                          >
                            {questActionText(playerQuest)}
                          </span>
                        )}
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              )}
            </For>
          </div>
        )}
      </div>
    </PageContainer>
  );
}
