import { Accessor, createEffect, createSignal } from "solid-js";

export function createAutoRefreshValue<T>(
  accessor: Accessor<T>,
  refreshInterval = 1000,
): Accessor<T> {
  const [value, setValue] = createSignal(accessor());

  createEffect(() => {
    setValue(accessor);
  });

  setInterval(() => setValue(accessor), refreshInterval);

  return value;
}
