import { Engine, Loader, Color, Vector } from "excalibur";
import pastureSrc from "@/assets/loader/pasture.png";
import logoSrc from "@/assets/loader/loading_screen_logo.webp";
import infoTopSrc from "@/assets/loader/info-top.png";
import infoBottomSrc from "@/assets/loader/info-bottom.png";

export class CustomLoader extends Loader {
  private _loaderElement: HTMLDivElement;
  private _logoContainer: HTMLDivElement;
  private _infoTopContainer: HTMLDivElement;
  private _infoBottomContainer: HTMLDivElement;
  private _progressBarContainer: HTMLDivElement;
  private _progressBarBackground: HTMLDivElement;
  private _progressBarFill: HTMLDivElement;

  constructor() {
    super();

    this._loaderElement = document.createElement("div");
    this._loaderElement.style.position = "fixed";
    this._loaderElement.style.top = "0";
    this._loaderElement.style.left = "0";
    this._loaderElement.style.width = "100%";
    this._loaderElement.style.height = "100%";
    this._loaderElement.style.zIndex = "1000";

    // Create background image
    this._loaderElement.style.backgroundImage = `url(${pastureSrc})`;
    this._loaderElement.style.backgroundPosition = "center bottom";
    this._loaderElement.style.backgroundRepeat = "no-repeat";
    this._loaderElement.style.backgroundSize = "cover";

    // Create logo image
    this._logoContainer = document.createElement("div");
    this._logoContainer.style.backgroundImage = `url(${logoSrc})`;
    this._logoContainer.style.backgroundPosition = "center top";
    this._logoContainer.style.backgroundRepeat = "no-repeat";
    this._logoContainer.style.backgroundSize = "cover";
    this._logoContainer.style.position = "absolute";
    this._logoContainer.style.top = "0";
    this._logoContainer.style.left = "50%";
    this._logoContainer.style.transform = "translateX(-50%)";
    this._logoContainer.style.width = "100vw";
    this._logoContainer.style.height = "100vh";

    this._infoTopContainer = document.createElement("div");
    this._infoTopContainer.style.backgroundImage = `url(${infoTopSrc})`;
    this._infoTopContainer.style.backgroundPosition = "center top";
    this._infoTopContainer.style.backgroundRepeat = "no-repeat";
    this._infoTopContainer.style.backgroundSize = "contain";
    this._infoTopContainer.style.position = "absolute";
    this._infoTopContainer.style.top = "0%";
    this._infoTopContainer.style.left = "50%";
    this._infoTopContainer.style.transform = "translateX(-50%)";
    this._infoTopContainer.style.width = "100vw";
    this._infoTopContainer.style.height = "100vh";

    this._infoBottomContainer = document.createElement("div");
    this._infoBottomContainer.style.backgroundImage = `url(${infoBottomSrc})`;
    this._infoBottomContainer.style.backgroundPosition = "center bottom";
    this._infoBottomContainer.style.backgroundRepeat = "no-repeat";
    this._infoBottomContainer.style.backgroundSize = "contain";
    this._infoBottomContainer.style.position = "absolute";
    this._infoBottomContainer.style.bottom = "0%";
    this._infoBottomContainer.style.left = "50%";
    this._infoBottomContainer.style.transform = "translateX(-50%)";
    this._infoBottomContainer.style.width = "100vw";
    this._infoBottomContainer.style.height = "100vh";

    // Create progress bar container
    this._progressBarContainer = document.createElement("div");
    this._progressBarContainer.style.position = "absolute";
    this._progressBarContainer.style.bottom = "43%";
    this._progressBarContainer.style.left = "50%";
    this._progressBarContainer.style.transform = "translateX(-50%)";
    this._progressBarContainer.style.width = "70%";
    this._progressBarContainer.style.margin = "auto";
    this._progressBarContainer.style.height = "40px"; // Increased height
    this._progressBarContainer.style.backgroundColor = "#FAC55E";
    this._progressBarContainer.style.borderRadius = "20px"; // Increased border radius
    this._progressBarContainer.style.border = "2px solid black"; // Added black border
    this._progressBarContainer.style.padding = "3px"; // Added padding
    this._progressBarContainer.style.boxSizing = "border-box"; // Ensure padding doesn't affect overall size

    // Create black background for progress bar
    this._progressBarBackground = document.createElement("div");
    this._progressBarBackground.style.width = "100%";
    this._progressBarBackground.style.height = "100%";
    this._progressBarBackground.style.backgroundColor = "black";
    this._progressBarBackground.style.borderRadius = "15px";
    this._progressBarBackground.style.border = "2px solid black";

    // Create progress bar fill
    this._progressBarFill = document.createElement("div");
    this._progressBarFill.style.width = "0%";
    this._progressBarFill.style.height = "100%";
    this._progressBarFill.style.background =
      "linear-gradient(to right, #11bf4b, #89f72f)";
    this._progressBarFill.style.borderRadius = "15px"; // Slightly smaller than container
    this._progressBarFill.style.transition = "width 0.3s ease-in-out";

    // Append elements
    this._progressBarBackground.appendChild(this._progressBarFill);
    this._progressBarContainer.appendChild(this._progressBarBackground);
    this._loaderElement.appendChild(this._logoContainer);
    this._loaderElement.appendChild(this._infoTopContainer);
    this._loaderElement.appendChild(this._infoBottomContainer);
    this._loaderElement.appendChild(this._progressBarContainer);

    // Append the loader container to the body
    document.body.appendChild(this._loaderElement);
  }

  public override onDraw(ctx: CanvasRenderingContext2D) {
    const progress = this.progress;
    this._progressBarFill.style.width = `${progress * 100}%`;
  }

  public override dispose() {
    if (this._loaderElement && this._loaderElement.parentElement) {
      this._loaderElement.parentElement.removeChild(this._loaderElement);
    }

    if (this.engine) {
      this.engine.canvas.style.display = "block";
    }

    super.dispose();
  }

  public override async onAfterLoad(): Promise<void> {
    const playButton = document.createElement("button");
    playButton.textContent = "Play!";
    playButton.style.position = "absolute";
    playButton.style.bottom = "43%";
    playButton.style.left = "50%";
    playButton.style.transform = "translateX(-50%)";
    playButton.style.width = "70%";
    playButton.style.boxSizing = "border-box";
    playButton.style.fontSize = "28px";
    playButton.style.letterSpacing = "-1px";
    playButton.style.background =
      "linear-gradient(180deg, #FCB74F 38%, #FA9044 102.17%)";
    playButton.style.paddingTop = "5px";
    playButton.style.border = "2px solid #B04831";
    playButton.style.borderRadius = "50px";
    playButton.style.cursor = "pointer";
    playButton.style.fontFamily = "Eazy Chat";
    playButton.style.color = "white";
    playButton.style.fontWeight = "bold";
    playButton.style.letterSpacing = "1px";
    playButton.style.transition = "background-color 0.3s ease-in-out";
    playButton.style.pointerEvents = "auto";
    playButton.style.verticalAlign = "middle";
    playButton.style.webkitTextStrokeWidth = "0.5px";
    playButton.style.webkitTextStrokeColor = "#FA9044";

    playButton.addEventListener("click", () => {
      this.dispose();
    });
    this._progressBarContainer.remove();
    this._loaderElement.appendChild(playButton);
  }
}
