import { Modal, ModalDescription } from "./ui/modal";
import { Button } from "./ui/button";
import { For, Show, Suspense, createEffect, createSignal } from "solid-js";
import { cn, formatCompactNumber } from "@/lib/utils";
import { checkImg, shardIcon } from "@/assets/images";
import { RiveAnimation } from "./RiveAnimation";
import { eventBus } from "./event-bus";
import { createQuery } from "@tanstack/solid-query";
import { captureEvent } from "@/lib/analytics";
import Loading from "./Loading";
import { refreshPlayerState } from "./player-store";
import createCountdownTimer from "./lib/countdown-timer";
import { DailyStreakService, DailyStreakState } from "@/api-client";

function getMultiplesOf12(num: number): number[] {
  let start = Math.floor(num / 12) * 12;
  return Array.from({ length: 12 }, (_, i) => start + i);
}

function calculateBonus(streakDay: number) {
  switch (streakDay) {
    case 0:
      return 25; // Day 1
    case 1:
      return 50; // Day 2
    case 2:
      return 75; // Day 3
    case 3:
      return 100; // Day 4
    case 4:
      return 125; // Day 5
    case 5:
      return 150; // Day 6
    case 6:
      return 175; // Day 7
    case 7:
      return 200; // Day 8
    case 8:
      return 200; // Day 9
    case 9:
      return 200; // Day 10
    case 10:
      return 200; // Day 11
    case 11:
      return 200; // Day 12
    default:
      return 200; // Max bonus for any day beyond 12
  }
}

function getDayStatus(
  streakDay: number,
  currentStreak: number,
  isTodayClaimed = false,
) {
  if (isTodayClaimed) {
    return "claimed";
  } else if (streakDay < currentStreak) {
    return "claimed";
  } else if (streakDay === currentStreak) {
    return "readyToClaim";
  } else {
    return "locked";
  }
}

const BG_COLORS = {
  claimed: "#95D57E",
  readyToClaim: "#FCB74F",
  locked: "#FFD0C5",
};

const BORDER_COLORS = {
  claimed: "#4DB24B",
  readyToClaim: "#CC7B4D",
  locked: "#E2A0A0",
};

function DailyCard(props: {
  day: number;
  goToGiftView: () => void;
  dailyStreakState: DailyStreakState;
}) {
  const status = () =>
    getDayStatus(
      props.day,
      props.dailyStreakState.currentStreak,
      props.dailyStreakState.isTodayClaimed &&
        props.day === props.dailyStreakState.currentStreak,
    );
  const isClaimed = () => status() === "claimed";
  const isReadyToClaim = () => status() === "readyToClaim";
  const isLocked = () => status() === "locked";

  const handleClick = () => {
    if (isReadyToClaim()) {
      props.goToGiftView();
    }
  };

  const cardHeader = () => {
    if (isReadyToClaim()) {
      return (
        <div
          class="absolute -top-5 rounded-xl border border-[#B04831] px-2"
          style={{
            background: "linear-gradient(180deg, #FCB74F 38.5%, #FA9044 100%)",
          }}
        >
          <span class="eazy-chat text-outline text-xs text-white">Claim</span>
        </div>
      );
    } else {
      return (
        <div
          class={cn(
            "absolute -top-5 rounded-xl bg-[#4D8C57] px-2",
            isLocked() && "bg-[#BD7272]",
          )}
        >
          <span class="eazy-chat text-outline text-xs text-white">
            Day {props.day + 1}
          </span>
        </div>
      );
    }
  };

  return (
    <div
      class="relative flex h-16 w-16 items-center justify-center rounded-xl border"
      style={{
        "background-color": BG_COLORS[status()],
        "border-color": BORDER_COLORS[status()],
      }}
      onClick={handleClick}
    >
      {cardHeader()}
      <div
        class={cn(
          "flex h-12 w-12 items-center justify-center opacity-55",
          isReadyToClaim() && "opacity-100",
        )}
        style={{
          "background-image": `url(${shardIcon})`,
          "background-size": "70%",
          "background-position": "center",
          "background-repeat": "no-repeat",
        }}
      >
        <p class="eazy-chat text-outline mt-1 text-center text-lg text-white">
          +{formatCompactNumber(calculateBonus(props.day))}
        </p>
      </div>
      <Show when={isClaimed()}>
        <div class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <img src={checkImg} class="h-10 w-10 object-contain" />
        </div>
      </Show>
    </div>
  );
}

function DaysContent(props: {
  closeModal: () => void;
  goToGiftView: () => void;
  dailyStreakState: DailyStreakState;
}) {
  let midnight = new Date(Date.now());
  midnight.setUTCHours(24, 0, 0, 0);

  return (
    <div class="relative flex flex-col justify-center rounded-xl bg-[#FFE7DF]">
      <div class="absolute -right-3 -top-3">
        <Button
          variant="ghost"
          class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
          onClick={props.closeModal}
        >
          <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
        </Button>
      </div>
      <div class="flex items-center justify-center rounded-t-xl bg-[#476EA9] px-2 py-3 text-center">
        <h3 class="eazy-chat text-outline text-2xl text-white">
          DAILY STREAK BONUS
        </h3>
      </div>
      <Show when={!props.dailyStreakState.isTodayClaimed}>
        <div class="mx-2 mt-2 rounded-md bg-[#BD7272] pt-1 text-center text-white">
          <p class="text-sm font-bold text-[#ffdede]">Time remaining:</p>
          <h4 class="eazy-chat text-outline text-2xl">
            {createCountdownTimer(midnight.toString())()}
          </h4>
        </div>
      </Show>
      <div class="p-1">
        <p class="eazy-chat text-center text-lg tracking-wider text-[#794343]">
          Come back multiple days in a row to unlock more rewards!
        </p>
        <div class="mb-1 mt-5 grid grid-cols-4 place-items-center gap-1 gap-y-6">
          <For each={getMultiplesOf12(props.dailyStreakState.currentStreak)}>
            {(day) => (
              <DailyCard
                day={day}
                goToGiftView={props.goToGiftView}
                dailyStreakState={props.dailyStreakState}
              />
            )}
          </For>
        </div>
      </div>
    </div>
  );
}

export function DailyStreakModal(props: {
  isModalOpen: () => boolean;
  setIsModalOpen: (value: boolean) => void;
  closeModal: () => void;
}) {
  const [view, setView] = createSignal<"days" | "gift">("days");
  const dailyStreakState = createQuery(() => ({
    queryKey: ["dailyStreakState"],
    queryFn: () => DailyStreakService.getState(),
  }));

  createEffect(() => {
    console.log(dailyStreakState?.data);
  });

  const goToGiftView = () => {
    setView("gift");
  };

  const onClaim = () => {
    DailyStreakService.claimDailyBonus().then(() => {
      captureEvent("daily_streak_claimed", {
        currentStreak: dailyStreakState.data?.currentStreak || 0,
      });
      props.closeModal();
      eventBus.emit(
        "dailyStreakClaimed",
        calculateBonus(dailyStreakState.data!.currentStreak),
      );
      dailyStreakState.refetch();
      refreshPlayerState();
    });
  };

  return (
    <Modal open={props.isModalOpen()} onOpenChange={props.setIsModalOpen}>
      <ModalDescription>
        <Suspense fallback={<Loading />}>
          <Show when={view() === "days" && dailyStreakState.data}>
            <DaysContent
              closeModal={props.closeModal}
              goToGiftView={goToGiftView}
              dailyStreakState={dailyStreakState.data!}
            />
          </Show>
        </Suspense>
      </ModalDescription>
      <Show when={view() === "gift"}>
        <div
          style={{
            width: "100vw",
            height: "calc(100vw * 1.5)",
            top: "50%",
            left: 0,
            right: 0,
            bottom: 0,
            transform: "translateY(-50%)",
            position: "fixed",
          }}
        >
          <RiveAnimation
            coinsString={formatCompactNumber(
              calculateBonus(dailyStreakState.data?.currentStreak ?? 0),
            ).toString()}
            onClaim={onClaim}
          />
        </div>
      </Show>
    </Modal>
  );
}
