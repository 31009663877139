import { Game } from "@/game/game.ts";
import {
  Accessor,
  createContext,
  createSignal,
  onCleanup,
  onMount,
  useContext,
  createResource,
  Show,
} from "solid-js";

const GameContext = createContext<Accessor<Game | undefined>>();

export function GameProvider(props: { children: any }) {
  const [game, setGame] = createSignal<Game | undefined>(undefined);

  onMount(() => {
    setGame(new Game());
  });

  onCleanup(() => {
    game()?.dispose();
  });

  return (
    <div class="relative mx-auto h-full max-w-xl overflow-y-hidden">
      <GameContext.Provider value={game}>
        <Show when={game()}>{props.children}</Show>
      </GameContext.Provider>
      <canvas id="game" />
    </div>
  );
}

export function useGame() {
  const game = useContext(GameContext);

  if (!game) {
    throw new Error("useGame must be used within a GameProvider");
  }

  return game;
}
