import { createEffect, onMount } from "solid-js"
import { useGame } from "@/components/GameProvider.tsx";
import { playerStore } from "@/components/player-store";

export default function MatchRoute() {
  const game = useGame();
  game()!.goToScene("levelSelection");

  return (
    <div></div>
  )
}