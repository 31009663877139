import enclosure_music from "./enclosure_music.webp";
import comfort_food from "./comfort_food.webp";
import drone_station from "./drone_station.webp";
import ancient_nexus from "./ancient_nexus.webp";
import ancient_interface from "./ancient_interface.webp";
import otiums_array from "./otiums_array.webp";
import beast_groomer from "./beast_groomer.webp";
import ancient_resonator from "./ancient_resonator.webp";
import nutrient_boost from "./nutrient_boost.webp";
import wind_turbines from "./wind_turbines.webp";
import ancient_conduit from "./ancient_conduit.webp";
import solar_panels from "./solar_panels.webp";
import health_checkups from "./health_checkups.webp";
import barn_upgrade from "./barn_upgrade.webp";
import incubation_tanks from "./incubation_tanks.webp";
import compost_system from "./compost_system.webp";
import otiums_extractor from "./otiums_extractor.webp";
import massage_therapy from "./massage_therapy.webp";
import soothing_baths from "./soothing_baths.webp";
import ancient_amplifier from "./ancient_amplifier.webp";
import otiums_converter from "./otiums_converter.webp";
import otiums_holo_projector from "./otiums_holo_projector.webp";
import enrichment_toys from "./enrichment_toys.webp";
import play_area from "./play_area.webp";
import ancient_beacon from "./ancient_beacon.webp";
import energy_grid from "./energy_grid.webp";
import training_sessions from "./training_sessions.webp";
import biodiversity_area from "./biodiversity_area.webp";
import otiums_crystal_drive from "./otiums_crystal_drive.webp";
import vitamin_infusions from "./vitamin_infusions.webp";
import otiums_synthesizer from "./otiums_synthesizer.webp";
import energy_harvesters from "./energy_harvesters.webp";
import relaxation_pods from "./relaxation_pods.webp";
import herbal_treatments from "./herbal_treatments.webp";
import silo_storage from "./silo_storage.webp";
import bio_dome from "./bio_dome.webp";
import otiums_scanner from "./otiums_scanner.webp";
import shard_hopper from "./shard_hopper.webp";
import enhanced_nest from "./enhanced_nest.webp";
import smart_sensors from "./smart_sensors.webp";
import ancient_engine from "./ancient_engine.webp";
import climate_control from "./climate_control.webp";
import exercise_yard from "./exercise_yard.webp";
import resonant_fencing from "./resonant_fencing.webp";
import ancient_regulator from "./ancient_regulator.webp";
import beast_visits from "./beast_visits.webp";
import otiums_obelisk from "./otiums_obelisk.webp";
import organic_fertilizer from "./organic_fertilizer.webp";

export const UPGRADE_IMAGES = {
  enclosure_music: enclosure_music,
  comfort_food: comfort_food,
  drone_station: drone_station,
  ancient_nexus: ancient_nexus,
  ancient_interface: ancient_interface,
  otiums_array: otiums_array,
  beast_groomer: beast_groomer,
  ancient_resonator: ancient_resonator,
  nutrient_boost: nutrient_boost,
  wind_turbines: wind_turbines,
  ancient_conduit: ancient_conduit,
  solar_panels: solar_panels,
  health_checkups: health_checkups,
  barn_upgrade: barn_upgrade,
  incubation_tanks: incubation_tanks,
  compost_system: compost_system,
  otiums_extractor: otiums_extractor,
  massage_therapy: massage_therapy,
  soothing_baths: soothing_baths,
  ancient_amplifier: ancient_amplifier,
  otiums_converter: otiums_converter,
  otiums_holo_projector: otiums_holo_projector,
  enrichment_toys: enrichment_toys,
  play_area: play_area,
  ancient_beacon: ancient_beacon,
  energy_grid: energy_grid,
  training_sessions: training_sessions,
  biodiversity_area: biodiversity_area,
  otiums_crystal_drive: otiums_crystal_drive,
  vitamin_infusions: vitamin_infusions,
  otiums_synthesizer: otiums_synthesizer,
  energy_harvesters: energy_harvesters,
  relaxation_pods: relaxation_pods,
  herbal_treatments: herbal_treatments,
  silo_storage: silo_storage,
  bio_dome: bio_dome,
  otiums_scanner: otiums_scanner,
  shard_hopper: shard_hopper,
  enhanced_nest: enhanced_nest,
  smart_sensors: smart_sensors,
  ancient_engine: ancient_engine,
  climate_control: climate_control,
  exercise_yard: exercise_yard,
  resonant_fencing: resonant_fencing,
  ancient_regulator: ancient_regulator,
  beast_visits: beast_visits,
  otiums_obelisk: otiums_obelisk,
  organic_fertilizer: organic_fertilizer,
};
