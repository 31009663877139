import { PageContainer } from "@/components/PageContainer";
import { useIsRouting } from "@solidjs/router";
import "../input-range.css";
import { CloseButton } from "@/components/CloseButton";
import { Show } from "solid-js";
import {
  settingsStore,
  settingsStoreActions,
} from "@/components/settings-store";

export default function SettingsRoute() {
  const isRouting = useIsRouting();

  return (
    <PageContainer>
      <div class="relative flex h-full w-full flex-col">
        <div class="sticky top-0 z-10 flex items-center justify-center gap-4 border border-[#561212] bg-gradient-to-b from-[#4C80CE] to-[#3854BC] px-3 py-4 text-center">
          <CloseButton />
          <h2 class="eazy-chat text-outline-1 mt-1 text-4xl font-bold text-white">
            Settings
          </h2>
        </div>
        <Show when={!isRouting()} fallback={<div>LOADINGGGG</div>}>
          <div class="flex flex-col gap-4 overflow-y-auto px-5 py-3 pb-10">
            <div>
              <h2 class="eazy-chat text-outline text-xl tracking-widest text-white">
                Music
              </h2>
              <input
                type="range"
                value={settingsStore.bgMusicVolume * 100}
                onChange={(e) => {
                  settingsStoreActions.changeBgMusicVolume(
                    parseInt(e.currentTarget.value) / 100,
                  );
                }}
              />
            </div>
            <div>
              <h2 class="eazy-chat text-outline text-xl tracking-widest text-white">
                Sound Effects
              </h2>
              <input
                type="range"
                value={settingsStore.sfxVolume * 100}
                onChange={(e) => {
                  settingsStoreActions.changeSfxVolume(
                    parseInt(e.currentTarget.value) / 100,
                  );
                }}
              />
            </div>
          </div>
        </Show>
      </div>
    </PageContainer>
  );
}
