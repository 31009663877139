import {
  Engine,
  Actor,
  vec,
  SpriteSheet,
  RotationType,
  ParticleEmitter,
  Color,
  Vector,
  EmitterType,
  Timer,
  Animation,
  range,
  AnimationStrategy,
  EasingFunctions,
} from "excalibur";

import { eventBus } from "@/components/event-bus";
import { Config } from "../config";
import { vibrate } from "@/lib/utils.ts";
import {
  OnboardingStep,
  onboardingActions,
  onboardingSelectors,
} from "@/components/onboarding-store";
import { OnboardingArrowDown } from "./onboarding-arrow-down";
import { createEffect } from "solid-js";
import { Onboarding } from "../scenes/onboarding";
import { OnboardingBeast } from "./onboarding-beast";
import { outline } from "../materials/outline";

export class OnboardingEgg extends Actor {
  beastId: number;
  tapstoHatch: number = 10;
  tapsCount: number = 0;
  spritesheet?: SpriteSheet;
  hatchAnim?: Animation;
  emitter?: ParticleEmitter;
  arrow?: OnboardingArrowDown;
  outlineMaterial: ex.Material | null = null;

  constructor(beastId: number) {
    super();
    this.beastId = beastId;

    this.pointer.useGraphicsBounds = true;

    createEffect(() => {
      if (onboardingSelectors.currentStep() === OnboardingStep.TappingEgg) {
        this.showArrow();
        this.showOutline();
      } else if (
        onboardingSelectors.currentStep() > OnboardingStep.TappingEgg
      ) {
        this.hideArrow();
      }
    });
  }

  public onInitialize(engine: Engine<any>): void {
    const viewport = engine.screen.getScreenBounds();
    let scale = Config.scale;
    let scene = this.scene as Onboarding;
    let onboardingResources = scene.onboardingResources;

    let image = onboardingResources!.EggBlue;
    switch (this.beastId) {
      case 211:
        image = onboardingResources!.EggGreen;
        break;
      case 219:
        image = onboardingResources!.EggYellow;
        break;
    }

    let hatchImage = onboardingResources!.EggBlueHatch;
    switch (this.beastId) {
      case 211:
        hatchImage = onboardingResources!.EggGreenHatch;
        break;
      case 219:
        hatchImage = onboardingResources!.EggYellowHatch;
        break;
    }

    this.name = "egg";
    this.pos.x = viewport.width / 2;
    this.pos.y = viewport.height - 300;
    this.anchor = vec(0.5, 1);
    this.scale = vec(scale, scale);

    this.spritesheet = SpriteSheet.fromImageSource({
      image: image,
      grid: {
        rows: 1,
        columns: 10,
        spriteWidth: 37,
        spriteHeight: 43,
      },
    });
    this.graphics.use(this.spritesheet.sprites[0]);

    let hatchSpritesheet = SpriteSheet.fromImageSource({
      image: hatchImage,
      grid: {
        rows: 4,
        columns: 8,
        spriteWidth: 161,
        spriteHeight: 153,
      },
    });
    this.hatchAnim = Animation.fromSpriteSheet(
      hatchSpritesheet,
      range(0, 31),
      70,
    );
    this.hatchAnim.strategy = AnimationStrategy.End;
    this.hatchAnim.events.on("end", (a) => {
      onboardingActions.moveToNextStep();
      eventBus.emit("beastHatchedOnboarding");
    });

    const arrow = new OnboardingArrowDown(this.pos.x, this.pos.y - 240);
    this.arrow = arrow;
    this.scene?.add(this.arrow);

    this.emitter = new ParticleEmitter({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      emitterType: EmitterType.Circle,
      radius: 28,
      isEmitting: false,
      emitRate: 20,
      particle: {
        opacity: 1,
        fade: true,
        life: 1000,
        minVel: 30,
        maxVel: 60,
        minAngle: 0,
        maxAngle: 6.2,
        maxSize: 12,
        minSize: 4,
        startSize: 0,
        endSize: 0,
        acc: vec(0, 250),
        beginColor: Color.Transparent,
        endColor: Color.Transparent,
      },
    });

    this.emitter.scale = vec(scale, scale);
    this.scene?.add(this.emitter);

    const outlineMaterial = engine.graphicsContext.createMaterial({
      name: "outline",
      fragmentSource: outline,
    });
    this.outlineMaterial = outlineMaterial;

    this.on("pointerdown", (event) => {
      if (this.tapsCount < this.tapstoHatch) {
        this.tapsCount += 1;
      }
      this.scene?.camera.shake(10, 10, 10);
      this.wiggle(this.tapsCount / 2);
      vibrate("light");
      onboardingResources!.EggCrack.play();
      if (this.spritesheet) {
        this.graphics.use(this.spritesheet.sprites[this.tapsCount]);
      }
      if (this.emitter) {
        this.emitter.pos = event.worldPos.clone();
        this.emitter.isEmitting = true;
        const timer = new Timer({
          fcn: () => {
            if (this.emitter) this.emitter.isEmitting = false;
          },
          repeats: false,
          interval: 200,
        });
        this.scene?.add(timer);
        timer.start();
      }

      console.log(`egg tap ${this.tapsCount}`);
      // Remove outline material
      if (this.graphics.material) {
        this.graphics.material = null;
        console.log("Remove outline material");
      }

      if (this.tapsCount >= this.tapstoHatch) {
        if (this.hatchAnim) {
          if (onboardingSelectors.currentStep() === OnboardingStep.TappingEgg) {
            onboardingActions.moveToNextStep();
          }
          this.graphics.use(this.hatchAnim);
          this.anchor = vec(0.5, 0.7);
          onboardingResources!.EggWhoosh.play();
          this.hatchAnim.events.on("frame", (frame) => {
            if (frame.frameIndex == 15) {
              onboardingResources!.EggExplosion.play();
            }
            if (frame.frameIndex == 16) {
              let scene = this.scene as Onboarding;
              scene.beast = new OnboardingBeast(scene.beastId!);
              scene.add(scene.beast);
              scene.beast.actions
                .moveBy(vec(0, -40), 400)
                .easeBy(vec(0, 40), 200, EasingFunctions.EaseInCubic);
            }
          });
        }
      }
    });
  }

  private wiggle(speed: number = 1) {
    this.actions
      .rotateTo(Math.PI / 16, Math.PI + speed, RotationType.Clockwise)
      .rotateTo(-Math.PI / 16, Math.PI + speed, RotationType.CounterClockwise)
      .rotateTo(0, Math.PI, RotationType.Clockwise);
  }

  public showArrow() {
    if (this.arrow !== undefined) {
      this.scene?.add(this.arrow);
    }
  }

  public hideArrow() {
    if (this.arrow) {
      this.arrow.kill();
      this.arrow = undefined;
    }
  }

  public showOutline() {
    if (this.graphics.material === null) {
      this.graphics.material = this.outlineMaterial;
    }
  }

  public hideOutline() {
    if (this.graphics.material) {
      this.graphics.material = null;
    }
  }
}
