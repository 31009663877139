import { createStore } from "solid-js/store";
import { Player, StatusEffectType } from "@/components/types.ts";
import { eventBus } from "@/components/event-bus.ts";
import connection from "@/components/connection.ts";
import { playSfxSound } from "@/components/sounds.ts";
import { makeSnapshotValue } from "@/lib/utils.ts";
import {
  calculateHappiness,
  calculateHappinessDepletesAt,
} from "@/components/lib/happiness.ts";
import {
  calculateCoinsBalance,
  calculateCoinsEarningRate,
} from "@/components/lib/coins.ts";
import { calculateEnergy } from "@/components/lib/energy.ts";
import {
  getCoinLevelReward,
  getExperienceRequirement,
} from "@/components/lib/levelling.ts";
import { gameConfig } from "@/components/lib/game-config.ts";
import { addClickAnalyticsToStore, captureEvent } from "@/lib/analytics";
import {
  addClickToStore,
  clicksStore,
  MAX_CLICKS_PER_SECOND,
} from "@/lib/feed-mine-clicks";
import { GameService } from "@/api-client";

export const [playerStore, setPlayerStore] = createStore({} as Player);

export const playerStoreSelector = {
  hasEffect: (type: StatusEffectType) => {
    console.log(playerStore.statusEffects);
    return playerStore.statusEffects.some((effect) => effect.type === type);
  },
  beastHappiness: () => calculateHappiness(playerStore.beast.happinessSnapshot),
  energy: () => calculateEnergy(playerStore.energySnapshot),
  coins: () =>
    calculateCoinsBalance(
      playerStore.coinsSnapshot,
      playerStore.coinsEarningRate,
      calculateHappinessDepletesAt(playerStore.beast.happinessSnapshot),
    ),
  coinsEarningRate: () =>
    calculateCoinsEarningRate(
      playerStore.coinsEarningRate,
      calculateHappinessDepletesAt(playerStore.beast.happinessSnapshot),
    ),
};

function handleMining() {
  playSfxSound("mine");
  const energy = playerStoreSelector.energy();

  const unlimitedEnergyEffect = playerStoreSelector.hasEffect(
    "UnlimitedEnergyEffect",
  );

  if (clicksStore.mineClicks > MAX_CLICKS_PER_SECOND) {
    return;
  }

  const canMine = energy >= 1 || unlimitedEnergyEffect;
  const shardsChange = canMine ? 1 : 0;
  if (canMine) {
    addClickAnalyticsToStore("mineClicksAnalytics");
    if (energy === 1) {
      // captureEvent("no_energy");
    }
    addClickToStore("mineClicks");

    const energyChange = unlimitedEnergyEffect ? 0 : 1;
    setPlayerStore({
      energySnapshot: makeSnapshotValue(Math.max(energy - energyChange, 0)),
      shards: playerStore.shards + shardsChange,
    });
  } else {
    playSfxSound("noShards");
  }

  if (unlimitedEnergyEffect) {
    refreshPlayerStatusEffects();
  }

  return shardsChange;
}

const handleFeed = async () => {
  const { beast } = playerStore;
  if (playerStore.shards < 1) {
    playSfxSound("noShards");
    return false;
  }

  if (playerStore.shards === 1) {
    // captureEvent("no_shards");
  }

  addClickAnalyticsToStore("feedClicksAnalytics");
  playSfxSound("eat");

  const newExperience = beast.experience + 1;
  const expToNextLevel = getExperienceRequirement(beast.level);
  const currentHappiness = playerStoreSelector.beastHappiness();
  const newHappiness = Math.min(currentHappiness + 10, 100);
  const isLevelUp = newExperience >= expToNextLevel;

  const playerUpdate = {
    coinsSnapshot: playerStore.coinsSnapshot,
    shards: playerStore.shards - 1,
    coinsEarningRate: playerStore.coinsEarningRate,
  };

  if (currentHappiness === 0) {
    // Player will begin earning again, so updating this.
    playerUpdate.coinsSnapshot = makeSnapshotValue(playerStoreSelector.coins());
  }

  if (isLevelUp) {
    const nextBeastLevel = beast.level + 1;
    const reincarnationMultiplier = beast.reincarnations == 0 ? 1 : 5;
    playSfxSound("levelUp");
    playerUpdate.coinsSnapshot = makeSnapshotValue(
      playerStoreSelector.coins() + getCoinLevelReward(nextBeastLevel),
    );
    playerUpdate.coinsEarningRate +=
      (gameConfig.coins.beastEarnPerLevel + playerStore.upgradesPerRate) *
      reincarnationMultiplier;
    captureEvent("level_up", { level: nextBeastLevel });
    eventBus.emit("levelUp", nextBeastLevel);
  }

  setPlayerStore(playerUpdate);
  setPlayerStore("beast", {
    experience: isLevelUp ? 0 : newExperience,
    happinessSnapshot: makeSnapshotValue(newHappiness),
    level: isLevelUp ? beast.level + 1 : beast.level,
  });

  addClickToStore("feedClicks");

  return true;
};

export function takeCoins(amount: number) {
  setPlayerStore({
    coinsSnapshot: makeSnapshotValue(playerStoreSelector.coins() - amount),
  });
}

export async function refreshPlayerState() {
  setPlayerStore(await GameService.getPlayerState());
}

export async function refreshPlayerStatusEffects() {
  const newPlayerStore = await GameService.getPlayerState();
  setPlayerStore({
    statusEffects: newPlayerStore.statusEffects,
  });
}

export const playerStoreActions = {
  handleMining,
  handleFeed,
};
