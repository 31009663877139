import { starterTrio } from "@/assets/images";
import "@/index.css";

// This route is just to close the window after deleting account in debug tools
export default function CloseRoute() {
  return (
    <div class="h-[100vh] relative z-[999999] mx-auto flex max-w-xl flex-col items-center justify-center gap-6 bg-[#FFE7DF]">
      <h1 class="eazy-chat text-center text-4xl text-black">
        Please close this window
      </h1>
      <img src={starterTrio} class="object-contain" />
    </div>
  );
}
