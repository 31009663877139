import { Actor, BoundingBox, Random, Engine, Vector, vec, coroutine } from "excalibur";

import { Coin } from "@/game/actors/coin";
import { Resources } from "@/game/resources";
import { Ranch } from "@/game/scenes/ranch";
import { Shard } from "@/game/actors/shard";
import { ShardSmall } from "@/game/actors/shard-small";
import { playSfxSound, sounds } from "@/components/sounds";
import { Energy } from "@/game/actors/energy";
import { Item } from "@kobalte/core/pagination";
import { RaffleTicket } from "./raffle-ticket";

class SpawnObject {
  item: Actor;
  x: number;
  y: number;
  height: number = 0;
  direction: Vector = new Vector(0, 0);

  force: number = 0;
  verticalForce: number = 0;
  speed: number = 0;
  maxSpeed: number = 600;
  acceleration: number = 0;
  friction: number = 0.1;
  gravity = 300;
  verticalSpeed: number = 0;

  collectStartPos: Vector = new Vector(0, 0);
  finished: boolean = false;
  lastPos: Vector = vec(0, 0);

  collectDelay: number = 0;
  shadow?: Actor;

  constructor(item: Actor, targetX: number, targetY: number, shadow: Actor|undefined, height: number = 0) {
    this.item = item;
    this.x = targetX;
    this.y = targetY;
    this.shadow = shadow;
    this.height = height;
  }

  public update(dt: number) {
    this.speed += this.acceleration * dt - this.friction * this.speed;

    let velocity: Vector = new Vector(
      this.direction.x * this.speed,
      this.direction.y * this.speed
    );

    // separate velocity and acceleration for height
    this.verticalSpeed += this.gravity * dt - this.friction * this.verticalSpeed;

    this.height -= this.verticalSpeed;
    if (this.height <= 0) {
      this.height = 0;
      this.verticalSpeed *= -0.9;
    }

    this.lastPos = this.item.pos.clone();
    // update item
    this.item.pos.x += velocity.x;
    this.item.pos.y = this.y + velocity.y - this.height;

    const diff = this.item.pos.sub(this.lastPos);
    if (!this.finished && this.height <= 0 && Math.abs(diff.x) < 0.01 && Math.abs(diff.y) < 0.01) {
      let self = this;
      coroutine(this.item.scene?.engine, function* () {
        yield self.collectDelay;
        self.finished = true;
        self.collectStartPos = self.item.pos.clone();
      });
    }
  }
}

export enum SpawnType {
  Drop,
  Splash
}

export enum ItemType {
  Coin,
  Shard,
  ShardSmall,
  Energy,
  RaffleTicket
}

export class ItemSpawner extends Actor {
  spawnObjects: SpawnObject[] = [];
  type: SpawnType;
  itemType: ItemType;
  isLucky: boolean = false;
  random: Random;

  bounds?: BoundingBox;
  target?: Vector;

  count: number = 0;
  limit: number = 100;
  lastSpawn: number = 0;
  spawnDelay: number = 0.01;
  time: number = 0;
  collectDelay: number;

  // need to update with logic from ui or passing position from ui
  collectSpeed: number = 800;
  collectAcceleration: number = 40;
  collectPoint: Vector = new Vector(30, 100);

  constructor(
    type: SpawnType,
    itemType: ItemType,
    collectDelay: number,
    bounds?: BoundingBox,
    target?: Vector,
  ) {
    super();
    this.name = 'spawner';
    this.random = new Random();
    this.type = type;
    this.itemType = itemType;
    this.collectDelay = collectDelay;
    this.bounds = bounds;
    this.target = target;
    if (this.itemType == ItemType.Shard) {
      this.collectPoint.y += 40;
    } else if (this.itemType == ItemType.Energy) {
    }
  }

  public update(_engine: Engine<any>, delta: number): void {
    let dt = delta / 1000;
    this.time += dt;

    const scene = this.scene as Ranch;

    // spawn coins wirh delay
    if (this.spawnDelay > 0 && this.time - this.lastSpawn > this.spawnDelay && this.count > 0) {
      this.addObject();
      this.lastSpawn = this.time;
      this.count -= 1;
      this.playItemDropSound();
    }
    else if (this.spawnDelay <= 0) {
      for (let i = 0; i < this.count; i++) {
        this.addObject();
        this.playItemDropSound();
      }
      this.count = 0;
    }

    for (var object of this.spawnObjects) {
      const item = object.item;

      if (object.finished) {
        const target: Vector = this.collectPoint.sub(item.pos);
        const direction = target.normalize();
        this.collectSpeed += this.collectAcceleration * dt;
        const velocity = direction.scale(this.collectSpeed).scale(dt);

        let distance = target.size;
        const startDistance = this.collectPoint.sub(object.collectStartPos).size;
        let scale = scene.scale * (distance / startDistance);
        item.scale = vec(scale, scale);

        if (distance < velocity.size) {
          item.pos = target.clone();
          distance = 0;
        }
        else {
          item.pos = item.pos.add(velocity);
        }
        if (object.shadow) {
          object.shadow.pos.x = item.pos.x;
        }


        const epsilon = 0.5;
        if (distance < epsilon) {
          this.playItemCollectSound();
          object.shadow?.kill();
          item.kill();
          const index = this.spawnObjects.indexOf(object);
          if (index > -1) {
            this.spawnObjects.splice(index, 1);
          }
        }
      } else {
        if (!object.finished) {
          object.update(dt);
        }
      }

      // update shadow
      if (object.shadow) {
        object.shadow.pos.x = object.item.pos.x;
        if (!object.finished) {
          object.shadow.pos.y = object.item.pos.y - 2 + object.height;
        }

        let distance = object.y - item.pos.y;
        const threshold = object.item.graphics.bounds.width * 6;
        if (distance > threshold) {
          object.shadow.graphics.opacity = 0.0;
        }
        else {
          object.shadow.graphics.opacity = 0.4;
          let scale = scene.scale * (1 - distance / threshold);
          object.shadow.scale = vec(scale, scale);
        }
      }
    }
  }

  public spawn(count: number) {
    this.count += Math.min(this.limit, count);
  }

  public setLucky(isLucky: boolean) {
    this.isLucky = isLucky;
  }

  private addObject() {
    let targetX = 0;
    let targetY = 0;
    let height = 0;

    // debugger;

    if (this.type == SpawnType.Drop && this.bounds != undefined) {
      targetX = this.random.integer(this.bounds.left, this.bounds.right);
      targetY = this.random.integer(this.bounds.top, this.bounds.bottom);

      let spawnY = -(16 + this.random.integer(0, 100));
      height = targetY - spawnY;

      if (this.target != undefined) {
        targetX = this.target.x;
        height = targetY - this.target.y;
      }
    } else if (this.type == SpawnType.Splash && this.target != undefined) {
      targetX = this.target.x;
      targetY = this.target.y;
    }

    let item: Actor;
    switch (this.itemType) {
      case ItemType.Coin:
        item = new Coin(targetX, targetY);
        break;
      case ItemType.Shard:
        item = new Shard(targetX, targetY, this.isLucky);
        break;
      case ItemType.ShardSmall:
        item = new ShardSmall(targetX, targetY);
        break;
      case ItemType.Energy:
        item = new Energy(targetX, targetY);
        break;
      case ItemType.RaffleTicket:
        item = new RaffleTicket(targetX, targetY);
        break;
    }
    item.z = targetY;
    this.scene?.add(item);

    let shadow = new Actor({
      name: 'coinShadow',
      x: targetX,
      y: targetY - 2,
    });
    shadow.graphics.use(Resources.CoinShadow.toSprite());
    const scene = this.scene as Ranch;
    shadow.scale = vec(scene.scale, scene.scale);
    this.scene?.add(shadow);

    let object = new SpawnObject(item, targetX, targetY, shadow, height);
    if (this.type == SpawnType.Splash || (this.type == SpawnType.Drop && this.target != undefined)) {
      object.speed = 8;
      object.verticalSpeed = 48;
      object.direction = new Vector(
        this.random.floating(-1, 1),
        this.random.floating(-1, 1)
      );
      object.y = item.pos.y + this.random.integer(0, 30);
      item.z = object.y;
    }
    object.collectDelay = this.collectDelay;
    this.spawnObjects.push(object);
  }

  playItemDropSound() {
    switch (this.itemType) {
      case ItemType.Coin:
        playSfxSound("coinTap1");
        break;
      case ItemType.Shard:
        playSfxSound("coinTap1");
        break;
      case ItemType.ShardSmall:
        playSfxSound("coinTap1");
        break;
      case ItemType.Energy:
        playSfxSound("energyDrop");
        break;
      case ItemType.RaffleTicket:
        playSfxSound("coinTap1");
        break;
    }
  }

  playItemCollectSound() {
    switch (this.itemType) {
      case ItemType.Coin:
        playSfxSound("coinTap1");
        break;
      case ItemType.Shard:
        playSfxSound("coinTap1");
        break;
      case ItemType.ShardSmall:
        playSfxSound("coinTap1");
        break;
      case ItemType.Energy:
        //playSfxSound("energyUp");
        break;
      case ItemType.RaffleTicket:
        playSfxSound("coinTap1");
        break;
    }
  }
}
