import { ImageSource } from "excalibur";
import witchHatSrc from "@/assets/beasts/hats/witch.png";
import bocHatSrc from "@/assets/beasts/hats/boc.png";
import leafHatSrc from "@/assets/beasts/hats/leaf.png";
import haloHatSrc from "@/assets/beasts/hats/halo.png";
import strawHatSrc from "@/assets/beasts/hats/straw.png";
import eggshellHatSrc from "@/assets/beasts/hats/eggshell.png";
import mushroomHatSrc from "@/assets/beasts/hats/mushroom.png"
import acornHatSrc from "@/assets/beasts/hats/acorn.png"

export interface Hat {
    originX: number;
    originY: number;
    src: ImageSource;
}

export const HatsConfig: {[id: string]: Hat} = {
    witch: {
        originX: 13,
        originY: 20,
        src: new ImageSource(witchHatSrc),
    },
    boc: {
        originX: 10,
        originY: 15,
        src: new ImageSource(bocHatSrc),
    },
    leaf: {
        originX: 10,
        originY: 13,
        src: new ImageSource(leafHatSrc),
    },
    halo: {
        originX: 7,
        originY: 22,
        src: new ImageSource(haloHatSrc),
    },
    straw: {
        originX: 13,
        originY: 12,
        src: new ImageSource(strawHatSrc),
    },
    eggshell: {
        originX: 10,
        originY: 11,
        src: new ImageSource(eggshellHatSrc),
    },
    mushroom: {
        originX: 10,
        originY: 13,
        src: new ImageSource(mushroomHatSrc),
    },
    acorn: {
        originX: 10,
        originY: 17,
        src: new ImageSource(acornHatSrc),
    },
}