import * as ex from 'excalibur';

export class LevelNode extends ex.Actor {
  public levelNumber: number;
  private levelText?: ex.Text;

  constructor(x: number, y: number, levelNumber: number, onSelect: (level: number) => void) {
    super({
      x,
      y,
      width: 40,
      height: 40,
      color: ex.Color.White
    });
    this.levelNumber = levelNumber;

    this.on('pointerup', () => {
      onSelect(this.levelNumber);
    });
  }

  onInitialize() {
    const graphics = new ex.GraphicsGroup({
      members: [
        {
          graphic: new ex.Circle({
            radius: 20,
            color: ex.Color.White,
            strokeColor: ex.Color.Black,
            lineWidth: 2,
          }),
          offset: ex.Vector.Zero,
        },
        {
          graphic: new ex.Text({
            text: this.levelNumber.toString(),
            color: ex.Color.Black,
            font: new ex.Font({
              family: 'Arial',
              size: 20,
              unit: ex.FontUnit.Px,
              textAlign: ex.TextAlign.Center
            })
          }),
          offset: new ex.Vector(22, 13),
        }
      ]
    });

    this.graphics.use(graphics);
  }
}