import {
  vec,
  Label,
  FontUnit,
  Font,
  Color,
  TextAlign,
  ParallelActions,
  MoveBy,
  Fade,
  Delay,
} from "excalibur";

export class FloatText extends Label {
  holdAtEnd: number;
  constructor(x: number, y: number, text: string, fontSize: number = 32, color: Color = Color.White, fontFamily: string = 'Eazy Chat', holdAtEnd: number = 0) {
    super({
      text: text,
      pos: vec(x, y),
      font: new Font({
        family: fontFamily,
        size: fontSize,
        unit: FontUnit.Px,
        color: color,
        strokeColor: Color.Black,
        lineWidth: 1,
        bold: true,
        textAlign: TextAlign.Center,
        shadow: {
          blur: 1,
          offset: vec(0, 0),
          color: Color.Black,
        }
      })
    });
    this.holdAtEnd = holdAtEnd;
  }

  public onInitialize(): void {
    if (this.holdAtEnd > 0) {
      this.actions
        .moveBy(0, -48, 48)
        .delay(this.holdAtEnd)
        .fade(0, 1000);
    } else {
      let actions = [
        new MoveBy(this, 0, -48, 48),
        new Fade(this, 0, 1000),
      ];
      const parallel = new ParallelActions(actions);
      this.actions.scaleBy(0.4, 0.4, 8).scaleBy(-0.4, -0.4, 8).runAction(parallel).die();
    }
    this.z = 9999;
  }
}