import { useGame } from "@/components/GameProvider.tsx";
import { createEffect, createSignal, Show } from "solid-js";
import { RaffleItem, RaffleMachineData } from "@/game/scenes/raffle-machine.ts";
import { RaffleResultModal } from "@/components/RaffleResultModal.tsx";
import { eventBus } from "@/components/event-bus.ts";
import { createQuery } from "@tanstack/solid-query";
import {
  RaffleTicketsService,
  SpecialSignupTicketsService,
} from "@/api-client";
import { loader } from "@/game/resources.ts";
import { useNavigate } from "@solidjs/router";
import { playSfxSound } from "@/components/sounds";
import { BonusTicketModal } from "@/components/BonusTicketModal";
import posthog from "posthog-js";

export function RaffleMachineRoute() {
  const game = useGame();
  const ticketsCountQuery = createQuery(() => ({
    queryKey: ["raffleTickets"],
    queryFn: RaffleTicketsService.getRaffleTickets,
  }));
  const specialSignupTicketCountQuery = createQuery(() => ({
    queryKey: ["SpecialSignupTicketCount"],
    queryFn: () => SpecialSignupTicketsService.getTicketCount(),
  }));
  const navigate = useNavigate();
  const [isBonusTicketModalOpen, setIsBonusTicketModalOpen] =
    createSignal(false);

  const [currentRaffleResult, setCurrentRaffleResult] =
    createSignal<RaffleItem | null>(null);

  let initialized = false;
  createEffect(async () => {
    if (initialized) return;

    if (!ticketsCountQuery.data) return;

    if (ticketsCountQuery.data.count > 0) {
      initialized = true;
      // Loading game assets here makes sure the game has the assets needed, even if this is the first page visited.
      if (!game()!.isRunning()) {
        await game()!.start(loader);
      }
      await game()!.goToScene<RaffleMachineData>("raffleMachine", {
        sceneActivationData: {
          initialTickets: ticketsCountQuery.data.count,
        },
      });
      return true;
    } else {
      navigate("/play");
    }
  });

  eventBus.on("raffleResult", (result: any) => {
    setCurrentRaffleResult(result);
    playSfxSound("raffleResult");
    ticketsCountQuery.refetch();
  });

  eventBus.on("goToRoute", (route: string) => {
    navigate(route);
  });

  function handleClose() {
    setCurrentRaffleResult(null);

    if (ticketsCountQuery.data && ticketsCountQuery.data?.count <= 0) {
      if (
        posthog.isFeatureEnabled("first_raffle_special_signup") &&
        specialSignupTicketCountQuery.data &&
        specialSignupTicketCountQuery.data?.count < 5
      ) {
        setIsBonusTicketModalOpen(true);
      } else {
        navigate("/play/airdrop");
      }
      return;
    }
  }

  return (
    <div>
      <RaffleResultModal
        isModalOpen={currentRaffleResult() !== null}
        closeModal={handleClose}
        raffleResult={currentRaffleResult()}
        ticketsCount={ticketsCountQuery.data?.count || 0}
      />
      <Show when={posthog.isFeatureEnabled("first_raffle_special_signup")}>
        <BonusTicketModal
          closeModal={() => {
            setIsBonusTicketModalOpen(false);
            navigate("/play/airdrop");
          }}
          isModalOpen={isBonusTicketModalOpen}
          setIsModalOpen={setIsBonusTicketModalOpen}
          title="Out of Tickets!"
        />
      </Show>
    </div>
  );
}
