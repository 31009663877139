import { ImageSource, Loader, SpriteSheet, Animation, Sound, range, FontSource, AnimationStrategy } from "excalibur";
import { AsepriteResource } from "@excaliburjs/plugin-aseprite";
import { CustomLoader } from "./custom-loader";
import { HatsConfig } from "./hats-config";

import pastureSrc from "@/assets/pasture.png";
import beastShadowSrc from "@/assets/beasts/shadow.png";
import coinSrc from "@/assets/objects/coin.png";
import coinShadowSrc from "@/assets/objects/coin-shadow.png";
import raffleTicketSrc from "@/assets/objects/raffle-ticket.png";
import shardSrc from "@/assets/objects/shard.png";
import luckyShardSrc from "@/assets/objects/lucky-shard.png";
import shardSmallSrc from "@/assets/objects/shard-small.png";
import energyParticleSrc from "@/assets/particles/energy-particle.png";
import sparkSrc from "@/assets/particles/spark.png";
import oreSrc from "@/assets/objects/shard-block.png";
import oreSmashSrc from "@/assets/objects/ore-node-smash.png";
import leafSrc from "@/assets/particles/leaf.png";
import sparkleSrc from "@/assets/particles/sparkle.png";
import veryHappySrc from "@/assets/emotes/very-happy.png";
import happySrc from "@/assets/emotes/happy.png";
import sadSrc from "@/assets/emotes/sad.png";
import verySadSrc from "@/assets/emotes/very-sad.png"
import arrowDownSrc from "@/assets/arrow-down.png";
import sparkleGoldSrc from "@/assets/beasts/sparkle/sparkle-gold.png";
import sparkleBlueSrc from "@/assets/beasts/sparkle/sparkle-blue.png";
import sparkleGreenSrc from "@/assets/beasts/sparkle/sparkle-green.png";
import sparklePurpleSrc from "@/assets/beasts/sparkle/sparkle-purple.png";
import energyBallSrc from "@/assets/objects/energy-ball.aseprite";
import raffleMachineSrc from "@/assets/objects/raffle-machine.aseprite";
import ticketProgressBarSrc from "@/assets/objects/ticket-progress.aseprite";
import reincarnateWingsSrc from "@/assets/beasts/reincarnate-wings.png";
import reincarnateHaloSrc from "@/assets/beasts/reincarnate-halo.png";

import raffleBronzeEgg from "@/assets/objects/raffle/bronze-egg.png"
import raffleSilverEgg from "@/assets/objects/raffle/silver-egg.png"
import raffleGoldEgg from "@/assets/objects/raffle/gold-egg.png"
import raffleShard from "@/assets/objects/raffle/shards.png"
import raffleTSikverTicker from "@/assets/objects/raffle/silver-ticket.png"
import raffleEnergyBall from "@/assets/objects/raffle/energy-ball.png"
import raffleLuckySerum from "@/assets/objects/raffle/lucky-serum.png"
import raffleKuro from "@/assets/objects/raffle/kuro.png"
import energyDrinkSrc from "@/assets/objects/raffle/energy-drink.png"; 

import digbySprite from "@/assets/beasts/idle/211.png";
import toruSprite from "@/assets/beasts/idle/215.png";
import ravoltSprite from "@/assets/beasts/idle/219.png";
import digbyLuckySprite from "@/assets/beasts/idle-lucky/211.png";
import toruLuckySprite from "@/assets/beasts/idle-lucky/215.png";
import ravoltLuckySprite from "@/assets/beasts/idle-lucky/219.png";

import eazyChatFontSrc from "@/assets/fonts/Eazy_Chat/Eazy_Chat-Regular.otf";
import superBubbleFontSrc from "@/assets/fonts/super_bubble/Super Bubble.ttf";

export const Resources = {
  Pasture: new ImageSource(pastureSrc),
  BeastShadow: new ImageSource(beastShadowSrc),
  Coin: new ImageSource(coinSrc),
  CoinShadow: new ImageSource(coinShadowSrc),
  Shard: new ImageSource(shardSrc),
  LuckyShard: new ImageSource(luckyShardSrc),
  ShardSmall: new ImageSource(shardSmallSrc),
  Ore: new ImageSource(oreSrc),
  OreSmash: new ImageSource(oreSmashSrc),
  Leaf: new ImageSource(leafSrc),
  Sparkle: new ImageSource(sparkleSrc),
  VeryHappy: new ImageSource(veryHappySrc),
  Happy: new ImageSource(happySrc),
  Sad: new ImageSource(sadSrc),
  VerySad: new ImageSource(verySadSrc),
  SparkleGold: new ImageSource(sparkleGoldSrc),
  SparkleBlue: new ImageSource(sparkleBlueSrc),
  SparkleGreen: new ImageSource(sparkleGreenSrc),
  SparklePurple: new ImageSource(sparklePurpleSrc),
  EnergyParticle: new ImageSource(energyParticleSrc),
  Spark: new ImageSource(sparkSrc),
  EnergyBall: new AsepriteResource(energyBallSrc),
  EnergyDrink: new ImageSource(energyDrinkSrc),
  RaffleMachine: new AsepriteResource(raffleMachineSrc),
  ArrowDown: new ImageSource(arrowDownSrc),
  EazyChat: new FontSource(eazyChatFontSrc, 'Eazy Chat'),
  SuperBubble: new FontSource(superBubbleFontSrc, 'Super Bubble'),
  RaffleSilverEgg: new ImageSource(raffleSilverEgg),
  RaffleBronzeEgg: new ImageSource(raffleBronzeEgg),
  RaffleGoldEgg: new ImageSource(raffleGoldEgg),
  RaffleShard: new ImageSource(raffleShard),
  RaffleTSikverTicker: new ImageSource(raffleTSikverTicker),
  RaffleEnergyBall: new ImageSource(raffleEnergyBall),
  RaffleLuckySerum: new ImageSource(raffleLuckySerum),
  RaffleKuro: new ImageSource(raffleKuro),
  TicketProgressBar: new AsepriteResource(ticketProgressBarSrc),
  RaffleTicket: new ImageSource(raffleTicketSrc),
  ReincarnateWings: new ImageSource(reincarnateWingsSrc),
  ReincarnateHalo: new ImageSource(reincarnateHaloSrc),
} as const;

export const loader = new CustomLoader();
for (const res of Object.values(Resources)) {
  loader.addResource(res);
}

for (const hat of Object.values(HatsConfig)) {
  loader.addResource(hat.src);
}

export function _makeBeastAnim(sprite: string) {
  let beastSprite = new ImageSource(sprite);
  loader.addResource(beastSprite);
  const spritesheet = SpriteSheet.fromImageSource({
    image: beastSprite,
    grid: {
      rows: 1,
      columns: 4,
      spriteWidth: 32,
      spriteHeight: 32,
    },
  });

  return Animation.fromSpriteSheet(spritesheet, range(0, 3), 200);
}

export function _makeSparkleAnim(src: ImageSource) {
  const spritesheet = SpriteSheet.fromImageSource({
    image: src,
    grid: {
      rows: 1,
      columns: 32,
      spriteWidth: 36,
      spriteHeight: 36,
    },
  });
  let anim = Animation.fromSpriteSheet(spritesheet, range(0, 31), 100);
  anim.strategy = AnimationStrategy.Loop;
  return anim;
}

export const BeastResources: { [key: string]: any } = {
  "211-idle": _makeBeastAnim(digbySprite),
  "211-idle-lucky": _makeBeastAnim(digbyLuckySprite),
  "215-idle": _makeBeastAnim(toruSprite),
  "215-idle-lucky": _makeBeastAnim(toruLuckySprite),
  "219-idle": _makeBeastAnim(ravoltSprite),
  "219-idle-lucky": _makeBeastAnim(ravoltLuckySprite),
  "sparkle-gold": _makeSparkleAnim(Resources.SparkleGold),
  "sparkle-blue": _makeSparkleAnim(Resources.SparkleBlue),
  "sparkle-green": _makeSparkleAnim(Resources.SparkleGreen),
  "sparkle-purple": _makeSparkleAnim(Resources.SparklePurple),
};