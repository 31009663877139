import { LevelNode } from '@/game/actors/match/level-node';
import * as ex from 'excalibur';

export class LevelSelection extends ex.Scene {
  private levels: number = 10;
  private path: ex.Vector[] = [];

  onInitialize(engine: ex.Engine) {
    this.generatePath(engine);
    this.createLevelNodes(engine);
    this.backgroundColor = ex.Color.fromRGB(128, 180, 100);
  }

  generatePath(engine: ex.Engine) {
    const pathPoints = 100;
    for (let i = 0; i < pathPoints; i++) {
      const t = i / (pathPoints - 1);
      const x = Math.sin(t * Math.PI * 2) * (engine.drawWidth / 4) + engine.drawWidth / 2;
      const y = engine.drawHeight - 50 - t * (engine.drawHeight - 100);
      this.path.push(new ex.Vector(x, y));
    }
  }

  calculatePathLength(): number {
    let length = 0;
    for (let i = 1; i < this.path.length; i++) {
      length += this.path[i - 1].distance(this.path[i]);
    }
    return length;
  }

  lerpVector(start: ex.Vector, end: ex.Vector, t: number): ex.Vector {
    return new ex.Vector(
      start.x + (end.x - start.x) * t,
      start.y + (end.y - start.y) * t
    );
  }

  createLevelNodes(engine: ex.Engine) {
    const pathLength = this.calculatePathLength();
    const segmentLength = pathLength / (this.levels - 1);

    let currentDistance = 0;
    let currentPathIndex = 0;

    for (let i = 0; i < this.levels; i++) {
      const targetDistance = i * segmentLength;

      while (currentPathIndex < this.path.length - 1) {
        const segmentDistance = this.path[currentPathIndex].distance(this.path[currentPathIndex + 1]);
        if (currentDistance + segmentDistance >= targetDistance) {
          const remainingDistance = targetDistance - currentDistance;
          const t = remainingDistance / segmentDistance;
          const position = this.lerpVector(this.path[currentPathIndex], this.path[currentPathIndex + 1], t);

          const node = new LevelNode(position.x, position.y, i + 1, (level) => {
            engine.goToScene("match", { sceneActivationData: { level: level } });
          });

          this.add(node);
          break;
        }
        currentDistance += segmentDistance;
        currentPathIndex++;
      }

      // Ensure the last node is added
      if (i === this.levels - 1 && currentPathIndex === this.path.length - 1) {
        const lastPosition = this.path[this.path.length - 1];
        const node = new LevelNode(lastPosition.x, lastPosition.y, i + 1, (level) => {
          engine.goToScene('match', { sceneActivationData: { level: level } });
        });
        this.add(node);
      }
    }
  }

  onPreDraw(ctx: ex.ExcaliburGraphicsContext) {
    for (let i = 1; i < this.path.length; i++) {
      ctx.drawLine(this.path[i - 1], this.path[i], ex.Color.White, 3);
    }
  }
}