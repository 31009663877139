import {
  Engine,
  Actor,
  Random,
  vec,
  Color,
  MoveBy,
  Fade,
  ParallelActions,
  Animation,
} from "excalibur";

import { Resources, BeastResources } from "../resources";
import { Config } from "../config";
import { FloatText } from "@/game/actors/float-text";
import { vibrate } from "@/lib/utils.ts";
import {
  OnboardingStep,
  onboardingActions,
  onboardingSelectors,
} from "@/components/onboarding-store";
import { outline } from "../materials/outline";
import { createEffect } from "solid-js";
import { OnboardingArrowDown } from "./onboarding-arrow-down";
import { playerStore } from "@/components/player-store";
import { animManager } from "./animation-manager";

enum Happiness {
  VerySad,
  Sad,
  Happy,
  VeryHappy,
}

export class OnboardingBeast extends Actor {
  public identifier: number = 211;
  public shadow: Actor = new Actor({ name: "beast-shadow" });
  public sort: boolean = true;
  public happiness?: Happiness;
  public outlineMaterial: ex.Material | null = null;
  private random: Random;
  public arrow?: OnboardingArrowDown;

  constructor(id: number) {
    super({ x: 0, y: 0 });

    this.random = new Random();
    this.identifier = id;

    this.pointer.useGraphicsBounds = true;
    this.on("pointerdown", () => {
      if (onboardingSelectors.currentStep() < OnboardingStep.FeedBeast) {
        return;
      }
      let result = onboardingActions.handleFeedOnboarding();
      let scale = Config.scale;
      this.actions.clearActions();
      this.scale = vec(scale, scale);
      this.actions
        .scaleBy(vec(-0.25, 0.5), 18)
        .scaleBy(vec(0.25, -0.5), 18)
        .scaleBy(vec(0.5, -0.25), 12)
        .scaleBy(vec(-0.5, 0.25), 12);
      vibrate("light");

      let text = result ? "+1" : "No shards!";
      let color = result ? Color.Green : Color.fromRGB(255, 64, 64);
      let font = result ? "Eazy Chat" : "Super Bubble";
      this.scene?.add(
        new FloatText(
          this.pos.x + this.random.integer(-32, 32),
          this.pos.y - 180,
          text,
          32,
          color,
          font,
        ),
      );
      if (result && onboardingSelectors.happiness() === 80) {
        this.showHappinessEmote(100);
      }
    });
    createEffect(() => {
      if (onboardingSelectors.currentStep() === OnboardingStep.HelloBeast) {
        this.hideArrow();
      } else if (
        onboardingSelectors.currentStep() === OnboardingStep.FeedBeast
      ) {
        this.showOutline();
        this.showArrow();
      } else if (
        onboardingSelectors.currentStep() === OnboardingStep.MoodXpExplanation
      ) {
        this.hideOutline();
        this.hideArrow();
      } else if (
        onboardingSelectors.currentStep() === OnboardingStep.FeedBeastAgain
      ) {
        this.showOutline();
        this.showArrow();
      } else if (
        onboardingSelectors.currentStep() === OnboardingStep.FeedBeastMore
      ) {
        this.hideOutline();
        this.hideArrow();
      } else if (
        onboardingSelectors.currentStep() === OnboardingStep.BeastHappinessAway
      ) {
        this.showHappinessEmote(10);
      }
    });
  }

  public onInitialize(engine: Engine<any>): void {
    const viewport = engine.screen.getScreenBounds();
    let scale = Config.scale;

    this.pos.x = viewport.width / 2;
    this.pos.y = viewport.height - 75 * scale;
    this.name = "beast";
    this.anchor = vec(0.5, 1);

    this.graphics.use(this.getBeastSprite());
    this.scale = vec(scale, scale);

    this.shadow.graphics.use(Resources.BeastShadow.toSprite());
    this.shadow.pos = vec(this.pos.x, this.pos.y - 4 * scale);
    this.shadow.graphics.opacity = 0.5;
    this.shadow.scale = vec(scale, scale);
    this.scene?.add(this.shadow);

    const outlineMaterial = engine.graphicsContext.createMaterial({
      name: "outline",
      fragmentSource: outline,
    });
    this.outlineMaterial = outlineMaterial;

    const arrow = new OnboardingArrowDown(this.pos.x, this.pos.y - 180, false);
    this.arrow = arrow;
    this.scene?.add(arrow);

    createEffect(() => {
      this.graphics.use(this.getBeastSprite());
    });
    if (onboardingSelectors.beastIsLucky()) {
      let anim: Animation = BeastResources["sparkle-gold"];
      anim.scale = vec(scale, scale);
      animManager.play(anim, this.pos.sub(vec(0, 14 * scale)));
    }
  }

  private getBeastSprite() {
    if (onboardingSelectors.beastIsLucky()) {
      return BeastResources[`${this.identifier}-idle-lucky`];
    }

    return BeastResources[`${this.identifier}-idle`];
  }

  public update(engine: ex.Engine, delta: number) {
    super.update(engine, delta);
    if (this.sort) {
      this.z = this.pos.y;
    }
  }

  public showHappinessEmote(happiness: number) {
    console.log(`happiness: ${happiness}`);
    let image = Resources.VeryHappy;
    let currenthappiness = Happiness.VeryHappy;
    if (happiness <= 25) {
      image = Resources.VerySad;
      currenthappiness = Happiness.VerySad;
    } else if (happiness <= 50) {
      image = Resources.Sad;
      currenthappiness = Happiness.Sad;
    } else if (happiness <= 99) {
      image = Resources.Happy;
      currenthappiness = Happiness.Happy;
    }
    if (currenthappiness == this.happiness) {
      return;
    }

    this.happiness = currenthappiness;
    let emote = new Actor({
      x: this.pos.x,
      y: this.pos.y - 24 * Config.scale,
      anchor: vec(0.5, 1),
    });
    emote.graphics.use(image.toSprite());
    emote.scale = vec((1 / Config.scale) * 1.5, (1 / Config.scale) * 1.5);
    this.scene?.add(emote);
    let actions = [
      new MoveBy(emote, 0, -8 * Config.scale, 8 * Config.scale * 7),
      new Fade(emote, 1, 300),
    ];
    const parallel = new ParallelActions(actions);
    emote.actions
      .runAction(parallel)
      .scaleBy(vec(0.05, 0.05), 1)
      .scaleBy(vec(-0.05, -0.05), 1)
      .delay(1000)
      .fade(0, 600)
      .die();
  }

  public showOutline() {
    if (this.graphics.material === null) {
      this.graphics.material = this.outlineMaterial;
    }
  }

  public hideOutline() {
    if (this.graphics.material) {
      this.graphics.material = null;
    }
  }

  public showArrow() {
    if (this.arrow !== undefined) {
      this.arrow.show();
    }
  }

  public hideArrow() {
    if (this.arrow) {
      this.arrow.hide();
    }
  }
}
