import { createSignal, For, Component, createEffect } from 'solid-js';

interface StarBurstProps {
  children: any;
  starCount?: number;
  starColor?: string;
  burstDistance?: number;
  starSize?: number;
  duration?: number;
  isBursting: boolean;
  onAnimationComplete?: () => void;
}

const StarBurst: Component<StarBurstProps> = (props) => {
  const [burst, setBurst] = createSignal(false);
  const starCount = props.starCount || 5;
  const starColor = props.starColor || '#ffff99';
  const burstDistance = props.burstDistance || 100;
  const starSize = props.starSize || 50;
  const duration = props.duration || 2000; // Animation duration in milliseconds

  createEffect(() => {
    if (props.isBursting) {
      const timer = setTimeout(() => {
        props.onAnimationComplete?.();
      }, duration);

      return () => clearTimeout(timer);
    }
  });

  return (
    <div
      style={{
        'position': 'relative',
        'display': 'inline-block',
      }}
    >
      {props.children}
      <div style={{
        'position': 'absolute',
        'top': '0',
        'left': '0',
        'width': '100%',
        'height': '100%',
        'transform': 'translate(-50%, -50%)',
        'pointer-events': 'none',
      }}>
        <style>
          {`
          @keyframes burstAnimation {
            0% {
              transform: translate(0%, 0%);
              opacity: 1;
            }
            100% {
              transform: translate(var(--end-x), var(--end-y));
              opacity: 0;
            }
          }
        `}
        </style>
        <For each={[...Array(starCount).keys()]}>
          {(index) => {
            const angle = 2 * Math.PI / starCount;
            const endX = Math.cos(index * angle) * burstDistance;
            const endY = Math.sin(index * angle) * burstDistance;

            return (
              <div
                style={{
                  'position': 'absolute',
                  'width': `${starSize}px`,
                  'height': `${starSize}px`,
                  'background': starColor,
                  'clip-path': 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
                  'top': '50%',
                  'left': '50%',
                  'transform': 'translate(-50%, -50%)',
                  'opacity': '0',
                  'pointer-events': 'none',
                  '--end-x': `${endX}px`,
                  '--end-y': `${endY}px`,
                  'animation': props.isBursting ? `burstAnimation ${duration}ms ease-out forwards` : 'none',
                  'z-index': 20,
                }}
              />
            );
          }}
        </For>
      </div>
    </div>
  );
};

export default StarBurst;