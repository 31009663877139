import { createStore } from "solid-js/store";
import { makePersisted } from "@solid-primitives/storage";
import { playBgMusic, sfxSounds, sounds } from "@/components/sounds.ts";

const [settingsStore, setSettingsStore] = makePersisted(
  createStore({
    bgMusicEnabled: true,
    compactNumbers: true,
    bgMusicVolume: 0.7,
    sfxVolume: 1,
  }),
  {
    name: "settings-ranch",
    storage: localStorage,
  },
);

const settingsStoreActions = {
  enableBgMusic: () => {
    playBgMusic();
    setSettingsStore("bgMusicEnabled", true);
  },
  disableBgMusic: () => {
    sounds.bgMusic.stop();
    setSettingsStore("bgMusicEnabled", false);
  },
  toggleCompactNumbers: () => {
    setSettingsStore("compactNumbers", !settingsStore.compactNumbers);
  },
  changeBgMusicVolume: (volume: number) => {
    sounds.bgMusic.volume(volume);
    setSettingsStore("bgMusicVolume", volume);
  },
  changeSfxVolume: (volume: number) => {
    sfxSounds.forEach((sfx) => {
      sfx.volume(volume);
    });
    setSettingsStore("sfxVolume", volume);
  },
};

export { settingsStore, settingsStoreActions };
