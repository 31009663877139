import {
  createEffect,
  createMemo,
  createResource,
  createSignal,
  For,
  Index,
  Show,
} from "solid-js";
import connection from "@/components/connection.ts";
import {
  playerStore,
  playerStoreSelector,
  refreshPlayerState,
  setPlayerStore,
} from "@/components/player-store.ts";
import { makeSnapshotValue } from "@/lib/utils.ts";
import { Button } from "@/components/ui/button.tsx";
import {
  Switch,
  SwitchControl,
  SwitchLabel,
  SwitchThumb,
} from "@/components/ui/switch.tsx";
import { PageContainer } from "@/components/PageContainer";
import { showToast } from "@/components/ui/toast.tsx";
import { gameConfig } from "@/components/lib/game-config.ts";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { DateTime } from "luxon";
import { calculateHappinessDepletesAt } from "@/components/lib/happiness.ts";
import {
  settingsStore,
  settingsStoreActions,
} from "@/components/settings-store";
import { WebApp } from "@/twa-sdk-updated/core";
import { CloseButton } from "@/components/CloseButton";
import { A } from "@solidjs/router";
import { useGame } from "@/components/GameProvider";
import { Ranch } from "@/game/scenes/ranch";
import posthog from "posthog-js";
import {
  DailyStreakService,
  DebugService,
  ShopService,
  EnergyBallsService,
} from "@/api-client";

function DebugObjectDisplay(props: {
  valuesObject: Record<string, Record<string, any>>;
}) {
  return (
    <For each={Object.entries(props.valuesObject)}>
      {([name, values]) => (
        <div>
          <p class="font-bold capitalize text-gray-800">{name}:</p>
          <ul class="ml-4 list-disc">
            <For each={Object.entries(values)}>
              {(subEntry) => (
                <li>
                  {subEntry[0]}: {subEntry[1]}
                </li>
              )}
            </For>
          </ul>
        </div>
      )}
    </For>
  );
}

function _readFromStorage<T>(key: string, defaultValue: T): T {
  const storedValue = localStorage.getItem(key);
  if (!storedValue) return defaultValue;

  try {
    return JSON.parse(storedValue) as T;
  } catch (e) {
    return defaultValue;
  }
}
function createLocalStorageSignal<T>(key: string, initialValue: T) {
  const [value, setValue] = createSignal(_readFromStorage(key, initialValue));

  createEffect(() => {
    localStorage.setItem(key, JSON.stringify(value()));
  });

  return [value, setValue] as const;
}

function TelegramWebAppSettings() {
  return (
    <div class="px-3">
      <h3 class="text-lg font-bold">Telegram</h3>
      <p>
        version: <span>{WebApp.version}</span>
      </p>
      <p>
        platform: <span>{WebApp.platform}</span>
      </p>
      <p>
        isExpanded: <span>{WebApp.isExpanded.toString()}</span>
      </p>
      <p>
        viewportHeight: <span>{WebApp.viewportHeight}</span>
      </p>
      <p>
        viewportStableHeight: <span>{WebApp.viewportStableHeight}</span>
      </p>
      <p>
        isVerticalSwipesEnabled:{" "}
        <span>{WebApp.isVerticalSwipesEnabled.toString()}</span>
      </p>
    </div>
  );
}

export function DebugRoute() {
  const [debugValues, debugValuesActions] = createResource(() =>
    DebugService.getDebutValues(),
  );
  const [currentTab, setCurrentTab] = createLocalStorageSignal(
    "debug-route-tab",
    "actions",
  );

  const [debugConfig, debugConfigResourceActions] = createResource(() =>
    DebugService.getDebugConfig(),
  );

  const [isAutoFeedStarted, { refetch: refetchIsAutoFeedStarted }] =
    createResource(() => DebugService.isAutoFeedStarted());

  const [dailyStreakState, { refetch: refetchDailyStreakState }] =
    createResource(() => DailyStreakService.getState());
  const game = useGame();

  createEffect(() => {
    console.log("DEBUG CONFIG:", debugConfig());
    console.log(
      "DEBUG CONFIG ACTIONS:",
      debugConfig()?.skipOnboarding ?? false,
    );
  });

  function clearData() {
    DebugService.debugClearData().then(() => {
      localStorage.clear();
      showToast({ title: "Data Cleared. Reloading...", variant: "success" });
      location.href = "/";
    });
  }

  function deleteAccount() {
    DebugService.debugClearData().then(() => {
      localStorage.clear();
      location.href = "/close";
    });
  }

  function deleteShopPurchases() {
    DebugService.debugDeleteShopPurchases().then(() => {
      showToast({ title: "Shop Purchases Deleted", variant: "success" });
      location.href = "/";
    });
  }

  function deleteQuests() {
    DebugService.debugDestroyQuests().then(() => {
      showToast({ title: "Player quests deleted", variant: "success" });
      location.href = "/";
    });
  }

  function createFakeReferee() {
    DebugService.debugCreateReferee().then(() => {
      showToast({ title: "Fake Referee Created", variant: "success" });
      location.href = "/";
    });
  }

  function deleteSocialConnections() {
    DebugService.debugDestroySocialConnections().then(() => {
      showToast({ title: "Player connections deleted", variant: "success" });
      location.href = "/";
    });
  }

  function fakePurchase() {
    DebugService.debugFakePurchase().then(() => {
      showToast({ title: "Fake Purchase", variant: "success" });
      location.href = "/";
    });
  }

  function fakePurchaseItem(listingId: string) {
    DebugService.debugFakePurchaseItem({
      requestBody: {
        listingId,
      },
    }).then(() => {
      showToast({ title: "Fake Purchase", variant: "success" });
      location.href = "/";
    });
  }

  function resetCoins() {
    DebugService.debugResetCoins().then(() => {
      showToast({ title: "Coins reset", variant: "success" });
      setPlayerStore({
        coinsSnapshot: makeSnapshotValue(0),
      });
    });
  }

  function rechargeEnergy() {
    DebugService.debugRechargeEnergy().then(() => {
      showToast({ title: "Energy Recharged", variant: "success" });
      setPlayerStore({
        energySnapshot: makeSnapshotValue(100),
      });
    });
  }

  function addShards() {
    DebugService.debugAddShards({
      requestBody: {
        amount: 100,
      },
    }).then(() => {
      setPlayerStore({
        shards: playerStore.shards + 100,
      });
    });
  }

  function addCoins(amount: number) {
    DebugService.debugAddCoins({
      requestBody: {
        amount,
      },
    }).then(() => {
      refreshPlayerState();
      showToast({ title: "Coins added", variant: "success" });
    });
  }

  function setSkipOnboarding(value: boolean) {
    DebugService.updateDebugConfigSkipOnboarding({
      requestBody: {
        value,
      },
    }).then(() => {
      debugConfigResourceActions.refetch();
    });
  }

  function startAutoFeed() {
    DebugService.startAutoFeed().then(() => {
      showToast({ title: "AutoFeed Started", variant: "success" });
      refetchIsAutoFeedStarted();
    });
  }

  function stopAutoFeed() {
    DebugService.stopAutoFeed().then(() => {
      showToast({ title: "AutoFeed Stopped", variant: "success" });
      refetchIsAutoFeedStarted();
    });
  }

  function convertToLuckyBeast() {
    setPlayerStore("beast", {
      isLucky: true,
    });
  }

  function convertToNormalBeast() {
    setPlayerStore("beast", {
      isLucky: false,
    });
  }

  function swapBeast(id: number) {
    setPlayerStore("beast", {
      specieStageId: id,
    });
  }

  function resetDailyStreakDebug() {
    DailyStreakService.debugResetStreak().then(() => {
      showToast({ title: "Daily Streak Reset", variant: "success" });
      refetchDailyStreakState();
    });
  }

  function changeDailyStreakDebugMode(enabled: boolean) {
    DailyStreakService.setDebugMode({
      requestBody: {
        enabled,
      },
    }).then(() => {
      showToast({
        title: enabled
          ? "Daily Streak Debug Mode Enabled"
          : "Daily Streak Debug Mode Disabled",
        variant: "success",
      });
      refetchDailyStreakState();
    });
  }

  createEffect(() => {
    console.log("DEBUG VALUES", debugValues());
    console.log("DEBUG?", debugValues.loading);
  });

  createEffect(() => {
    console.log("DEBUG VALUES", debugValues());
  });

  // Humanize using luxon
  const humanizeISODate = (date: string) => DateTime.fromISO(date).toRelative();
  const humanizeDate = (date: Date) => DateTime.fromJSDate(date).toRelative();
  const serverDebugValues = () => {
    if (!debugValues()) return {} as Record<string, Record<string, any>>;

    // TODO: Fix undefined values schema
    const values = debugValues()!.playerDebugValues!;
    return {
      coins: {
        balance: values.coins!.balance,
        snapshotUpdatedAt: humanizeDate(values.coins!.snapshot!.updatedAt!),
        beastEarnRate: values.coins!.beastEarnRate,
        upgradesEarnRate: values.coins!.upgradesEarnRate,
        earningRate: values.coins!.earningRate,
        earnStopsAt: humanizeDate(values.coins!.earnStopsAt!),
      },
    };
  };

  const clientDebugValues = () => {
    // This doesn't have anything to do with these debugValues, but
    // adding it here will force recomputing this when the data is refetched
    if (!debugValues()) return {} as Record<string, Record<string, any>>;

    return {
      coins: {
        balance: playerStoreSelector.coins(),
        snapshotUpdatedAt: humanizeDate(playerStore.coinsSnapshot.updatedAt),
        earningRate: playerStoreSelector.coinsEarningRate(),
        earnStopsAt: humanizeDate(
          calculateHappinessDepletesAt(playerStore.beast.happinessSnapshot),
        ),
      },
    };
  };

  return (
    <PageContainer>
      <CloseButton />
      <Tabs
        value={currentTab()}
        onChange={setCurrentTab}
        class="flex h-full flex-col"
      >
        <div
          style={{
            background: "white",
          }}
          class="sticky top-0 z-20 rounded-t-3xl border border-[#561212] px-3 py-2"
        >
          <CloseButton />

          <TabsList class="mt-10 grid w-full grid-cols-4 gap-2 p-2">
            <TabsTrigger value="actions">Actions</TabsTrigger>
            <TabsTrigger value="settings">Settings</TabsTrigger>
            <TabsTrigger value="values">Values</TabsTrigger>
            <TabsTrigger value="store">Store</TabsTrigger>
            <TabsTrigger value="minigames">Minigames</TabsTrigger>
            <TabsTrigger value="visuals">Visuals</TabsTrigger>
            <TabsTrigger value="featureflags">Feature Flags</TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="actions" class="overflow-y-auto pb-10">
          <div class="p-2">
            <div class="flex flex-wrap justify-start gap-2">
              <Button
                onClick={clearData}
                variant="destructive"
                class="pointer-events-auto"
              >
                Clear Data
              </Button>
              <Button
                onClick={deleteAccount}
                variant="destructive"
                class="pointer-events-auto"
              >
                Delete Account
              </Button>

              <Button
                onClick={() => connection()?.invoke("DebugThrow")}
                variant="destructive"
                class="pointer-events-auto"
              >
                Throw Debugging Exception
              </Button>

              <Button onClick={rechargeEnergy}>Recharge Energy</Button>
              <Button onClick={addShards}>Add Shards (100)</Button>
            </div>

            <h2 class="py-2 text-lg">Shops</h2>
            <div class="flex flex-wrap gap-2">
              <Button
                onClick={deleteShopPurchases}
                variant="destructive"
                class="pointer-events-auto"
              >
                Delete Shop Purchases
              </Button>
              <Button onClick={fakePurchase}>Fake purchase shop</Button>
              <Button onClick={() => fakePurchaseItem("lucky-serum")}>
                Fake Purchase Lucky Serum
              </Button>
              <Button onClick={() => fakePurchaseItem("boc")}>
                Fake Purchase Bok Hat
              </Button>
              <Button onClick={() => fakePurchaseItem("raffle-ticket")}>
                Fake Purchase Raffle Ticket
              </Button>
              <Button onClick={() => fakePurchaseItem("energy-ball")}>
                Fake Purchase Energy Ball
              </Button>
              <Button onClick={() => fakePurchaseItem("energy-drink")}>
                Fake Purchase Energy Drink
              </Button>
            </div>

            <h2 class="py-2 text-lg">Coins</h2>
            <div class="flex flex-wrap gap-2">
              <Button onClick={resetCoins} variant="destructive">
                Reset Coins
              </Button>
              <Button onClick={() => addCoins(100)}>Add Coins (100)</Button>
              <Button onClick={() => addCoins(1000)}>Add Coins (1000)</Button>
              <Button onClick={() => addCoins(100_000)}>
                Add Coins (100k)
              </Button>
              <Button onClick={() => addCoins(500_000_000_000_000)}>
                Add Coins (500T)
              </Button>
            </div>

            <h2 class="py-2 text-lg">
              Auto Feed {isAutoFeedStarted() ? "ON" : "OFF"}
            </h2>
            <div class="flex flex-wrap gap-2">
              <Button onClick={startAutoFeed}>StartAutoFeed</Button>
              <Button onClick={stopAutoFeed}>StopAutoFeed</Button>
            </div>

            <h2 class="py-2 text-lg">Energy Balls</h2>
            <div class="flex flex-wrap gap-2">
              <Button
                onClick={() =>
                  EnergyBallsService.debugReset().then(
                    () => (location.href = "/"),
                  )
                }
              >
                Reset
              </Button>
              <Button
                onClick={async () => {
                  const energyState =
                    await EnergyBallsService.getEnergyBallState();
                  EnergyBallsService.debugTakeHits({
                    ballId: energyState.id,
                    requestBody: {
                      hits: 100,
                    },
                  }).then(() => (location.href = "/"));
                }}
              >
                Take Hit Combo 100
              </Button>
            </div>

            <h2 class="py-2 text-lg">
              Daily Streaks Debug Mode{" "}
              {dailyStreakState()?.isDebugMode ? "ON" : "OFF"}
            </h2>
            <div class="flex flex-wrap gap-2">
              <Button onClick={resetDailyStreakDebug} variant="destructive">
                Reset Daily Streak
              </Button>
              <Button onClick={() => changeDailyStreakDebugMode(true)}>
                Enable Debug Mode
              </Button>
              <Button onClick={() => changeDailyStreakDebugMode(false)}>
                Disable Debug Mode
              </Button>
            </div>

            <h2 class="py-2 text-lg">Quests and Social Connections</h2>
            <div class="flex flex-wrap gap-2">
              <Button variant="destructive" onClick={deleteQuests}>
                Delete quests
              </Button>
              <Button variant="destructive" onClick={deleteSocialConnections}>
                Delete social connections
              </Button>
            </div>

            <h2 class="py-2 text-lg">Referrals</h2>
            <div class="flex flex-wrap gap-2">
              <Button onClick={createFakeReferee}>Create referee</Button>
            </div>

            <h2 class="py-2 text-lg">Notifications</h2>
            <div class="flex flex-wrap gap-2">
              <Button
                onClick={() => {
                  DebugService.sendDebugNotification().then(() => {
                    showToast({
                      title: "Notification sent",
                      variant: "success",
                    });
                  });
                }}
              >
                Sent test notification
              </Button>
            </div>
          </div>
        </TabsContent>
        <TabsContent value="settings" class="overflow-y-auto pb-10">
          <div class="flex flex-col gap-2">
            <Switch
              checked={Boolean(debugConfig()?.skipOnboarding) ?? false}
              onChange={setSkipOnboarding}
              class="flex items-center space-x-2"
            >
              <SwitchControl>
                <SwitchThumb />
              </SwitchControl>
              <SwitchLabel>Skip Onboarding</SwitchLabel>
            </Switch>
            <Switch
              defaultChecked={settingsStore.bgMusicEnabled}
              onChange={(value) => {
                if (value) {
                  settingsStoreActions.enableBgMusic();
                } else {
                  settingsStoreActions.disableBgMusic();
                }
              }}
              class="flex items-center space-x-2"
            >
              <SwitchControl>
                <SwitchThumb />
              </SwitchControl>
              <SwitchLabel>Music</SwitchLabel>
            </Switch>
            <Switch
              defaultChecked={settingsStore.compactNumbers}
              onChange={settingsStoreActions.toggleCompactNumbers}
              class="flex items-center space-x-2"
            >
              <SwitchControl>
                <SwitchThumb />
              </SwitchControl>
              <SwitchLabel>Compact Numbers</SwitchLabel>
            </Switch>
            <TelegramWebAppSettings />
          </div>
        </TabsContent>
        <TabsContent value="values" class="overflow-y-auto pb-10">
          <div>
            <h2 class="mb-2 font-bold">
              Server Values{" "}
              <Button
                size="sm"
                onClick={() => {
                  debugValuesActions.refetch();
                }}
              >
                Refresh
              </Button>
            </h2>
            <DebugObjectDisplay valuesObject={serverDebugValues()} />
          </div>
          <div class="pt-3">
            <h2 class="mb-2 font-bold">Client Values </h2>
            <DebugObjectDisplay valuesObject={clientDebugValues()} />
          </div>
        </TabsContent>
        <TabsContent value="store" class="overflow-y-auto pb-10">
          <DebugShop />
        </TabsContent>
        <TabsContent value="minigames" class="overflow-y-auto p-2 pb-10">
          <div>
            <Button
              class="mr-2"
              onClick={() => {
                let scene = game()?.currentScene as Ranch;
                scene.addEnergyBall({
                  currentHealth: 100,
                  maxHealth: 100,
                });
              }}
            >
              Add EnergyBall
            </Button>
            <Button
              onClick={() => {
                let scene = game()?.currentScene as Ranch;
                scene.removeEnergyBall();
              }}
            >
              Remove EnergyBall
            </Button>
          </div>
          <A href="/play/match">Try Match Prototype</A>
        </TabsContent>
        <TabsContent value="visuals" class="overflow-y-auto p-2 pb-10">
          <div>
            <h3 class="text-xl font-bold">Visuals</h3>
            <h2 class="py-2 text-lg">Lucky Visuals</h2>
            <div class="flex flex-wrap gap-2">
              <Button onClick={convertToLuckyBeast}>Convert to Lucky</Button>
              <Button onClick={convertToNormalBeast}>Convert to Normal</Button>
            </div>
            {/* <h2 class="py-2 text-lg">Change Beast Visually</h2>
            <div class="flex flex-wrap gap-2">
              <Button
                onClick={() => {
                  swapBeast(211);
                }}
              >
                Digby
              </Button>
              <Button
                onClick={() => {
                  swapBeast(215);
                }}
              >
                Toru
              </Button>
              <Button
                onClick={() => {
                  swapBeast(219);
                }}
              >
                Ravolt
              </Button>
            </div> */}
            <h2 class="py-2 text-lg">Halo</h2>
            <div class="flex flex-wrap gap-2">
              <Button
                onClick={() => {
                  setPlayerStore("beast", {
                    reincarnations: 1,
                  });
                }}
              >
                Add Halo
              </Button>
              <Button
                onClick={() => {
                  setPlayerStore("beast", {
                    reincarnations: 0,
                  });
                }}
              >
                Remove Halo
              </Button>
            </div>
          </div>
        </TabsContent>
        <TabsContent value="featureflags" class="overflow-y-auto p-2 pb-10">
          <div>
            <h3 class="text-xl font-bold">Feature Flags</h3>
            <For each={posthog.featureFlags.getFlags() || []}>
              {(featureFlagString) => (
                <div class="flex items-center gap-2">
                  <h4 class="text-lg">{featureFlagString}</h4>
                  <span class="italic">
                    {posthog.isFeatureEnabled(featureFlagString)
                      ? "Enabled"
                      : "Disabled"}
                  </span>
                </div>
              )}
            </For>
          </div>
        </TabsContent>
      </Tabs>
    </PageContainer>
  );
}

function DebugShop() {
  const [shopListings, shopListingsActions] = createResource(() =>
    ShopService.getShopListings(),
  );

  createEffect(() => {
    if (connection()) {
      connection()?.on("ItemPurchased", (itemId) => {
        showToast({ title: `Item ${itemId} Purchased`, variant: "success" });
      });
    }
  });

  createEffect(() => {
    console.log("SHOP ITEMS", shopListings());
  });

  return (
    <div class="flex flex-col gap-2 p-4">
      <Index each={shopListings()}>
        {(shopListing) => (
          <div class="flex gap-1">
            <Button
              disabled={shopListing().isPurchased}
              onClick={() => {
                ShopService.initiatePurchase({
                  listingId: shopListing().id,
                }).then((r) => {
                  console.log("DATA", r);
                  WebApp.openInvoice(r.invoiceLink, (cb) => {
                    console.log("INVOICE CALLBACK", cb);
                    shopListingsActions.refetch();
                    refreshPlayerState();
                  });
                });
              }}
            >
              {shopListing().isPurchased
                ? "PURCHASED"
                : `Buy ${shopListing().name} (${shopListing().starsCost} stars)`}
            </Button>
            <Button
              class="flex-1 bg-blue-800"
              disabled={shopListing().isPurchased}
              onClick={() => {
                setPlayerStore("beast", {
                  equippedHatId: shopListing().id,
                });
              }}
            >
              {`Equip ${shopListing().name}`}
            </Button>
          </div>
        )}
      </Index>
    </div>
  );
}
