import { Actor, vec, SpriteSheet, Animation, range } from "excalibur";

import { Resources } from "../resources";
import { Ranch } from "../scenes/ranch";

export class Coin extends Actor {
  constructor(x: number, y: number) {
    super({ x: x, y: y });
  }

  public onInitialize(): void {
    this.name = 'coin';
    const scene = this.scene as Ranch;
    this.scale = vec(scene.scale, scene.scale);
    this.anchor = vec(0.5, 1);

    const spritesheet = SpriteSheet.fromImageSource({
      image: Resources.Coin,
      grid: {
        rows: 1,
        columns: 6,
        spriteWidth: 16,
        spriteHeight: 16,
      },
    });
    const anim = Animation.fromSpriteSheet(spritesheet, range(0, 5), 100);
    this.graphics.use(anim);
  }
}