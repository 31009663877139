import { useNavigate } from "@solidjs/router";
import { Button } from "./ui/button";

export function CloseButton() {
  const navigate = useNavigate();

  return (
    <div class="absolute right-2 top-2 z-50">
      <Button
        variant="ghost"
        class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
        onClick={() => navigate("/play")}
      >
        <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
      </Button>
    </div>
  );
}
