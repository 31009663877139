import posthog from "posthog-js";
import { Show } from "solid-js";
import { Modal } from "@/components/ui/modal.tsx";
import { createQuery } from "@tanstack/solid-query";
import { RaffleTicketsService } from "@/api-client";
import { useNavigate } from "@solidjs/router";
import { Button } from "./ui/button";
import { raffleTicketImg, rewardBg } from "@/assets/images";
import { ftueBoosterActions } from "./DialogBoostersOverlay";

export function createRaffleTicketsQuery() {
  return createQuery(() => ({
    queryKey: ["raffleTickets"],
    queryFn: RaffleTicketsService.getRaffleTickets,
  }));
}

function RaffleTicketsModalContent(props: {
  closeModal: () => void;
  ticketsCount: number;
}) {
  const navigate = useNavigate();

  const startFTUEBooster = () => {
    navigate("/play/ranch");
    ftueBoosterActions.initiate();
    props.closeModal();
  };

  return (
    <div class="relative flex flex-col justify-center rounded-xl bg-[#FFB3B3]">
      <div class="absolute -right-3 -top-3">
        <Button
          variant="ghost"
          class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
          onClick={startFTUEBooster}
        >
          <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
        </Button>
      </div>
      <div class="flex items-center justify-center rounded-t-xl bg-[#E68686] px-2 pt-1 text-center">
        <h3 class="eazy-chat text-outline text-2xl text-white">
          You Got Raffle Tickets
        </h3>
      </div>
      <div class="flex flex-col gap-4 rounded-b-lg bg-[#FFE7DF] p-2">
        <div class="flex items-center justify-center">
          <div
            class="relative mx-1 my-2 flex h-[115px] w-[155px] items-center justify-center rounded-xl bg-[#E68686]"
            style={{
              "background-image": `url(${rewardBg})`,
              "background-size": "cover",
              "background-position": "center",
              "background-repeat": "no-repeat",
            }}
          >
            <img
              src={raffleTicketImg}
              class="h-[90px] w-[90px] object-contain"
            />
            <Show when={props.ticketsCount > 0}>
              <span class="eazy-chat text-outline absolute bottom-2 text-center text-2xl leading-tight text-white">
                x{props.ticketsCount}
              </span>
            </Show>
          </div>
        </div>
        <div class="flex items-center justify-center px-6">
          <ul class="list-disc space-y-2 rounded-b-lg text-sm text-black">
            <li>
              Based on your progress since the previous raffle snapshot on
              7-30-24, you earned {props.ticketsCount} Raffle Tickets!
            </li>
            <li>
              Prizes include 2 Million $KURO, Silver Tickets, Boosters, rare
              Treasure Eggs and more!
            </li>
            <li>
              You’ll now be able to buy Raffle Tickets each day from the{" "}
              <span class="font-bold">Boosters</span> page using Coins!
            </li>
          </ul>
        </div>
        <div class="flex items-center justify-center pb-4">
          <Button
            onClick={startFTUEBooster}
            size="sm"
            class="eazy-chat text-outline relative z-10 min-w-fit border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
          >
            <div class="rounded-md"></div>
            <span class="z-10 text-white">Yay!</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export function RaffleTicketsModal(props: {
  onClose: () => void;
  ticketsCount: number;
}) {
  return (
    <Modal open={true}>
      <RaffleTicketsModalContent
        ticketsCount={props.ticketsCount}
        closeModal={() => {
          props.onClose();
          posthog.capture("raffle_tickets_modal_closed");
        }}
      />
    </Modal>
  );
}
