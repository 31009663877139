export const gameConfig = {
  happiness: {
    bufferTimeSeconds: 7200, //Beast starts to get sad after 2 hours without being fed
    decayPerSecond: 1 / 36, //Beast reaches 0% happiness at 3 hours
  },
  energy: {
    replenishRatePerSecond: 1 / 108, //fully replenished after 3 hours
  },
  leveling: {
    baseExp: 15,
    expGrowthRate: 1.2,
  },
  coins: {
    earningUnit: "hour" as "minute" | "hour" | "second",
    beastEarnPerLevel: 10,
    beastCoinsPerLevel: 10,
  },
  upgrades: {
    maxLevel: 99,
  },
  luckies: {
    luckyShardValue: 2,
  }
};
