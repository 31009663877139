import { XIcon } from "@/assets/extra-icons";
import {
  Dialog,
  DialogDescriptionProps,
  DialogRootProps,
} from "@kobalte/core/dialog";
import { rewardsOverlay } from "@/assets/images";
import { mergeProps } from "solid-js";
import { cn } from "@/lib/utils";

interface ModalProps {
  title?: string;
  showRewardsOverlay?: boolean;
  onPointerDownOutside?: () => void;
  hideBlur?: boolean;
  alignItems?: "items-center" | "items-start" | "items-end";
}

const Modal = (props: ModalProps & DialogRootProps) => {
  const mergedProps = mergeProps(
    {
      alignItems: "items-center",
    },
    props,
  );

  return (
    <Dialog open={props.open} onOpenChange={props.onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay
          class="fixed inset-0 z-[200] bg-black bg-opacity-50"
          style={
            !props.hideBlur
              ? {
                  "backdrop-filter": "blur(4px) contrast(0.9) brightness(0.7)",
                }
              : undefined
          }
        />
        {props.showRewardsOverlay && (
          <div
            style={{
              "min-width": "100vw",
              "min-height": "100vh",
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              "background-image": `url(${rewardsOverlay})`,
              "background-position": "center",
              "background-size": "cover",
              opacity: "0.8",
              "z-index": "41",
            }}
          ></div>
        )}
        <div
          class={cn(
            "fixed inset-0 z-[201] flex items-center justify-center",
            mergedProps.alignItems,
          )}
        >
          <Dialog.Content
            class="relative w-full max-w-md rounded-lg bg-transparent p-4"
            onPointerDownOutside={props.onPointerDownOutside}
          >
            <div class="absolute right-0 top-0"></div>
            {props.title && (
              <div class="mb-4 flex items-center justify-between">
                <Dialog.Title class="eazy-chat text-outline mt-3 text-xl font-bold text-white">
                  {props.title}
                </Dialog.Title>
              </div>
            )}
            {props.children}
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog>
  );
};

const ModalDescription = (props: DialogDescriptionProps) => {
  return <Dialog.Description class="text-gray-700" {...props} />;
};

export type { ModalProps };
export { Modal, ModalDescription };
