import { Actor, vec } from "excalibur";
import { Resources } from "../resources";

const SPEED = 20;

export class OnboardingArrowDown extends Actor {
  constructor(x: number, y: number, visible: boolean = true) {
    super({ x: x, y: y });
    this.graphics.visible = visible;
  }

  public onInitialize(): void {
    this.name = "arrow-down";
    this.scale = vec(0.5, 0.5);
    this.anchor = vec(0.5, 0.5);
    this.z = 100;

    this.graphics.use(Resources.ArrowDown.toSprite());
    this.addUpDownAnimation();
  }

  private addUpDownAnimation(): void {
    this.actions.repeatForever((repeatCtx) => {
      repeatCtx.moveBy(0, 10, SPEED).moveBy(0, -10, SPEED);
    });
  }

  public hide(): void {
    this.graphics.visible = false;
  }

  public show(): void {
    this.graphics.visible = true;
  }
}
