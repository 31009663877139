import type { ClassValue } from "clsx";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import {
  ImpactHapticFeedbackStyle,
  initHapticFeedback,
} from "@tma.js/sdk-solid";
import { SnapshotValue } from "@/components/types.ts";
import { settingsStore } from "@/components/settings-store";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

let hapticFeedback: any;

const styleToPattern: Record<ImpactHapticFeedbackStyle, number> = {
  light: 30,
  medium: 60,
  heavy: 90,
  rigid: 100,
  soft: 15,
};

export function vibrate(style: ImpactHapticFeedbackStyle) {
  if (window.navigator.vibrate) {
    window.navigator.vibrate(styleToPattern[style]);
  } else {
    if (!hapticFeedback) hapticFeedback = initHapticFeedback();
    hapticFeedback.impactOccurred(style);
  }
}

export function makeSnapshotValue<T>(value: T): SnapshotValue<T> {
  return {
    value,
    updatedAt: new Date(),
  };
}

export function formatCompactNumber(number: number) {
  if (!settingsStore.compactNumbers) {
    return number;
  }
  const units = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "Q" }, // Quadrillion
    { value: 1e18, symbol: "Qt" }, // Quintillion
    { value: 1e21, symbol: "Sx" }, // Sextillion
    { value: 1e24, symbol: "Sp" }, // Septillion
    { value: 1e27, symbol: "O" }, // Octillion
    { value: 1e30, symbol: "N" }, // Nonillion
    { value: 1e33, symbol: "D" }, // Decillion
  ];

  const unit = units
    .slice()
    .reverse()
    .find((unit) => number >= unit.value);
  if (!unit) return "0";

  const num = number / unit.value;
  if (unit.symbol === "") return num.toFixed(0);
  let finalNum = num.toFixed(num >= 1000 ? 0 : 2);
  // Remove trailing zeros and the decimal point if it's unnecessary
  finalNum = finalNum.replace(/\.0+$/, "").replace(/(\.\d*[1-9])0+$/, "$1");
  return finalNum + unit.symbol;
}

export function showOneDecimal(num: number) {
  // Need to show 1 decimal only if it has one
  return num % 1 === 0 ? num : num.toFixed(1);
}

export function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /android|webos|iphone|ipad|ipod/i.test(userAgent);
}
