import { Modal } from "./ui/modal";
import { Button } from "./ui/button";
import { createSignal, For, Suspense } from "solid-js";
import { Checkbox, CheckboxControl, CheckboxLabel } from "./ui/checkbox";

const LIST = [
  "Borderline levels of malicious activity have been detected on your account.",
  "Continued abuse will result in a permanent ban, you will receive no further warnings.",
];

function Content(props: { closeModal: () => void }) {
  const [checked, setChecked] = createSignal(false);

  return (
    <div class="relative flex flex-col justify-center rounded-xl bg-[#B7330B]">
      {/* <div class="absolute -right-3 -top-3">
        <Button
          variant="ghost"
          class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
          onClick={props.closeModal}
        >
          <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
        </Button>
      </div> */}
      <div class="flex flex-col items-center justify-center rounded-t-xl bg-[#B7330B] px-2 pb-1 pt-2 text-center">
        <h3 class="eazy-chat text-outline text-3xl text-white">Warning</h3>
      </div>
      <div class="rounded-b-xl bg-[#B7330B]">
        <div class="rounded-b-xl bg-[#B7330B]">
          <ul class="mx-2 flex list-disc flex-col items-center justify-center gap-2 bg-[#CF7054] p-2">
            <For each={LIST}>
              {(item) => (
                <li class="w-[200px] text-sm font-bold text-white">
                  {item}
                </li>
              )}
            </For>
          </ul>
          <div class="flex items-center justify-center px-8 py-4">
            <Checkbox
              class="flex items-center space-x-4"
              checked={checked()}
              onChange={setChecked}
            >
              <CheckboxControl class="flex h-6 w-6 items-center justify-center border-2 border-white data-[checked]:bg-white data-[checked]:text-black" />
              <CheckboxLabel class="text-sm font-medium leading-none text-white">
                Yes, I understand that I will be permanently banned if I do not
                play fair.
              </CheckboxLabel>
            </Checkbox>
          </div>
          <div class="flex items-center justify-center pb-4">
            <Button
              onClick={() => props.closeModal()}
              disabled={checked() === false}
              size="sm"
              class="eazy-chat text-outline relative z-10 min-w-fit border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
            >
              <div class="rounded-md"></div>
              <span class="z-10 pt-1 text-white">I Understand</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function WarningBanModal(props: {
  isModalOpen: boolean;
  closeModal: () => void;
}) {
  return (
    <Modal open={props.isModalOpen}>
      <Suspense fallback={<div>Loading...</div>}>
        <Content closeModal={props.closeModal} />
      </Suspense>
    </Modal>
  );
}
