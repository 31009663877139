const storedUser = localStorage.getItem("test-user");

// @ts-ignore
window.setRandomTestUser = () => {
  const user = {
    id: Math.floor(Math.random() * 100000000),
    first_name: "Random",
    last_name: "User",
    username: `user_${Math.floor(Math.random() * 100000)}`,
    language_code: "en",
    allows_write_to_pm: true,
  };

  console.log("TEST USER IS:", user.username);
  localStorage.setItem("test-user", JSON.stringify(user));
  location.reload();
};

export const testUser = storedUser
  ? JSON.parse(storedUser)
  : {
    id: 6923061953,
    first_name: "LOCAL",
    last_name: "",
    username: "local_user",
    language_code: "en",
    allows_write_to_pm: true,
  };

const testRefCode = "AA823ECE" as string;
const startAppParam = testRefCode != "" ? `ref-${testRefCode}` : "";
export const testToken = `query_id=AAHBiqUcAwAAAMGKpRzMNv97&user=${encodeURIComponent(
  JSON.stringify(testUser),
)}&auth_date=1718724176&hash=92940b8b5f43d895de104b5d09fa58e62ea995fc77e0f6251336baafcd80c436`;
export const testUserId = testUser.id;
