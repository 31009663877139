import { Switch, Match, createEffect, onMount } from "solid-js";
import { DateTime } from "luxon";
import { playerStore } from "./player-store"; // Adjust import paths as needed
import { createStore } from "solid-js/store";
import {
  createRaffleTicketsQuery,
  RaffleTicketsModal,
} from "@/components/RaffleTicketsModal.tsx";
import { makePersisted } from "@solid-primitives/storage";
import { eventBus } from "@/components/event-bus.ts";
import posthog from "posthog-js";
import { ReincarnationModal } from "./ReincarnationModal";
import { createQuery } from "@tanstack/solid-query";
import { BansService, RaffleTicketsService } from "@/api-client";
import { useSearchParams } from "@solidjs/router";
import { WarningBanModal } from "./WarningBanModal";

const [persistedModalOpenState, setPersistedModalOpenState] = makePersisted(
  createStore({
    energyBallModal: true,
    beastLevelingUpdateModal: true,
    boosterTicketsModal: true,
    warningBanModal: true,
  }),
  {
    name: "hud-show-alerts",
    storage: localStorage,
  },
);
export const [modalOpenState, setModalOpenState] = createStore({
  reincarnationModal: true,
});

export function WelcomeMessages() {
  const [searchParams, setSearchParams] = useSearchParams();
  const raffleSnapshotTicketsQuery = createQuery(() => ({
    queryKey: ["raffleSnapshotTickets"],
    queryFn: RaffleTicketsService.getTicketsSinceFirstSnapshot,
  }));
  const banStateQuery = createQuery(() => ({
    queryKey: ["banState"],
    queryFn: async () => {
      const result = await BansService.getBanState();
      return result ?? null;
    },
  }));

  createEffect(() => {
    eventBus.on("openEnergyBallExplainerModal", () =>
      setPersistedModalOpenState("energyBallModal", true),
    );
    console.log({
      warningBanModal: persistedModalOpenState.warningBanModal,
      asd: persistedModalOpenState.warningBanModal !== false,
    });
  });

  onMount(() => {
    if (
      banStateQuery.data?.status !== "Warning" &&
      banStateQuery.data?.status !== "Banned"
    ) {
      setPersistedModalOpenState({ warningBanModal: undefined });
    }

    if (Boolean(searchParams.reincarnationModal)) {
      setModalOpenState("reincarnationModal", true);
      setSearchParams({ reincarnationModal: undefined });
    } else if (playerStore.beast.level < 50) {
      setModalOpenState("reincarnationModal", false);
    }
  });

  return (
    <Switch>
      <Match when={persistedModalOpenState.warningBanModal !== undefined}>
        <WarningBanModal
          closeModal={() => {
            setPersistedModalOpenState("warningBanModal", false);
          }}
          isModalOpen={banStateQuery.data?.status === "Warning"}
        />
      </Match>
      {/* <Match
        when={
          isUserCreatedAfterBeastLevelingUpdate() &&
          persistedModalOpenState.beastLevelingUpdateModal
        }
      >
        <BeastLevelingModal
          closeModal={() =>
            setPersistedModalOpenState("beastLevelingUpdateModal", false)
          }
          isModalOpen={true}
        />
      </Match> */}
      {/* <Match
        when={isEnergyBallEnabled() && persistedModalOpenState.energyBallModal}
      >
        <EnergyBallExplainerModal
          closeModal={() =>
            setPersistedModalOpenState("energyBallModal", false)
          }
          isModalOpen={true}
        />
      </Match> */}
      <Match
        when={
          posthog.isFeatureEnabled("boosters") &&
          persistedModalOpenState.boosterTicketsModal !== false
        }
      >
        <RaffleTicketsModal
          ticketsCount={raffleSnapshotTicketsQuery.data?.count || 0}
          onClose={() =>
            setPersistedModalOpenState("boosterTicketsModal", false)
          }
        />
      </Match>
      <Match
        when={
          modalOpenState.reincarnationModal &&
          playerStore.beast.reincarnations < 1
        }
      >
        <ReincarnationModal
          closeModal={() => {
            console.log("here");
            setModalOpenState("reincarnationModal", false);
          }}
          isModalOpen={true}
        />
      </Match>
    </Switch>
  );
}
