import { IS_RANCH_PROD, isDevelopment, isProduction } from "@/config";
import * as Sentry from "@sentry/solid";
import { WebApp } from "@/twa-sdk-updated/core";
import { ErrorBoundary } from "solid-js";

if (isProduction) {
  const dsn =
    import.meta.env.VITE_SENTRY_DSN ||
    "https://27206234f9ab468bf826ca33b385712e@errors.kuroro-tools.com/5";

  Sentry.init({
    dsn,
    integrations: [],
    replaysOnErrorSampleRate: 0,
    environment: IS_RANCH_PROD ? "production" : isDevelopment ? "local" : "staging",
  });

  setSentryUser();
}

export function setSentryUser() {
  console.log("Setting Sentry user");
  if (!WebApp.initDataUnsafe.user) return;

  const userId = WebApp.initDataUnsafe.user.id;
  const username = WebApp.initDataUnsafe.user.username;

  Sentry.setUser({ id: userId, username });
  console.log("Sentry user set", userId, username);
}

export const SentryErrorBoundary =
  Sentry.withSentryErrorBoundary(ErrorBoundary);
