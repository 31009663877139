import { Game } from "@/game/game.ts";
import { Ranch } from "@/game/scenes/ranch.ts";
import {
  EnergyBallsService,
  type GetApiEnergyBallsGetEnergyBallStateResponse,
} from "@/api-client";
import { eventBus } from "@/components/event-bus.ts";
import posthog from "posthog-js";

export function isEnergyBallEnabled() {
  return posthog.isFeatureEnabled("energy_ball");
}

class EnergyBallHandler {
  state: GetApiEnergyBallsGetEnergyBallStateResponse | null = null;
  async getState() {
    if (this.state) return this.state;

    try {
      this.state = await EnergyBallsService.getEnergyBallState();
      return this.state;
    } catch (e) {
      console.error("Error getting energy ball state", e);
      throw e;
    }
  }
  takeHitsCombo(hits: number) {
    if (!this.state) {
      return;
    }

    EnergyBallsService.takeHitsCombo({
      ballId: this.state.id,
      requestBody: {
        hits,
      },
    })
      .then(() => {
        console.log("Sent combo", hits);
      })
      .catch((e) => {
        console.error("Error sending combo", e);
      });
  }

  async updateState() {
    try {
      this.state = await EnergyBallsService.getEnergyBallState();
    console.log("Energy ball state updated", this.state);
    } catch (e) {
      console.error("Error updating energy ball state", e);
      throw e;
    }
  }
}

const energyBallHandler = new EnergyBallHandler();

export async function initializeEnergyBall(
  game: Game,
  fetchEnergyBallStateAgain = false,
) {
  if (!isEnergyBallEnabled()) {
    return;
  }

  const scene = game.currentScene;
  if (!(scene instanceof Ranch)) {
    return;
  }

  if (fetchEnergyBallStateAgain) {
    await energyBallHandler.updateState();
  }

  const ballState = await energyBallHandler.getState();

  if (ballState.isDestroyed) {
    console.log("Energy ball is destroyed");
    return;
  }

  scene.addEnergyBall({
    currentHealth: ballState.currentHealth,
    maxHealth: ballState.maxHealth,
  });

  eventBus.on("energyBallComboEnded", (hits: number) =>
    energyBallHandler.takeHitsCombo(hits),
  );
}
