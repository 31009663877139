import { Beast, Player } from "@/components/types.ts";
import { calculateHappinessDepletesAt } from "@/components/lib/happiness.ts";
import { gameConfig } from "@/components/lib/game-config.ts";

const getEarningTimeBase = () => {
  switch (gameConfig.coins.earningUnit) {
    case "minute":
      return 1000 * 60;
    case "hour":
      return 1000 * 60 * 60;
    case "second":
      return 1000 * 60 * 60 * 24;
    default:
      throw new Error(`Unknown earning unit: ${gameConfig.coins.earningUnit}`);
  }
};
const earningTimeBase = getEarningTimeBase(); // 1 minute

export const calculateCoinsBalance = (
  coinsSnapshot: Player["coinsSnapshot"],
  earningRate: number,
  earningEndsAt: Date,
) => {
  const endTime = Math.min(earningEndsAt.getTime(), new Date().getTime());
  const elapsedTime = positiveOrZero(
    endTime - coinsSnapshot.updatedAt.getTime(),
  );
  const coinsEarned = Math.floor((elapsedTime * earningRate) / earningTimeBase);
  return coinsSnapshot.value + coinsEarned;
};

function positiveOrZero(value: number) {
  return value < 0 ? 0 : value;
}

export const calculateCoinsEarningRate = (
  earningRate: number,
  earningEndsAt: Date,
) => {
  return new Date().getTime() < earningEndsAt.getTime() ? earningRate : 0;
};
