import { Actor, vec } from "excalibur";

import { Resources } from "@/game/resources";
import { Config } from "@/game/config";

export class Shard extends Actor {
  public isLucky: boolean = false;
  
  constructor(x: number, y: number, isLucky: boolean = false) {
    super({ x: x, y: y });
    this.isLucky = isLucky;
  }

  public onInitialize(): void {
    this.name = 'shard';
    let scale = Config.scale;
    this.scale = vec(scale, scale);
    this.anchor = vec(0.5, 1);

    let sprite = Resources.Shard.toSprite();
    if (this.isLucky) {
      sprite = Resources.LuckyShard.toSprite();
      console.log("made lucky shard");
    }
    this.graphics.use(sprite);
  }
}