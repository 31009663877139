import * as ex from "excalibur";

export class TimerLabel extends ex.Label {
  constructor(x: number, y: number) {
    super({
      text: '',
      pos: new ex.Vector(x, y),
      font: new ex.Font({
        family: 'Arial',
        size: 24,
        unit: ex.FontUnit.Px
      }),
      color: ex.Color.White
    });
  }

  onInitialize(engine: ex.Engine<any>): void {
    this.anchor = new ex.Vector(0.5, 0.5);
  }

  updateTime(remainingTime: number) {
    this.text = `Time: ${Math.ceil(remainingTime)}s`;
  }
}