import {
  Engine,
  Actor,
  vec,
  SpriteSheet,
  Animation,
  range,
  ParticleEmitter,
  EmitterType,
  Vector,
  Color,
} from "excalibur";

import { Resources } from "../resources";
import { Config } from "../config";

export class LeafEmitter extends Actor {
  public onInitialize(engine: Engine<any>): void {
    const viewport = engine.screen.getScreenBounds();
    let scale = Config.scale;

    const spritesheet = SpriteSheet.fromImageSource({
      image: Resources.Leaf,
      grid: {
        rows: 5,
        columns: 6,
        spriteWidth: 12,
        spriteHeight: 12,
      },
    });
    let anim = Animation.fromSpriteSheet(spritesheet, range(0, 29), 100);
    anim.scale = vec(scale, scale);

    const emitter = new ParticleEmitter({
      x: 0,
      y: -64,
      width: viewport.width,
      height: 64,
      emitterType: EmitterType.Rectangle,
      radius: 28,
      isEmitting: true,
      emitRate: 1,
      particle: {
        graphic: anim,
        opacity: 1.0,
        fade: true,
        life: 10000,
        minVel: 15,
        maxVel: 34,
        minAngle: 0,
        maxAngle: 6.2,
        maxSize: 10,
        minSize: 1,
        startSize: 0,
        endSize: 0,
        acc: vec(25, 49),
        beginColor: Color.White,
        endColor: Color.Transparent,
      },
    });

    this.scene?.add(emitter);
  }
}
