import posthog from "posthog-js";
import { Modal } from "@/components/ui/modal.tsx";
import { useNavigate } from "@solidjs/router";
import { Button } from "./ui/button";
import { bitgetKB, raffleTicketImg, rewardBg } from "@/assets/images";
import { createQuery } from "@tanstack/solid-query";
import { QuestsService } from "@/api-client";
import { doGoQuestAction, PlayerQuest } from "@/routes/QuestsRoute";

function BitgetModalContent(props: { closeModal: () => void }) {
  const navigate = useNavigate();

  const questId = posthog.getFeatureFlagPayload("bitget");

  const playerQuestQuery = createQuery(() => ({
    queryKey: ["bitgetQuest", questId],
    queryFn: () =>
      QuestsService.getQuest({
        questId: questId?.toString() || "",
      }).then((res) => res as unknown as PlayerQuest),
    enabled: !!questId,
  }));

  return (
    <div class="relative flex flex-col justify-center rounded-xl bg-[#FFB3B3]">
      <div class="absolute -right-3 -top-3">
        <Button
          variant="ghost"
          class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
          onClick={props.closeModal}
        >
          <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
        </Button>
      </div>
      <div class="flex items-center justify-center rounded-t-xl bg-[#10A6AF] px-2 pt-1 text-center">
        <h3 class="eazy-chat text-outline text-2xl text-white">
          Bitget Partnership
        </h3>
      </div>
      <div class="flex flex-col gap-4 rounded-b-lg bg-[#FFE7DF] p-2">
        <div class="flex items-center justify-center">
          <div
            class="relative mx-1 my-2 flex h-full w-full items-center justify-center rounded-xl bg-[#10A6AF] p-6"
            style={{
              "background-image": `url(${rewardBg})`,
              "background-size": "cover",
              "background-position": "center",
              "background-repeat": "no-repeat",
            }}
          >
            <img src={bitgetKB} class="h-full w-full object-contain" />
          </div>
        </div>
        <div class="flex items-center justify-center px-6">
          <ul class="list-disc space-y-2 rounded-b-lg text-sm text-black">
            <li>
              Kuroro is announcing a strategic partnership with Bitget - the
              world’s leading cryptocurrency exchange!
            </li>
            <li>
              Win up to 200 BGB on your first trade with Bitget on the Kuroro
              Beasts campaign!
            </li>
            <li>
              Earn <span class="font-bold">300 Shards</span> when you complete
              this Quest!
            </li>
          </ul>
        </div>
        <div class="flex items-center justify-center pb-4">
          <Button
            onClick={() => {
              if (questId) {
                if (playerQuestQuery.data?.status == "Active") {
                  QuestsService.moveQuestToVerifiable({
                    questId: playerQuestQuery.data.quest.id,
                  }).then(() => {
                    doGoQuestAction(playerQuestQuery.data!);
                    playerQuestQuery.refetch();
                    navigate("/play/quests?questId=" + questId.toString());
                  });
                } else if (playerQuestQuery.data?.status == "Verifiable") {
                  navigate("/play/quests?questId=" + questId.toString());
                } else {
                  navigate("/play/quests");
                }
              } else {
                navigate("/play/quests");
              }
              props.closeModal();
            }}
            size="sm"
            class="eazy-chat text-outline relative z-10 min-w-[150px] border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-xl pt-1 leading-4 hover:opacity-70"
          >
            <div class="rounded-md"></div>
            <span class="z-10 text-white">GO</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export function BitgetModal(props: {
  isModalOpen: () => boolean;
  setIsModalOpen: (value: boolean) => void;
  closeModal: () => void;
}) {
  return (
    <Modal
      open={props.isModalOpen()}
      onOpenChange={props.setIsModalOpen}
      onPointerDownOutside={props.closeModal}
    >
      <BitgetModalContent closeModal={props.closeModal} />
    </Modal>
  );
}
