import {
  createContext,
  useContext,
  createSignal,
  JSX,
  Accessor,
  onMount,
  createEffect,
  onCleanup,
} from "solid-js";
import {
  ActionConfiguration,
  Locales,
  TonConnectUI,
  UIPreferences,
  WalletsListConfiguration,
} from "@tonconnect/ui";
import type {
  ITonConnect,
  Wallet,
  WalletInfoWithOpenMethod,
  ConnectedWallet,
  TonConnectUiOptions,
} from "@tonconnect/ui";

export type TonConnectUIProviderProps = {
  children: JSX.Element;
  manifestUrl?: string;
  connector?: ITonConnect;
  restoreConnection?: boolean;
  language?: Locales;
  widgetRootId?: string;
  uiPreferences?: UIPreferences;
  walletsListConfiguration?: WalletsListConfiguration;
  actionsConfiguration?: ActionConfiguration;
  enableAndroidBackHandler?: boolean;
};

const BUTTON_ROOT_ID = "ton-connect-button";

// Create a context for TonConnectUI
const TonConnectUIContext =
  createContext<Accessor<TonConnectUI | undefined>>(undefined);

export function TonWalletProvider(props: TonConnectUIProviderProps) {
  const baseUrl = window.location.origin;
  const [tonConnectUI, setTonConnectUI] = createSignal<
    TonConnectUI | undefined
  >(undefined);

  onMount(() => {
    // we cannot use props here bc we will access to children and we get this error
    // see this https://stackoverflow.com/questions/76974967/why-does-not-a-context-provider-at-the-top-get-called-before-sub-components-usin
    setTonConnectUI(
      new TonConnectUI({
        manifestUrl: `${baseUrl}/tonconnect-manifest.json`,
        buttonRootId: null,
        widgetRootId: "please-work",
      }),
    );
  });

  onCleanup(() => {
    // tonConnectUI()?.disconnect();
  });

  return (
    <TonConnectUIContext.Provider value={tonConnectUI}>
      <div
        onClick={(e) => {
          console.log("CLICKCLCLC");
          e.preventDefault();
        }}
        style={{
          "z-index": 10000,
          position: "absolute",
          "pointer-events": "auto",
        }}
        id="please-work"
      />
      {props.children}
    </TonConnectUIContext.Provider>
  );
}

/**
 * Use it to get access to the `TonConnectUI` instance and UI options updating function.
 */
export function useTonConnectUI(): [
  Accessor<TonConnectUI | null>,
  (options: TonConnectUiOptions) => void,
] {
  const tonConnectUI = useContext(TonConnectUIContext);

  if (!tonConnectUI) {
    throw new Error("useTonConnectUI must be used within a TonWalletProvider");
  }

  const [uiInstance, setUiInstance] = createSignal<TonConnectUI | null>(
    tonConnectUI() || null,
  );
  const setOptions = (options: TonConnectUiOptions) => {
    if (uiInstance()) {
      uiInstance()!.uiOptions = options;
      // setUiInstance(uiInstance());
    }
  };

  return [uiInstance, setOptions];
}

/**
 * Use it to get user's current ton wallet. If wallet is not connected hook will return null.
 */

export function useTonWallet() {
  const [tonConnectUI] = useTonConnectUI();
  const [wallet, setWallet] = createSignal<
    Wallet | (Wallet & WalletInfoWithOpenMethod) | null
  >(tonConnectUI()?.wallet || null);

  createEffect(() => {
    const wallet = tonConnectUI()?.wallet;

    if (wallet) setWallet(wallet);

    tonConnectUI()?.onStatusChange((value: ConnectedWallet | null) => {
      setWallet(value);
    });
  });

  onCleanup(() => {
    tonConnectUI()?.onStatusChange((value: ConnectedWallet | null) => {
      setWallet(value);
    });
  });

  return wallet;
}

interface TonConnectButtonProps {
  class?: string;
  style?: { [key: string]: string | number };
}

export function TonConnectButton(props: TonConnectButtonProps) {
  const [_, setOptions] = useTonConnectUI();

  createEffect(() => {
    setOptions({ buttonRootId: BUTTON_ROOT_ID });
  }, setOptions);

  onCleanup(() => {
    setOptions({ buttonRootId: null });
  });

  return <div id={BUTTON_ROOT_ID} class={props.class} style={props.style} />;
}
