import { gameConfig } from "@/components/lib/game-config.ts";

export function getExperienceRequirement(level: number) {
  const { baseExp, expGrowthRate } = gameConfig.leveling;

  const requiredExp = Math.floor(baseExp * expGrowthRate ** (level - 1));

  return Math.min(requiredExp, 300);
}

export function getCoinLevelReward(level: number) {
  return level * gameConfig.coins.beastCoinsPerLevel;
}
