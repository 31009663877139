import {
  coinIcon,
  energyBallPixel,
  energyDrink,
  hourglassImage,
  raffleTicketImg,
  rewardBg,
  starIcon,
} from "@/assets/images";
import createCountdownTimer from "./lib/countdown-timer";
import { DateTime } from "luxon";
import { createEffect, createSignal, For, Match, Show, Switch } from "solid-js";
import { createQuery } from "@tanstack/solid-query";
import { playerStoreSelector } from "./player-store";
import { cn, formatCompactNumber } from "@/lib/utils";
import { Card, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import { BuyBoosterItemModal } from "./BuyBoosterItemModal";
import {
  CoinShopItemListing,
  CoinsShopService,
  PlayerShopListing,
  ShopService,
} from "@/api-client";
import { EnergyBallsService } from "@/api-client";
import { unwrap } from "solid-js/store";

export const BOOSTER_IMAGES = {
  "energy-ball": energyBallPixel,
  "raffle-ticket": raffleTicketImg,
  "energy-drink": energyDrink,
};

function BoosterCard(props: {
  boosterItem: CoinShopItemListing;
  openBuyModal: (boosterItem: CoinShopItemListing) => void;
  starShopListingQueryData: PlayerShopListing[];
}) {
  const boosterShopItem = () =>
    props.starShopListingQueryData.find(
      (listing) => listing.id === props.boosterItem.itemId,
    );
  const energyBallStateQuery = createQuery(() => ({
    queryKey: ["energyBallState"],
    queryFn: EnergyBallsService.getEnergyBallState,
    enabled: props.boosterItem.itemId === "energy-ball",
  }));
  const isEnergyBallDestroyed = () => {
    if (energyBallStateQuery.data) {
      return energyBallStateQuery.data.isDestroyed;
    } else {
      return false;
    }
  };

  const canBuy = () =>
    playerStoreSelector.coins() >= props.boosterItem.coinCost;

  const buttonText = () => {
    if (canBuy()) {
      return (
        <div class="flex items-center gap-2">
          Buy{" "}
          <div class="flex items-center justify-center gap-1">
            <img src={coinIcon} class="h-5 w-5 object-contain" />
            <span class="mt-1">
              {formatCompactNumber(props.boosterItem.coinCost)}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div class="flex items-center gap-2">
          Need{" "}
          <div class="flex items-center justify-center gap-1">
            <img src={coinIcon} class="h-5 w-5 object-contain" />
            <span class="mt-1">
              {formatCompactNumber(props.boosterItem.coinCost)}
            </span>
          </div>
        </div>
      );
    }
  };

  return (
    <div class="flex flex-1" style={{ width: "100%" }}>
      <Card
        class="relative flex flex-1 border bg-cover bg-center bg-no-repeat text-white"
        style={{
          "box-shadow": "0px 2px 4px 0px #740F0F40",
          "border-color": "#F2935E",
          "background-color": "#FFBA68",
        }}
      >
        <div
          class="absolute bottom-0 left-0 right-0 top-0 z-[5] min-h-full min-w-full bg-cover bg-center bg-no-repeat"
          style={{
            "background-image": `url(${rewardBg})`,
          }}
        />
        <CardContent class="z-10 flex h-full w-full flex-col justify-between gap-2 p-2">
          <Show
            when={
              props.boosterItem.itemId === "energy-ball" &&
              !isEnergyBallDestroyed()
            }
          >
            <div class="absolute inset-0 z-20 flex flex-col items-center justify-center gap-2 bg-red-500 bg-opacity-70 text-center">
              <p class="eazy-chat text-outline z-10 text-lg font-medium leading-5">
                Limit Reached!
              </p>
              <p class="eazy-chat text-outline z-10 text-lg font-medium leading-5">
                Clear your Energy Ball to redeem another!
              </p>
            </div>
          </Show>
          <Show when={!props.boosterItem.inStock}>
            <div class="absolute inset-0 z-[15] flex flex-col items-center justify-center gap-2 bg-[#69D043] bg-opacity-70 text-center">
              <p class="eazy-chat text-outline z-10 -mt-4 max-w-[85px] text-center text-2xl font-medium leading-tight">
                OUT OF STOCK
              </p>
            </div>
          </Show>
          <div
            class="flex flex-col"
            onClick={() => {
              props.openBuyModal(props.boosterItem);
            }}
          >
            <div class="flex items-center justify-center">
              <img
                class="z-10 h-14 w-14 object-contain"
                src={
                  BOOSTER_IMAGES[
                    props.boosterItem.itemId as keyof typeof BOOSTER_IMAGES
                  ]
                }
              />
            </div>
            <div class="flex items-center justify-center">
              <h3 class="eazy-chat text-outline z-10 text-lg font-medium leading-5">
                {props.boosterItem.name}
              </h3>
            </div>
          </div>
          <Switch
            fallback={
              <Button
                onClick={() => {
                  props.openBuyModal(props.boosterItem);
                }}
                size="sm"
                class={cn(
                  "eazy-chat text-outline relative z-[16] min-w-fit border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70",
                  !canBuy() && "opacity-50",
                )}
              >
                <div class="z-[16]">{buttonText()} </div>
              </Button>
            }
          >
            <Match when={!props.boosterItem.inStock}>
              <Button
                onClick={() => {
                  props.openBuyModal(props.boosterItem);
                }}
                size="sm"
                class="eazy-chat text-outline relative z-[16] min-w-fit border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-lg leading-4 hover:opacity-70"
              >
                <div class="z-[16]">
                  <Show when={boosterShopItem()}>
                    <div class="mt-1 flex items-center justify-center gap-2">
                      Buy{" "}
                      <div class="flex items-center justify-center gap-1">
                        <img
                          src={starIcon}
                          class="-mt-1 h-5 w-5 object-contain"
                        />
                        <span class="">
                          {formatCompactNumber(
                            boosterShopItem()?.starsCost || 0,
                          )}
                        </span>
                      </div>
                    </div>
                  </Show>
                </div>
              </Button>
            </Match>
          </Switch>
        </CardContent>
      </Card>
    </div>
  );
}

export default function BoostersTab() {
  const coinShopStockListingQuery = createQuery(() => ({
    queryKey: ["coinShopStockListing"],
    queryFn: () => CoinsShopService.getListings(),
  }));
  const starShopListingQuery = createQuery(() => ({
    queryKey: ["starShopListing"],
    queryFn: () => ShopService.getBoosterShopListings(),
  }));
  const [buyModalOpen, setBuyModalOpen] = createSignal(false);
  const [selectedBooster, setSelectedBooster] =
    createSignal<CoinShopItemListing | null>(null);

  const openBuyModal = (boosterItem: CoinShopItemListing) => {
    setBuyModalOpen(true);
    setSelectedBooster(boosterItem);
  };

  return (
    <div class="overflow-y-auto overscroll-y-contain pb-10">
      <div class="flex items-center justify-center py-2">
        <div class="flex w-fit items-center">
          <div class="relative z-10 rounded-full bg-[#DA5252] p-2">
            <img src={hourglassImage} class="h-7 w-7 object-contain" />
          </div>
          <div class="-ml-4 w-[140px]">
            <div class="w-full bg-[#DA5252] shadow-md">
              <p class="text-center font-bold text-white">RESTOCK</p>
            </div>
            <div class="w-[90%] bg-[#4C4543] shadow-md">
              <p class="text-center text-base font-bold leading-4 text-white">
                {createCountdownTimer(
                  //  Next day 0 hours utc
                  DateTime.utc().startOf("day").plus({ days: 1 }).toString(),
                )()}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2 px-3">
        <For each={coinShopStockListingQuery.data}>
          {(boosterItem) => (
            <BoosterCard
              boosterItem={boosterItem}
              openBuyModal={openBuyModal}
              starShopListingQueryData={starShopListingQuery.data || []}
            />
          )}
        </For>
      </div>
      <Show when={selectedBooster() !== null}>
        <BuyBoosterItemModal
          isModalOpen={buyModalOpen}
          closeModal={() => {
            setSelectedBooster(null);
            setBuyModalOpen(false);
          }}
          setIsModalOpen={setBuyModalOpen}
          boosterItem={selectedBooster()!}
        />
      </Show>
    </div>
  );
}
