import { retrieveLaunchParams } from "@tma.js/sdk";
import { testToken } from "./test-token.ts";
import { IS_RANCH_PROD } from "@/config.ts";

export const getAuthToken = () => {
  try {
    const { initDataRaw } = retrieveLaunchParams();
    return initDataRaw!;
  } catch (e) {
    if (!IS_RANCH_PROD) {
      console.warn("Could not retrieve launch params. Using test token.");
      return testToken;
    } else {
      throw e;
    }
  }
};
