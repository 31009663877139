import { createSignal, onCleanup } from "solid-js";

const createCountdownTimer = (endDate: string) => {
  const [timeRemaining, setTimeRemaining] = createSignal("");

  const calculateTimeRemaining = () => {
    const now = new Date(new Date().toUTCString());
    const end = new Date(endDate);
    const timeDiff = end.getTime() - now.getTime();

    if (timeDiff <= 0) {
      setTimeRemaining("Time's up!");
      return;
    }

    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    setTimeRemaining(
      `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`,
    );
  };

  calculateTimeRemaining();
  const timer = setInterval(calculateTimeRemaining, 1000);
  onCleanup(() => clearInterval(timer));

  return timeRemaining;
};

export default createCountdownTimer;
